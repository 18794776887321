<ion-grid>
    <ion-row>
        <ion-col>
          <span class="modal-header-text">Pending Additional Hours</span>
        </ion-col>
        <ion-col size="auto">
          <ion-icon class="cross" (click)="close()" name="close-outline"></ion-icon>
        </ion-col>
      </ion-row>
      <div class="scroll-container">
        <ion-row class="parent-row" *ngFor="let additionalTime of additionalTimeArray | sortByDate : trigger">
            <ion-col>

              <ion-row class="top-data">
                <ion-col class="content">
                  <span class="label-margin">Hours: </span>
                  <span>{{ additionalTime.hours }}</span>
                </ion-col>
                <ion-col size="auto" class="content status">
                  <!-- <span class="label-margin">Recieved Status: </span>
                  <span>{{ true ? 'Sent' : 'Opened' }}</span> -->
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="content">
                  <span class="label-margin">Reason: </span>
                  <!-- <span>{{ additionalTime.reason?.length >= 50 ? (additionalTime.reason.substring(0, 50) + '...') : additionalTime.reason }}</span> -->
                  <span>{{ additionalTime.reason }}</span>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="additionalTime.approved" class="content">
                <ion-col size="auto" class="content">
                  <span class="label-margin">Approver: </span>
                  <span>{{ additionalTime.approver_name ?? 'Unknown' }}</span>
                </ion-col>
                <ion-col class="content status">
                  <span class="label-margin">Method: </span>
                  <span>{{ additionalTime.approval_method ?? 'Unknown' }}</span>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col size="auto" 
                [ngStyle]="{'color': additionalTime.approved ? 'green' : additionalTime.rejected ? 'red' : ''}" class="approval-status no-padding-top">
                  <span class="label-margin">Status: </span>
                  {{ additionalTime.approved ? 'Approved' : additionalTime.rejected ? 'Rejected' : 'Pending' }}
                </ion-col>
                <ion-col></ion-col>
                <ion-col class="edit-col" size="auto">
                    <ion-icon (click)="editAdditionalHours(additionalTime)" name="create-outline"></ion-icon>
                </ion-col>
              </ion-row>

              <ion-row *ngIf="!additionalTime.approved && !additionalTime.rejected" class="button-row">
                <ion-col class="approve">
                  <ion-button (click)="approveAdditionalTime(additionalTime)">Enter External Approval</ion-button>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
      </div>
</ion-grid>