import { Pipe, PipeTransform } from "@angular/core";
import { ILocation } from "../models/location.model";

@Pipe({
  name: "filterLocationSearch",
})
export class FilterLocationSearchPipe implements PipeTransform {
    transform(value: ILocation[], searchValue: string): ILocation[] {
        return value.filter(v => v.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
}