<div class="issue-container">
  <ion-row class="content">
    <ion-col class="issue-date-concern-container">
      <ion-row class="closed-row" *ngIf="!issue.active">
        Closed
      </ion-row>
      <ion-row
        class="date-container"
        (click)="createOrUpdateIssue(issue)"
        class="issue-date"
      >
        {{ getDate(issue.date_created) | date : "fullDate" }}
      </ion-row>
      <ion-row (click)="createOrUpdateIssue(issue)" class="issue-desc">
        <span>{{ issue.description }}</span>
      </ion-row>
    </ion-col>
    <img
      *ngIf="issue.concern"
      class="caution-image"
      src="../../../../../../assets/icons/concern.svg"
    />
    <img
      *ngIf="!issue.concern"
      class="caution-image"
      src="../../../../../../assets/icons/caution.svg"
    />
  </ion-row>
</div>
