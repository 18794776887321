import { Pipe, PipeTransform } from "@angular/core";
import { IAccountData } from "../../../shared/models/account.model";

@Pipe({
    name: 'filterAccountByLiaison'
})

export class FilterAccountByLiaisonPipe implements PipeTransform {


    transform(items: IAccountData[], userId: number) {

        if (!items) {
            return [];
        }

        return items.filter(i => i.liaison_accounts.map(l => l.user_id).includes(userId)).map(i => {
            const liaisonAccount = i.liaison_accounts.find(l => l.user_id === userId);
            return {account: i, liaisonAccountId: liaisonAccount.id, basePay: liaisonAccount.pay_rate, baseHours: liaisonAccount.base_hours}
        });

    }
}