<ion-grid>
    <ion-row class="header-row">

        <ion-col class="back-button" size="auto">
            <div (click)="back()" [ngStyle]="{'opacity': (this.activeTab === Tabs.CUSTOMER) ? '0' : '1'}">
                <ion-icon name="chevron-back-outline"></ion-icon>
                <span>back</span>
            </div>
        </ion-col>

        <ion-col>
            <div class="stepper-wrapper">
                <div class="stepper-item" (click)="updateActiveTab(Tabs.CUSTOMER)"
                 [ngClass]="{'active': (this.activeTab === Tabs.CUSTOMER), 'completed': (this.activeTab > Tabs.CUSTOMER)}">
                  <div class="step-counter"><span>1</span></div>
                  <div class="step-name"><span>Customer</span></div>
                </div>
                <div class="stepper-item" (click)="updateActiveTab(Tabs.LOCATION)"
                [ngClass]="{'active': (this.activeTab === Tabs.LOCATION), 'completed': (this.activeTab > Tabs.LOCATION)}">
                  <div class="step-counter"><span>2</span></div>
                  <div class="step-name"><span>Location</span></div>
                </div>
                <div class="stepper-item" (click)="updateActiveTab(Tabs.ACCOUNT)"
                [ngClass]="{'active': (this.activeTab === Tabs.ACCOUNT), 'completed': (this.activeTab > Tabs.ACCOUNT)}">
                  <div class="step-counter"><span>3</span></div>
                  <div class="step-name"><span>Account</span></div>
                </div>
                <div class="stepper-item" (click)="updateActiveTab(Tabs.LIAISON)"
                [ngClass]="{'active': (this.activeTab === Tabs.LIAISON), 'completed': (this.activeTab > Tabs.LIAISON)}">
                  <div class="step-counter"><span>4</span></div>
                  <div class="step-name"><span>Liaisons</span></div>
                </div>
                <div class="stepper-item" (click)="updateActiveTab(Tabs.CONTACTS)"
                [ngClass]="{'active': (this.activeTab === Tabs.CONTACTS), 'completed': (this.activeTab > Tabs.CONTACTS)}">
                  <div class="step-counter"><span>5</span></div>
                  <div class="step-name"><span>Contacts</span></div>
                </div>
              </div>
        </ion-col>
        <ion-col class="close-button" size="auto">
            <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
        </ion-col>
    </ion-row>

    <div class="form-container">

        <!-- CUSTOMER SECTION -->
        <div *ngIf="this.activeTab === Tabs.CUSTOMER">
            <ion-row class="label-row margin-t-10">
                <ion-col class="ion-no-padding label-container">
                    <ion-label>
                        Customer *
                    </ion-label>
                </ion-col>
                <ion-col class="ion-no-padding new-button-row">
                    <ion-button name="new-customer-button" (click)="createNewCustomer()" class="new-button">
                        + New
                    </ion-button>
                </ion-col>
            </ion-row>
            <ion-row class="center-item">
                <ion-col>
                    <select *ngIf="sharedService.isNative()" class="quickbooks-input" (change)="customerUpdate()" [(ngModel)]="customer" [ngClass]="{'text-color-grey': !customer}">
                        <option [ngValue]="null" disabled selected>Select Customer</option>
                        <option *ngFor="let customer of customers | activeFilter" [ngValue]="customer.id">
                            {{customer.company_name}}
                        </option>
                    </select>
                    <ng-select placeholder="Select Customer" *ngIf="!sharedService.isNative()" appendTo="body" class="quickbooks-input ng-select-no-border"
                     (change)="customerUpdate()" [(ngModel)]="customer" [ngClass]="{'text-color-grey': !customer}">
                        <ng-option *ngFor="let customer of customers | activeFilter" [value]="customer.id">
                            {{customer.company_name}}
                        </ng-option>
                    </ng-select>
                </ion-col>
            </ion-row>
            <ion-row class="label-row margin-t-10">
                <ion-col class="ion-no-padding label-container">
                    <ion-label>
                        Account Name
                    </ion-label>
                </ion-col>
            </ion-row>
            <ion-row class="center-item">
                <ion-col>
                    <ion-input name="account-name" [(ngModel)]="name" [placeholder]="'Enter Account Name'"></ion-input>
                </ion-col>
            </ion-row>
        </div>

        <!-- LOCATION SECTION -->
        <div *ngIf="this.activeTab === Tabs.LOCATION">
            <ion-row class="label-row margin-t-10">
                <ion-col class="ion-no-padding label-container">
                    <ion-label>
                        Location *
                    </ion-label>
                </ion-col>
                <ion-col class="ion-no-padding new-button-row">
                    <ion-button name="new-location-button" (click)="createNewLocation()" class="new-button">
                        + New
                    </ion-button>
                </ion-col>
            </ion-row>
            <ion-row class="center-item">
                <ion-col>
                    <select *ngIf="sharedService.isNative()" (change)="locationUpdated()" [(ngModel)]="location" [ngClass]="{'text-color-grey': !location}">
                        <option [value]="null" disabled selected>Select Location</option>
                        <option *ngFor="let location of locations | activeFilter" [ngValue]="location.id">
                            {{location.name}}
                        </option>
                    </select>
                    <ng-select placeholder="Select Location" *ngIf="!sharedService.isNative()" appendTo="body" class="ng-select-no-border"
                     (change)="locationUpdated()" [(ngModel)]="location" [ngClass]="{'text-color-grey': !location}">
                        <ng-option *ngFor="let location of locations | activeFilter" [value]="location.id">
                            {{location.name}}
                        </ng-option>
                    </ng-select>
                </ion-col>
            </ion-row>

            <ion-row class="label-row margin-t-10">
                <ion-col class="ion-no-padding label-container">
                    <ion-label>
                        Quickbooks Name *
                    </ion-label>
                </ion-col>
            </ion-row>
            <ion-row class="center-item">
                <ion-col>
                    <ion-input class="quickbooks-input" [(ngModel)]="qbo_name" [placeholder]="'Enter Quickbooks Name'"></ion-input>
                </ion-col>
            </ion-row>

            <ion-row class="label-row margin-t-10">
                <ion-col class="ion-no-padding label-container">
                    <ion-label>
                        PO Number
                    </ion-label>
                </ion-col>
            </ion-row>
            <ion-row class="center-item">
                <ion-col>
                    <ion-input (keypress)="omitSemiColon($event)" [(ngModel)]="poNumber" [placeholder]="'Enter PO Number'"></ion-input>
                </ion-col>
            </ion-row>

            <ion-row class="label-row margin-t-10">
                <ion-col class="ion-no-padding label-container">
                    <ion-label>
                        Program/Vehicle Lines
                    </ion-label>
                </ion-col>
            </ion-row>
            <ion-row class="center-item">
                <ion-col>
                    <ion-input [(ngModel)]="program" [placeholder]="'Enter Program/Vehicle Lines'"></ion-input>
                </ion-col>
            </ion-row>

            <ion-row class="label-row margin-t-10">
                <ion-col class="ion-no-padding label-container">
                    <ion-label>
                        Product Description
                    </ion-label>
                </ion-col>
            </ion-row>
            <ion-row class="center-item">
                <ion-col>
                    <ion-input [(ngModel)]="productDesc" [placeholder]="'Enter Product Description'"></ion-input>
                </ion-col>
            </ion-row>
        </div>

        <!-- ACCOUNT SECTION -->
        <div *ngIf="this.activeTab === Tabs.ACCOUNT" class="account-area">
    
            <div class="scroll-area">
                <ng-container>
                    <div class="account-history-container">
                        <ion-row class="label-row full-width margin-t-10">
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    Invoice Rate *
                                </ion-label>
                            </ion-col>
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    Contracted Hours *
                                </ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row class="double-row full-width">
                            <ion-col class="ion-no-padding currency-col">
                                <ng-container *ngIf="!refreshCurrencyInput">
                                    <currency-input class="currency-input" [width]="100" [(value)]="selectedData.invoice_rate" [placeholder]="'Enter Invoice Rate'"></currency-input>
                                </ng-container>
                                <ng-container *ngIf="refreshCurrencyInput">
                                    <currency-input class="currency-input" [width]="100" [placeholder]="'Enter Invoice Rate'"></currency-input>
                                </ng-container>
                            </ion-col>
                            <ion-col class="ion-no-padding date-col">
                                <ion-input name="contracted-hours-input" (ionChange)="refreshPipe()" class="full-width" type="number" [(ngModel)]="selectedData.contracted_hours" [placeholder]="'Enter Contracted Hours'"></ion-input>
                            </ion-col>
                        </ion-row>

                        <ion-row class="label-row full-width margin-t-10">
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    Start Date
                                </ion-label>
                            </ion-col>
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    End Date
                                </ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row class="double-row full-width">
                            <ion-col class="ion-no-padding date-col">
                                <ion-input class="full-width" (ionBlur)="dateUnfocused()" type="date" [(ngModel)]="selectedData.start_date"></ion-input>
                            </ion-col>
                            <ion-col class="ion-no-padding date-col">
                                <ion-input class="full-width" [disabled]="!isNewestEntry(selectedData)" (ionBlur)="dateUnfocused()" type="date" [(ngModel)]="selectedData.end_date"></ion-input>
                            </ion-col>
                        </ion-row>

                        <ion-row class="full-width no-overtime-row sign-contract">
                            <ion-col>
                                <ion-checkbox name="signed-contract" [(ngModel)]="selectedData.signed_contract"></ion-checkbox>
                                <span class="small-text">Signed Contract</span>
                            </ion-col>
                        </ion-row>

                        <ion-row class="full-width label-row margin-t-10">
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    Overtime Rules
                                </ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row class="full-width no-overtime-row">
                            <ion-col>
                                <ion-checkbox [(ngModel)]="selectedData.has_overtime"></ion-checkbox>
                                <span class="small-text">Overtime</span>
                            </ion-col>
                        </ion-row>
                        <ng-container *ngIf="selectedData.has_overtime">
                            <ion-row class="full-width hours-row">
                                <ion-col size="auto">
                                    <span class="small-text">Overtime if hours exceed</span>
                                </ion-col>
                                <ion-col class="overtime-hours" size="auto">
                                    <ion-input [(ngModel)]="selectedData.overtime_hours" placeholder=""></ion-input>
                                </ion-col>
                                <ion-col size="auto">
                                    <select [(ngModel)]="selectedData.overtime_option">
                                        <option [value]="OVERTIMEOPTIONS.WEEKLY">
                                            Weekly
                                        </option>
                                        <option [value]="OVERTIMEOPTIONS.DAILY">
                                            Daily
                                        </option>
                                    </select>
                                </ion-col>
                            </ion-row>
                            <ion-row class="full-width checkbox-row">
                                <ion-col size="auto" class="checkbox-col weekend-overtime-col">
                                    <ion-checkbox labelPlacement="end" [(ngModel)]="selectedData.overtime_weekends"></ion-checkbox>
                                    <span class="small-text">Overtime on Weekends</span>
                                </ion-col>
                                <ion-col size="auto"  class="checkbox-col">
                                    <ion-checkbox labelPlacement="end" [(ngModel)]="selectedData.overtime_holidays"></ion-checkbox>
                                    <span class="small-text">Overtime on Holidays</span>
                                </ion-col>
                            </ion-row>
                            <ion-row class="full-width checkbox-row">
                                <ion-col size="auto"  class="checkbox-col">
                                    <ion-checkbox labelPlacement="end" [(ngModel)]="selectedData.doubletime_sundays"></ion-checkbox>
                                    <span class="small-text">Double Time on Sundays</span>
                                </ion-col>
                                <ion-col size="auto"  class="checkbox-col">
                                    <ion-checkbox labelPlacement="end" [(ngModel)]="selectedData.doubletime_holidays"></ion-checkbox>
                                    <span class="small-text">Double Time on Holidays</span>
                                </ion-col>
                            </ion-row>
                        </ng-container>

                    </div>
                </ng-container>
        
            </div>

        </div>

        <!-- LIAISON Section -->
        <div *ngIf="this.activeTab === Tabs.LIAISON">

            <div class="liaison-scroll">
                <ion-row class="liaison-header-row liaison-row">
                    <ion-col class="name-col">
                        Name
                    </ion-col>
                    <ion-col class="data-col">
                        Pay Rate
                    </ion-col>
                    <ion-col class="data-col">
                        Base Hours
                    </ion-col>
                    <ion-col class="data-col">
                        <!-- <div class="show-inactive">
                            <ion-toggle [(ngModel)]="showInactiveLiaisonAccount"></ion-toggle>
                            <span>Show Inactive</span>
                        </div> -->
                    </ion-col>
                </ion-row>
     
                <ng-container *ngFor="let liaison of assignedLiaisons">
                    <ion-row class="liaison-row">
                        <ion-col class="name-col">
                            {{liaison.users.first_name}} {{liaison.users.last_name}}
                        </ion-col>
                        <ion-col class="data-col">
                            {{(liaison.pay_rate ?? 0) | currency:'USD'}}
                        </ion-col>
                        <ion-col class="data-col">
                            {{liaison.base_hours ?? 0}}
                        </ion-col>
                        <ion-col class="data-col">
                            <div (click)="removeLiaisonRow(liaison)" class="remove-button">Remove</div>
                        </ion-col>
                    </ion-row>
                </ng-container>

                <ion-row class="add-liaison-row">
                    <ion-col>
                        <div (click)="addNewLiaison()">+ Liaison</div>
                    </ion-col>
                    <ion-col class="total-hours-text">
                        Total hours: {{totalBaseHours}}
                    </ion-col>
                </ion-row>

            </div>

        </div>

        <!-- CONTACT SECTION -->
        <div *ngIf="this.activeTab === Tabs.CONTACTS">
            <div class="table">
                <div class="container">
                    <ion-row class="contact-table-label">
                        <ion-col>
                            <span>Quality Contact *</span>
                        </ion-col>
                        <ion-col size="auto">
                            <ion-button name="new-contact-button" [disabled]="!customer" (click)="createNewContact()" class="new-button">
                                + New
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    <ion-row class="assign-row" *ngFor="let contact of assignedContacts">
                        <ion-col>
                            <select *ngIf="sharedService.isNative()" (click)="checkForQualityContacts()" [disabled]="!customer" [(ngModel)]="contact.contact_id"
                             [ngClass]="{'text-color-grey': !contact.contact_id}">
                                <option [value]="null" disabled selected>Select Contact</option>
                                <option *ngFor="let allContact of contacts | filterAssignedContacts: assignedContacts : contact.contact_id : manualTriggerContact" [ngValue]="allContact.id">
                                    {{allContact.first_name}} {{allContact.last_name}}
                                </option>
                            </select>
                            <ng-select placeholder="Select Contact" *ngIf="!sharedService.isNative()" appendTo="body" class="ng-select-no-border"
                            (click)="checkForQualityContacts()" [disabled]="!customer" [(ngModel)]="contact.contact_id" [ngClass]="{'text-color-grey': !contact.contact_id}">
                                <ng-option *ngFor="let allContact of contacts | filterAssignedContacts: assignedContacts : contact.contact_id : manualTriggerContact" [value]="allContact.id">
                                    {{allContact.first_name}} {{allContact.last_name}}
                                </ng-option>
                            </ng-select>
                        </ion-col>
                    </ion-row>
                    <ion-row class="warning" *ngIf="!customer">
                        <ion-col>
                            <span>* Customer must be selected before choosing a quality contact.</span>
                        </ion-col>
                    </ion-row>
                </div>
            </div>
        </div>

    </div>
    <ion-row class="button-row">
        <ion-col>
            <ion-button name="next-button" *ngIf="!(this.activeTab === Tabs.CONTACTS)" (click)="next()" [disabled]="((this.activeTab === Tabs.CUSTOMER && !customer) ||
            (this.activeTab === Tabs.LOCATION && !location) || (this.activeTab === Tabs.ACCOUNT && !this.accountHistoryValid()))">
                <span>Next</span>
                <ion-icon class="next-icon" name="chevron-forward-outline"></ion-icon>
            </ion-button>
            <ion-button name="finish-button" (click)="finish()" *ngIf="(this.activeTab === Tabs.CONTACTS)" [disabled]="!customer || !qbo_name || !location || (assignedContacts.length <= 0)
             || !assignedContacts[0]?.contact_id || processing">
                <span>Finish</span>
            </ion-button>
        </ion-col>
    </ion-row>

</ion-grid>