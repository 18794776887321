<ion-row class="header">
    <ion-col size="auto">
        <back-button [navigateTo]="[navRoute]"></back-button>
    </ion-col>
    <ion-col class="account-info-col" *ngIf="isLoaded">
        <ion-row class="account-name-row">
            <ion-col class="ion-no-padding">
                <span class="account-name">{{account?.name}}</span>
            </ion-col>
            <ion-col (click)="openAccountInfo()" size="auto" class="ion-no-padding icon-container">
                <ion-icon name="information-circle-outline"></ion-icon>
            </ion-col>
        </ion-row>
        
        <ion-row *ngIf="!timePage" class="hours-row">
            <ion-col class="ion-no-padding">
                <span><span [ngStyle]="{'color': (totalHours > (baseHours + totalAdditionalHours)) ? 'red' :
                     (((totalHours/(baseHours + totalAdditionalHours)) >= .80) ? 'orange' : '#0568af')}">
                     {{totalHours}}/{{baseHours + totalAdditionalHours}}</span> Hours Worked This Week</span>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="timePage" class="hours-row">
            <div>
                <ion-col class="ion-no-padding hours-breakdown">
                    <span class="label-text">Contracted Hours</span>
                    <span class="hours-text">{{baseHours}}</span>
                </ion-col>
                <ion-col class="ion-no-padding hours-breakdown">
                    <span class="label-text">Additional Approved</span>
                    <span class="hours-text">{{totalAdditionalHours}}</span>
                </ion-col>
                <ion-col class="ion-no-padding hours-breakdown">
                    <span class="label-text">Additional Pending</span>
                    <span class="hours-text">{{totalPendingAdditionalHours}}</span>
                </ion-col>
            </div>
        </ion-row>

        <ion-row class="approval-needed" [ngStyle]="{'opacity': ((totalHours > (baseHours + totalAdditionalHours)) && !timePage) ? 1 : 0}">
            <ion-col (click)="viewPendingHours()" class="ion-no-padding">
                <span>* Approval Needed</span>
            </ion-col>
        </ion-row>

    </ion-col>

    <ion-col class="account-info-col" *ngIf="!isLoaded">
        <ion-row class="account-name-row">
            <ion-col class="ion-no-padding">
                <ion-skeleton-text class="title-skeleton-loader" [animated]="true"></ion-skeleton-text>
            </ion-col>
        </ion-row>
        <ion-row class="hours-row">
            <ion-col class="ion-no-padding">
                <ion-skeleton-text class="content-skeleton-loader" [animated]="true"></ion-skeleton-text>
            </ion-col>
        </ion-row>
    </ion-col>
</ion-row>