<div class="container" *ngIf="!isMobile" 
[ngClass]="{
  'prod-colors': (environment === 'production'),
  'staging-colors': (environment === 'staging'),
  'dev-colors': (environment === 'development')
}">
  <ion-row (click)="toggleSize()" class="nav-row chevron-row">
    <ion-col>
      <ion-icon [ngClass]="{'rotate': !isExpanded, 'expanded': isExpanded}" name="chevron-back-outline"></ion-icon>
    </ion-col>
  </ion-row>
  <ng-container *ngFor="let option of options">
    <ion-row [id]="option.cypressName" *ngIf="option.authorized" [routerLink]="[option.route]" class="nav-row">
      <ng-container>
        <ion-col size="auto" class="image-col" [ngStyle]="{'margin-right': !isExpanded ? '10px' : ''}">
          <img [ngStyle]="{'opacity': (activeTab === option.tab) ? 1 : .7}" [src]="'../../../../assets/icons/' + option.icon + '.svg'" />
        </ion-col>
        <ion-col *ngIf="isExpanded" class="title-col">
          <span [ngStyle]="{'color': (activeTab === option.tab) ? 'white' : '#FFFFFF90'}">{{option.title}}</span>
        </ion-col>
      </ng-container>
    </ion-row>
  </ng-container>
</div>

<div class="mobile-container" *ngIf="isMobile">
  <ion-button id="open-modal" expand="modal-container" (click)="openMobileMenu()">
    <ion-icon name="reorder-four-outline"></ion-icon>
  </ion-button>
</div>


