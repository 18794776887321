import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateOrder'
})
export class DateOrderPipe implements PipeTransform {

  transform(items: any[], searchText: string, fieldName: string): any[] {

    // return empty array if array is falsy
    if (!items) { return []; }

    return items.sort((a, b) => {
      if (new Date(a.date_worked).getTime() > new Date(b.date_worked).getTime()) {
          return 1;
      }
      if (new Date(a.date_worked).getTime() < new Date(b.date_worked).getTime()) {
          return -1;
      }
      
      return 0;
  });
   }
}