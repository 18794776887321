import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IonInput } from "@ionic/angular";
import { ViewChild } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: "currency-input",
  templateUrl: "currency-input.component.html",
  styleUrls: ["./currency-input.component.scss"],
  providers: [CurrencyPipe]
})
export class CurrencyInputComponent {

    @ViewChild('input') inputElement: IonInput;

    @Input() value: number = null;
    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() placeholder: string = '';
    @Input() disabled: boolean = false;
    @Input() width: number = 80;
    @Input() fieldChanged: boolean = false;
    valueFormatted: string = '$0.00';

    isBlur: boolean = true;

    constructor(
        private currencyPipe: CurrencyPipe
    ) {
        setTimeout(async () => {
            this.valueFormatted = this.currencyPipe.transform(this.value ?? 0, '$');
            this.inputElement.value = this.valueFormatted;
        }, 0);
    }

    onBlur(element) {
        this.valueChange.emit(Number(this.value));
        this.isBlur = true;
        this.valueFormatted = this.currencyPipe.transform(this.value, '$');
        element.target.value = this.valueFormatted;
    }

    async onFocus(event) {
        this.value = Number(this.valueFormatted.replace('$', ''));
        this.isBlur = false;

        const innerInputElement = await this.inputElement.getInputElement();
        innerInputElement.select();
    }


}
