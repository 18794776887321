import { Component, HostListener, Input, OnInit } from "@angular/core";
import { User } from "../../models/user.model";
import { AuthService } from "../../services/auth.service";
import { INav } from "../../../../app/features/admin/pages/home/models/nav.model";
import { NavigationStart, Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { filter, Subscription } from "rxjs";
import { MobileNavComponent } from "./components/mobile-nav/mobile-nav.component";
import { SharedService } from "../../services/shared.service";
import { environment } from "../../../../environments/environment";

export enum TABS {
  'HOME' = 1,
  'CUSTOMER' = 2,
  'LIAISON' = 3,
  'LOCATION' = 4,
  'TIME' = 5,
  'INVOICE' = 6,
  'REPORTS' = 7,
  'MESSAGING' = 8
}
@Component({
  selector: "app-admin-nav",
  templateUrl: "./admin-nav.component.html",
  styleUrls: ["./admin-nav.component.scss"],
})

export class AdminNavComponent implements OnInit {

  environment: string = '';

  TABS = TABS;

  activeTab = TABS.HOME;

  isExpanded: boolean = true;
  isMobile: boolean = false;

  subscription: Subscription = null;

  options: INav[] = [
    {
      route: '/admin/home',
      icon: 'home-outline',
      title: 'Home',
      row: 3,
      tab: TABS.HOME,
      authorized: true,
      cypressName: 'admin-home'
    },
    {
      route: '/admin/customer-setup',
      icon: 'customer',
      title: 'Customers',
      row: 1,
      tab: TABS.CUSTOMER,
      authorized: true,
      cypressName: 'admin-customer'
    },
    {
      route: '/admin/liaison-setup',
      icon: 'profile-white',
      title: 'Liaisons',
      row: 1,
      tab: TABS.LIAISON,
      authorized: true,
      cypressName: 'admin-liaison'
    },
    {
      route: '/admin/locations',
      icon: 'map',
      title: 'Locations',
      row: 1,
      tab: TABS.LOCATION,
      authorized: true,
      cypressName: 'admin-location'
    },
    {
      route: '/admin/time-approval',
      icon: 'time-outline',
      title: 'Time Approval',
      row: 2,
      tab: TABS.TIME,
      authorized: true,
      cypressName: 'admin-time-approval'
    },
    {
      route: '/admin/invoicing-and-payments',
      icon: 'invoicing-and-billing',
      title: 'Invoicing',
      row: 2,
      tab: TABS.INVOICE,
      authorized: this.getAuthorizationStatus(),
      cypressName: 'admin-invoice'
    },
    {
      route: '/admin/reports',
      icon: 'pencil-white',
      title: 'Reports',
      row: 3,
      tab: TABS.REPORTS,
      authorized: true,
      cypressName: 'admin-report'
    },
    {
      route: '/admin/messaging',
      icon: 'chatbox-outline',
      title: 'Messaging',
      row: 2,
      tab: TABS.MESSAGING,
      authorized: this.getAuthorizationStatus(),
      cypressName: 'admin-message'
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth > 1400 || (this.activeTab === TABS.HOME)) {
      this.isExpanded = true;
      
      if (window.innerWidth <= 500) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    } else {
      this.isExpanded = false;

      if (window.innerWidth <= 500) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalController: ModalController,
    private sharedService: SharedService
  ) {
    this.subscription = router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((val: any) => {
      if (val) {
        if (val.url) {
          this.toggleOnURL(val.url);
        }
      }
    });
  }

  getAuthorizationStatus(): boolean {
    return (JSON.parse(this.sharedService.localStorageGet('user'))?.role === 'admin') ?? false;
  }

  ngOnInit(): void {
    // Set the side bar color based on environment
    this.environment = environment.name;

    if (this.router.url) {
      this.toggleOnURL(this.router.url);
    }

    if (window.innerWidth > 1400 || (this.activeTab === TABS.HOME)) {
      this.isExpanded = true;
      
      if (window.innerWidth <= 500) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

    } else {
      this.isExpanded = false;

      if (window.innerWidth <= 500) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
  }

  toggleOnURL(route: string): void {
    switch (route) {
      case '/admin/home': 
        this.activeTab = TABS.HOME;
        break;
      
      case '/admin/customer-setup': 
        this.activeTab = TABS.CUSTOMER;
        break;

      case '/admin/liaison-setup': 
        this.activeTab = TABS.LIAISON;
        break;

      case '/admin/locations': 
        this.activeTab = TABS.LOCATION;
        break;

      case '/admin/time-approval': 
        this.activeTab = TABS.TIME;
        break;

      case '/admin/invoicing-and-payments': 
        this.activeTab = TABS.INVOICE;
        break;

      case '/admin/reports': 
        this.activeTab = TABS.REPORTS;
        break;

      case '/admin/messaging': 
        this.activeTab = TABS.MESSAGING;
        break;

      default:
        this.activeTab = TABS.HOME;
        break;
    }
  }

  toggleSize(): void {
    this.isExpanded = !this.isExpanded;
  }

  async openMobileMenu(): Promise<void> {
    const modal = await this.modalController.create({
      component: MobileNavComponent,
      componentProps: {
        options: this.options
      },
      cssClass: 'bottom-modal',
      breakpoints: [0, 1],
      initialBreakpoint: 1
    });

    modal.present();

    const { data } = await modal.onDidDismiss();

    if (data) {

    }
  }

}
