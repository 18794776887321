import { Component, Input } from '@angular/core';

@Component({
    selector: 'accordion',
    templateUrl: 'accordion.component.html',
    styleUrls: ['./accordion.component.scss']
})

export class AccordionComponent {

    @Input() title:string;

    @Input() hidden:boolean = false;

    @Input() fontSize: number = 18;

    constructor() { }

    toggleHidden(){
        this.hidden = !this.hidden;
    }

    open() {
        this.hidden = false;
    }

}