import { Pipe, PipeTransform } from "@angular/core";
import { ICustomer } from "../models/customer.model";

@Pipe({
  name: "filterSearch",
})
export class FilterSearchPipe implements PipeTransform {
    transform(value: ICustomer[], searchValue: string): ICustomer[] {
      return value.filter(v => v.company_name.toLowerCase().includes(searchValue.toLowerCase()));
    }
}