<ion-grid>
    <ion-row class="header-row">
        <ion-col>
            <span>Choose Account</span>
        </ion-col>
        <ion-icon class="cross" (click)="close()" name="close-outline"></ion-icon>
    </ion-row>

    <ion-row class="select-row">
        <ion-col>
            <select *ngIf="sharedService.isNative()" [(ngModel)]="accountId">
                <option *ngFor="let account of accounts" [ngValue]="account.id">
                    {{account?.customers?.company_name}} - {{account?.locations?.name}} {{account?.account_details ? (' - ' + account?.account_details) : ''}}
                </option>
            </select>
            <ng-select *ngIf="!sharedService.isNative()" appendTo="body" [(ngModel)]="accountId">
                <ng-option *ngFor="let account of accounts" [value]="account.id">
                    {{account?.customers?.company_name}} - {{account?.locations?.name}} {{account?.account_details ? (' - ' + account?.account_details) : ''}}
                </ng-option>
             </ng-select>
        </ion-col>
    </ion-row>


    <ion-row class="save-row">
        <ion-col>
            <ion-button [disabled]="!accountId" (click)="select()">Select</ion-button>
        </ion-col>
    </ion-row>
</ion-grid>