<ion-grid name="add-new-contact-popup">
    <ion-row class="header-row">
        <ion-col>
            Add New Contact
        </ion-col>
        <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
    </ion-row>
    <form [formGroup]="newForm" (ngSubmit)="submitNewContact()">
        <div class="form-container">
 
            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            First Name *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input name="contact-firstname" class="full-width" formControlName="firstName" placeholder="Enter first name"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Last Name *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input name="contact-lastname" class="full-width" formControlName="lastName" placeholder="Enter last name"></ion-input>
                        </ion-col>
                    </ion-row>     
                </ion-col>
            </ion-row>


            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Email *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input name="contact-email" class="full-width" (ionChange)="checkEmailValidity()" formControlName="email" placeholder="Enter email"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width" *ngIf="showCustomerOptions">
                        <ion-label>
                            Role *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <select name="contact-role" formControlName="role" class="full-width" [ngClass]="{'text-color-grey': !newForm.value.role}">
                                <option [value]="null" disabled selected>Select Role</option>
                                <option value="AP">Accounts Payable</option>
                                <option value="Quality">Quality</option>
                                <option value="Purchasing">Purchasing</option>
                                <option value="Other">Other</option>
                            </select>
                        </ion-col>
                    </ion-row>        
                </ion-col>
            </ion-row>

            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-col class="ion-no-padding">
                            <ion-label>
                                Phone
                            </ion-label>
                        </ion-col>
                        <ion-col class="ion-no-padding phone-extension-label">
                            <ion-label>
                                Ext.
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="center-item phone-item">
                        <ion-col>
                            <div class="country-code-dropdown" (click)="countryCodeDropdownOpen = !countryCodeDropdownOpen">
                                <span *ngIf="countryCode === 1">+1</span>
                                <span *ngIf="countryCode === 44">+44</span>
                                <span *ngIf="countryCode === 52">+52</span>
            
                                <div *ngIf="countryCodeDropdownOpen" class="drop-down">
                                    <div [ngClass]="{'selected': (countryCode === 1)}" class="item" (click)="countryCode = 1">
                                        +1
                                    </div>
                                    <div [ngClass]="{'selected': (countryCode === 44)}" class="item top-line" (click)="countryCode = 44">
                                        +44
                                    </div>
                                    <div [ngClass]="{'selected': (countryCode === 52)}" class="item top-line" (click)="countryCode = 52">
                                        +52
                                    </div>
                                </div>
                            </div>
                            <input name="contact-phone" [mask]="(countryCode === 1) ? '000-000-0000' : '00 0000 0000'" type="tel" formControlName="phone" placeholder="Enter phone"/>
                        </ion-col>
                        <ion-col class="phone-extension-input">
                            <input formControlName="phoneExt" placeholder="Optional"/>
                        </ion-col>
                    </ion-row>    
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-col class="ion-no-padding">
                            <ion-label>
                                Alternative Phone
                            </ion-label>
                        </ion-col>
                        <ion-col class="ion-no-padding phone-extension-label">
                            <ion-label>
                                Ext.
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="center-item phone-item">
                        <ion-col>
                            <div class="country-code-dropdown" (click)="countryCodeDeskDropdownOpen = !countryCodeDeskDropdownOpen">
                                <span *ngIf="countryCode === 1">+1</span>
                                <span *ngIf="countryCode === 44">+44</span>
                                <span *ngIf="countryCode === 52">+52</span>
            
                                <div *ngIf="countryCodeDeskDropdownOpen" class="drop-down">
                                    <div [ngClass]="{'selected': (countryCodeDesk === 1)}" class="item" (click)="countryCodeDesk = 1">
                                        +1
                                    </div>
                                    <div [ngClass]="{'selected': (countryCode === 44)}" class="item top-line" (click)="countryCode = 44">
                                        +44
                                    </div>
                                    <div [ngClass]="{'selected': (countryCodeDesk === 52)}" class="item top-line" (click)="countryCodeDesk = 52">
                                        +52
                                    </div>
                                </div>
                            </div>
                            <input [mask]="(countryCodeDesk === 1) ? '000-000-0000' : '00 0000 0000'" type="tel" formControlName="deskPhone" placeholder="Enter phone"/>
                        </ion-col>
                        <ion-col class="phone-extension-input">
                            <input formControlName="deskPhoneExtension" placeholder="Optional"/>
                        </ion-col>
                    </ion-row>          
                </ion-col>
            </ion-row>

            <ion-row class="double-row"> 
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width" *ngIf="showCustomerOptions">
                        <ion-label>
                            Job Title
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input name="contact-job" class="full-width" formControlName="job_title" placeholder="Enter job title"></ion-input>
                        </ion-col>
                    </ion-row>         
                </ion-col>
                <ion-col class="ion-no-padding">
    
                </ion-col>
            </ion-row>

            <ion-row class="label-row" *ngIf="showCustomerOptions">
                <ion-label>
                    Notes
                </ion-label>
            </ion-row>
            <ion-row class="center-item" *ngIf="showCustomerOptions">
                <ion-col>
                    <ion-textarea name="contact-notes" formControlName="notes" placeholder="Enter notes"></ion-textarea>
                </ion-col>
            </ion-row>
            <ion-row class="center-item checkbox-row">
                <ion-col>
                    <ion-checkbox *ngIf="showCustomerOptions" formControlName="receive_invoice" labelPlacement="end"></ion-checkbox>
                    <ion-label *ngIf="showCustomerOptions">Receives a copy of invoice</ion-label>
                </ion-col>
                <ion-col>
                    <ng-container *ngIf="newForm.value.receive_invoice">
                        <ion-checkbox *ngIf="showCustomerOptions" formControlName="all_accounts" labelPlacement="end"></ion-checkbox>
                        <ion-label *ngIf="showCustomerOptions">Receives invoice at all accounts</ion-label>
                    </ng-container>
                </ion-col>
                <ion-col [ngStyle]="{'opacity': !newForm.value.all_accounts ? 1 : 0}">
                    <ng-container>
                        <ion-select #accountSelect *ngIf="newForm.value.receive_invoice" multiple formControlName="invoiceAccounts" class="full-width" 
                        [interfaceOptions]="{'cssClass': 'view-width-50 view-height-80', size: 'cover'}">
                            <ng-container>
                                <ion-select-option [value]="account" *ngFor="let account of accounts">
                                    {{account.name}} - {{account?.account_details ?? account?.locations?.name}}
                                </ion-select-option>
                            </ng-container>
                        </ion-select>
                    </ng-container>
                </ion-col>
            </ion-row>
            <!-- <ion-row class="center-item checkbox-row" *ngIf="showCustomerOptions">
                <ion-col>
                    <ion-checkbox formControlName="receive_invoice" labelPlacement="end"></ion-checkbox>
                    <ion-label>Receives a copy of invoice</ion-label>
                </ion-col>
                <ion-col>
                    <ion-select *ngIf="newForm.value.receive_invoice" multiple formControlName="invoiceAccounts" class="full-width" 
                    [interfaceOptions]="{'cssClass': 'view-width-50 view-height-80', size: 'cover'}">
                        <ion-select-option [value]="account" *ngFor="let account of accounts">
                            {{account.name}} - {{account.locations?.name}}
                        </ion-select-option>
                    </ion-select>
                </ion-col>
            </ion-row> -->

            <ion-row class="center-item checkbox-row">
                <ion-col>
                    <ion-checkbox *ngIf="showCustomerOptions" formControlName="app_access" labelPlacement="end"></ion-checkbox>
                    <ion-label *ngIf="showCustomerOptions">Can Access QAS Track</ion-label>
                </ion-col>
                <ion-col>
                    <ng-container *ngIf="newForm.value.app_access">
                        <ion-checkbox *ngIf="showCustomerOptions" formControlName="all_account_access" labelPlacement="end"></ion-checkbox>
                        <ion-label *ngIf="showCustomerOptions">Allow Access to All Accounts</ion-label>
                    </ng-container>
                </ion-col>
                <ion-col [ngStyle]="{'opacity': !newForm.value.all_account_access ? 1 : 0}">
                    <ng-container>
                        <ion-select #accountSelect *ngIf="newForm.value.app_access" multiple formControlName="accountAccess" class="full-width" 
                        [interfaceOptions]="{'cssClass': 'view-width-50 view-height-80', size: 'cover'}">
                            <ng-container>
                                <ion-select-option [value]="account" *ngFor="let account of accounts">
                                    {{account.name}} - {{account?.account_details ?? account?.locations?.name}}
                                </ion-select-option>
                            </ng-container>
                        </ion-select>
                    </ng-container>
                </ion-col>
            </ion-row>

            <ion-row class="center-item checkbox-row-50 padding-4px">
                <!-- Placeholder to avoid jumpy UI -->
                <ion-col *ngIf="newForm.value.role === 'Quality'">
                    <ion-checkbox formControlName="sendQASInviteEmail" labelPlacement="end"></ion-checkbox>
                    <ion-label>Send QAS Track Invite</ion-label>
                </ion-col>
                <ion-col></ion-col>
                <ion-col></ion-col>
            </ion-row>
        </div>
        <ion-row class="button-row">
            <ion-col>
                <ion-button [disabled]="!newForm.value.firstName || !newForm.value.lastName || !newForm.value.email || (!newForm.value.role && showCustomerOptions)"
                 type="submit" name="add-contact" >Add Contact</ion-button>
            </ion-col>
        </ion-row>
    </form>

</ion-grid>

<ion-select [multiple]="true" class="invisible-select" #accountSelect [(ngModel)]="selectedAccounts" [interfaceOptions]="{'cssClass': 'view-width-50'}">
    <ion-select-option [value]="account.id" *ngFor="let account of accounts">{{account?.name}} - {{account?.account_details ?? account?.locations?.name}}</ion-select-option>
</ion-select>