import { Component, OnInit } from "@angular/core";
import { AdminApiService } from "../../../../../../../app/features/admin/services/admin-api-service";
import { ViewWillEnter } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { ILiaisonAccount, LiaisonAccount } from "../../../../../../../app/shared/models/liaison-account.model";
import { User } from "../../../../../../../app/shared/models/user.model";
import { ILiaisonAccountHistory } from "../../../../../../shared/models/liaison-account-history.model";
import { AlertController } from "@ionic/angular";
import { SharedService } from "../../../../../../../app/shared/services/shared.service";
import { IAccountHistory } from "../../../../../../../app/shared/models/account-history.model";
import { AddLiaisonCoverComponent } from "../add-liaison-cover/add-liaison-cover.component";
import { ILiaisonAccountCover } from "../../../../../../../app/shared/models/liaison-account-cover.model";

@Component({
    selector: 'assign-liaison-history-component',
    templateUrl: 'assign-liaison-history.component.html',
    styleUrls:['./assign-liaison-history.component.scss'],
    providers:[ AdminApiService ]
})
export class AssignLiaisonHistoryComponent implements OnInit {

    closeOnSave: boolean = false;

    liaisonAccountId: number = null;
    liaisonAccount: ILiaisonAccount = null;
    user: User = null;
    history: ILiaisonAccountHistory[] = [];
    originalHistory: ILiaisonAccountHistory[] = [];

    coverHistory: ILiaisonAccountCover[] = [];

    allLiaisonHistory: ILiaisonAccountHistory[] = [];

    accountId: number = null;

    allAccountData: IAccountHistory[] = [];
    accountData: IAccountHistory = null;

    selectedData: ILiaisonAccountHistory = null;
    selectedDataCopy: ILiaisonAccountHistory = null;
    minStartDate: string = null;

    refreshCurrencyInput: boolean = false;

    hasDescrepancy: boolean = false;

    invoiceRate: number = 0;
    contractedHours: number = 0;

    saveInProgress: boolean = false;
    showAll: boolean = false;

    trigger: number = 0;
    loading: boolean = true;

    constructor(
        private modalController: ModalController,
        private adminApiService: AdminApiService,
        private alertController: AlertController,
        public sharedService: SharedService
    ) {

    }

    async ngOnInit(): Promise<void> {
        const promises: any[] = [];

        promises.push(this.getHistory());
        promises.push(this.getCurrentAccountSettings());
        promises.push(this.getCurrentInvoiceRateAndHours());
        promises.push(this.getAllLiaisonAccountHistory());
        promises.push(this.getLiaisonCovers());

        await Promise.all(promises);


        this.isDescrepancyInTimeRange();
        this.loading = false;
    }

    setData(data: ILiaisonAccountHistory): void {
        this.refreshCurrencyInput = true;
        this.selectedData = JSON.parse(JSON.stringify(data));
        this.selectedDataCopy = JSON.parse(JSON.stringify(data));
        
        setTimeout(() => {
            this.refreshCurrencyInput = false;
            this.isDescrepancyInTimeRange();
        }, 0);
    }

    async showAllToggleUpdate(): Promise<void> {
        this.getLiaisonCovers();
    }

    async getHistory(): Promise<void> {
        this.refreshCurrencyInput = true;

        const history = await this.adminApiService.getAllHistoryByLiaisonAccount(this.liaisonAccountId);

        history.forEach((b: ILiaisonAccountHistory) => {
            b.start_date = this.sharedService.getDefaultDate(b.start_date as any) as any;
            b.end_date = b.end_date ? this.sharedService.getDefaultDate(b.end_date as any) as any : null;
        });

        this.history = history;
        
        this.originalHistory = JSON.parse(JSON.stringify(this.history));

        if (this.history.length > 0) {
            this.selectedData = JSON.parse(JSON.stringify(this.history[0]));
            this.selectedDataCopy = JSON.parse(JSON.stringify(this.history[0]));
        } else {
            this.addNew();
        }

        this.refreshCurrencyInput = false;

        if (!this.loading) {
            await this.getAllLiaisonAccountHistory();
            this.isDescrepancyInTimeRange(this.selectedData);
        }
    }

    isSelectedData(data: ILiaisonAccountHistory): boolean {
        return this.selectedData.id === data.id;
    }

    currentEntryMatches(): boolean {
        return JSON.stringify(this.selectedData) === JSON.stringify(this.selectedDataCopy);
    }

    isFieldChanged(field: string): boolean {
        return !(JSON.stringify(this.selectedData[field]) === JSON.stringify(this.selectedDataCopy[field]));
    }

    dateChange(): void {
        if ((this.selectedData?.end_date as any) === '') {
            this.selectedData.end_date = null;
        }

        if ((this.selectedData?.start_date as any) === '') {
            this.selectedData.start_date = null;
        }
    }

    getDisplayEndDate(date: string): Date {
        const d = this.sharedService.stringToDate(date);
        const newDate = this.sharedService.subDaysToDate(d, 1);
 
        return newDate;
    }

    async getLiaisonCovers(): Promise<void> {
        const covers = await this.adminApiService.getLiaisonAccountCoverByLiaisonAccountId(this.liaisonAccountId);

        if (this.showAll) {
            this.coverHistory = covers;
        } else {
            covers.forEach(c => {
                c.start_date = this.sharedService.stringToDate(c.start_date as any);
                c.end_date = c.end_date ? this.sharedService.stringToDate(c.end_date as any) : null;
            });
    
            this.coverHistory = covers.filter(c => (new Date(c.end_date).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)) || !c.end_date)
        }
    }

    async getAllLiaisonAccountHistory(): Promise<void> {
        this.allLiaisonHistory = await this.adminApiService.getAllLiaisonAccountHistoryByAccountId(this.accountId);
    }

    async getCurrentAccountSettings(): Promise<void> {
        this.accountData = await this.adminApiService.getAccountHistoryStartDateLiaisonAccountId(this.liaisonAccountId);

        if (this.accountData && this.accountData.start_date) {
            const date = new Date(new Date(this.accountData.start_date).setHours(24, 0, 0, 0));
            this.minStartDate = `${date.getFullYear()}-${((date.getMonth() + 1) < 10) ? '0' : ''}${date.getMonth() + 1}-${(date.getDate() < 10) ? '0' : ''}${date.getDate()}`;
        }
    }

    async getCurrentInvoiceRateAndHours(): Promise<any> {
        this.allAccountData = await this.adminApiService.getAllAccountHistoryByLiaisonAccount(this.liaisonAccountId);

        var currentDate = new Date(new Date().setHours(0,0,0,0));

        const currentHistory: IAccountHistory = this.allAccountData.find(l => (new Date(new Date(l.start_date)) <= currentDate) &&
        ((new Date(new Date(l.end_date)) >= currentDate) || (l.end_date === null)));

        this.invoiceRate = currentHistory?.invoice_rate ?? 0;
        this.contractedHours = currentHistory?.contracted_hours ?? 0;
    }

    getAccountDataInDateRange(): IAccountHistory[] {
        if (!this.allAccountData || !this.selectedData) {
            return [];
        }

        const history: IAccountHistory[] = this.allAccountData.filter(l => (((new Date(new Date(l.start_date)) <= (new Date(new Date(this.selectedData.end_date)))) || (this.selectedData.end_date === null)) && 
        ((new Date(new Date(this.selectedData.start_date)) <= (new Date(new Date(l.end_date)))) || (l.end_date === null))));

        return history;
    }

    getFormatedDate(data: any): string {
        const date: Date = new Date(new Date(data).setDate(new Date(data).getDate() + 1));
        return `${((date.getMonth() + 1) < 10) ? '0' : ''}${date.getMonth() + 1}/${(date.getDate() < 10) ? '0' : ''}${date.getDate()}/${date.getFullYear()}`;
    }

    async addNew(): Promise<void> {

        var startDate: Date = new Date(new Date().setHours(0, 0, 0, 0));;
        var prev: ILiaisonAccountHistory = null;

        const newHistory: ILiaisonAccountHistory = {
            id: null,
            liaison_account: this.liaisonAccountId,
            liaison: prev?.liaison ?? this.user.id,
            rate: prev?.rate ?? 0,
            hours: prev?.hours ?? 0,
            start_date: this.sharedService.getDefaultDate(prev ? startDate.toISOString() : startDate.toISOString().split('T')[0]) as any,
            end_date: null
        };

        this.history.push(newHistory);

        this.setData(newHistory);
    }

    isDisabledEntry(data?: ILiaisonAccountHistory): boolean {
        if (!data) {
            if (!this.selectedData) {
                return false;
            }

            data = this.selectedData;
        }

        if (!data.end_date) {
            return false;
        }

        const currentDate = new Date(new Date().setHours(0,0,0,0));

        return (new Date(new Date(data.end_date).setHours(24,0,0,0)) < currentDate);
    }

    isDescrepancyInTimeRange(data?: ILiaisonAccountHistory): boolean {
        this.hasDescrepancy = false;
        var counter = 0;

        // Only show warnings for future entries
        if (this.selectedData.end_date && (this.sharedService.stringToEndDate(this.selectedData.end_date as any) <= new Date())) {
            return;
        }

        const history: IAccountHistory[] = this.allAccountData.filter(l => 
            ((this.sharedService.stringToStartDate(l.start_date as any) <= this.sharedService.stringToEndDate(this.selectedData.end_date as any))
            || (this.selectedData.end_date === null)) && 
            (this.sharedService.stringToStartDate(l.end_date as any) > this.sharedService.stringToEndDate(this.selectedData.start_date as any)) 
            || (l.end_date === null));

        const liaisonHistory: ILiaisonAccountHistory[] = this.allLiaisonHistory.filter(l => 
            ((this.sharedService.stringToStartDate(l.start_date as any) <= this.sharedService.stringToEndDate(this.selectedData.end_date as any))
            || (this.selectedData.end_date === null)) && 
            (this.sharedService.stringToStartDate(l.end_date as any) > this.sharedService.stringToEndDate(this.selectedData.start_date as any)) 
            || (l.end_date === null));

        var continueLoop = true;

        if (!history || !liaisonHistory || (history.length <= 0) || (liaisonHistory.length <= 0)) {
            continueLoop = false;
        }

        // var startDate = this.sharedService.stringToDate(this.selectedData.start_date as any);
        var startDate = this.sharedService.stringToDate(new Date().toISOString());

        while (continueLoop) {
            const accountHistory = history.find(c => {
                return ((this.sharedService.stringToStartDate(c.start_date as any) <= startDate) &&
                ((this.sharedService.stringToEndDate(c.end_date as any) > startDate) || (c.end_date === null)));
            });

            //if account ends on that day STOP
            if (!accountHistory) {
                continueLoop = false;
                continue;
            }

            const contractedHours = accountHistory?.contracted_hours;

            const liaisonAccountHistory: ILiaisonAccountHistory[] = [];

            if ((contractedHours === null) || (contractedHours === undefined)) {
                continueLoop = false;
                continue;
            }

            const liaisonHistoryOnDay = liaisonHistory?.filter(l => (new Date(new Date(l.start_date).setHours(24,0,0,0)) <= startDate) &&
            ((new Date(new Date(l.end_date).setHours(24,0,0,0)) > startDate) || (l.end_date === null)));

            if (liaisonHistoryOnDay) {
                liaisonHistoryOnDay.forEach(h => {
                    liaisonAccountHistory.push(h);
                })
            }

            const assignedTotalForDay: number = liaisonHistoryOnDay.reduce((prev, curr) => {
                return prev += (curr?.hours ?? 0)
            }, 0);

            if (assignedTotalForDay !== contractedHours) {

                this.hasDescrepancy = true;
            }

            //if all liaisons are at null and account has no end date STOP
            //if all liaisons are unassigned STOP
            if (!accountHistory.end_date && ((liaisonAccountHistory.length <= 0) || (liaisonAccountHistory.every(l => (l.end_date === null))))) {
                continueLoop = false;
            }

            // Safe guard against infinite loop due to invalid data
            if (counter > 10000) {
                continueLoop = false;
            }

            // increase the day by one each loop
            startDate = new Date(new Date(JSON.parse(JSON.stringify(new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1))))));
            counter++;
        }

        return this.hasDescrepancy;
    }

    async save(): Promise<void> {
        if (this.selectedData.end_date && (new Date(this.selectedData.start_date) > new Date(this.selectedData.end_date))) {
            this.sharedService.presentToast('warning', 'End date must be greater than start date.', 'Invalid data', 'long');
            return;
        }

        if (this.saveInProgress) {
            return;
        }

        this.saveInProgress = true;

        var showWarning: boolean = false;

        const promises: any[] = [];
        const newestHistory = this.getNewestHistory();

        var addNewEntry: boolean = false;
        var continueProcessing: boolean = true;

        if (this.originalHistory.length <= 0) {
            continueProcessing = false;
            addNewEntry = true;
        }

        this.history.forEach(h => {
            if ((this.selectedData.start_date === h.start_date) && (this.selectedData.end_date === h.end_date) && continueProcessing) {
                continueProcessing = false;
                const id = h.id;
                var clone = JSON.parse(JSON.stringify(this.selectedData));
                clone.id = id;

                promises.push(this.adminApiService.updateHistoryByLiaisonAccount(clone));
            }
        });

        if ((new Date(this.selectedData.start_date) >= new Date(newestHistory.start_date)) && continueProcessing) {
            continueProcessing = false;

            if (this.selectedData.start_date === newestHistory.start_date) {
                showWarning = true;
                var clone = JSON.parse(JSON.stringify(newestHistory));
                clone.end_date = this.selectedData.end_date;

                promises.push(this.adminApiService.updateHistoryByLiaisonAccount(clone));
            } else {
                addNewEntry = true;

                // If there is no end date or the new start date overlaps existing end date update end date to new start date
                if (!newestHistory.end_date || (newestHistory.end_date && (new Date(this.selectedData.start_date) < new Date(newestHistory.end_date)))) {
                    showWarning = true;
                    var clone = JSON.parse(JSON.stringify(newestHistory));
                    clone.end_date = this.selectedData.start_date;

                    promises.push(this.adminApiService.updateHistoryByLiaisonAccount(clone));
                } 
            }
        }

        var previousHistory = null;

        if (continueProcessing) {
            addNewEntry = true;
            showWarning = true;

            this.history.forEach((h, i) => {
                 if ((new Date(this.selectedData.start_date) > new Date(h.start_date)) && 
                 (new Date(this.selectedData.start_date) < new Date(h.end_date)) && 
                 (((new Date(this.selectedData.end_date) >= new Date(h.end_date)) && this.selectedData.end_date && h.end_date) || !this.selectedData.end_date)) {
                    //set end date to new start date
                    const clone = JSON.parse(JSON.stringify(h));
                    clone.end_date = this.selectedData.start_date;
    
                    promises.push(this.adminApiService.updateHistoryByLiaisonAccount(clone));
                } else if ((new Date(this.selectedData.start_date) <= new Date(h.start_date)) && 
                (((new Date(this.selectedData.end_date) >= new Date(h.end_date)) && this.selectedData.end_date && h.end_date) || !this.selectedData.end_date)) {
                    // delete existing entry
                    promises.push(this.adminApiService.deleteHistoryByLiaisonAccount(h.id));
                } else if ((new Date(this.selectedData.start_date) <= new Date(h.start_date)) && 
                (((new Date(this.selectedData.end_date) < new Date(h.end_date)) && this.selectedData.end_date && h.end_date) || !h.end_date) &&
                (((new Date(this.selectedData.end_date) > new Date(h.start_date)) && this.selectedData.end_date) || !this.selectedData.end_date)) {
                    // Set start date to new end date
                    const clone = JSON.parse(JSON.stringify(h));
                    clone.start_date = this.selectedData.end_date;

                    promises.push(this.adminApiService.updateHistoryByLiaisonAccount(clone));
                } else if ((new Date(this.selectedData.start_date) > new Date(h.start_date)) && 
                ((new Date(this.selectedData.end_date) < new Date(h.end_date)) && this.selectedData.end_date && h.end_date)) {
                    // delete existing entry
                    promises.push(this.adminApiService.deleteHistoryByLiaisonAccount(h.id));

                    // Split entry if new entry cuts existing entry in half
                    const newEntryOne = JSON.parse(JSON.stringify(h));
                    const newEntryTwo = JSON.parse(JSON.stringify(h));

                    newEntryOne.id = null;
                    newEntryTwo.id = null;
                    newEntryOne.end_date = this.selectedData.start_date;
                    newEntryTwo.start_date = this.selectedData.end_date;

                    promises.push(this.adminApiService.postHistoryByLiaisonAccount(newEntryOne));
                    promises.push(this.adminApiService.postHistoryByLiaisonAccount(newEntryTwo));
                }
    
                previousHistory = h;
            });
        }

        if ((this.sharedService.stringToEndDate(this.selectedData.start_date as any) < new Date())) {
            const alert = await this.alertController.create({
                header: `Liaison history will be changed.`,
                message: `Would you like to continue?`,
                cssClass: 'custom-alert alert-button large-max-width-alert',
                buttons: [
                    {
                        text: 'Confirm',
                        handler: async () => {
                            if (addNewEntry) {
                                this.selectedData.id = null;
                                promises.push(this.adminApiService.postHistoryByLiaisonAccount(this.selectedData));
                            }
                    
                            const data = await Promise.all(promises);
                    
                            await this.getHistory();

                            this.sharedService.presentToast('primary', '', 'Liaison history updated!', 'med');

                            if (this.closeOnSave) {
                                this.close(true);
                            }
                        },
                        cssClass: 'alert-button'
                    },
                    {
                        text: 'Cancel',
                        cssClass: 'alert-button no'
                    }
                ],
            });
            
            await alert.present();
            await alert.onDidDismiss(); 
        } else {
            if (addNewEntry) {
                this.selectedData.id = null;
                promises.push(this.adminApiService.postHistoryByLiaisonAccount(this.selectedData));
            }
    
            const data = await Promise.all(promises);
    
            await this.getHistory();
            this.sharedService.presentToast('primary', '', 'Liaison history updated!', 'med');

            if (this.closeOnSave) {
                this.close(true);
            }
        }

        this.saveInProgress = false;
    }

    getNewestHistory(): ILiaisonAccountHistory {
        if (!this.history || this.history.length <= 0) {
            return null;
        }

        return this.history.sort((a, b) => {
            if (a.start_date > b.start_date) return -1;
            if (a.start_date < b.start_date) return 1;
            return 0;
        })[0];
    }

    async unassign(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Are you sure?',
            message: 'This liaison will no longer be assigned to this account.',
            cssClass: 'custom-alert alert-button',
            buttons: [
            {
                text: 'Yes',
                handler: async () => {
                    const deletedLiaisonAccount = await this.adminApiService.deleteLiaisonAccount(this.liaisonAccountId);
                    this.sharedService.presentToast('primary', 'Liaison Account has been removed.', 'Success!', 'med');
                    this.close(true);
                },
                cssClass: 'alert-button'
            },
            {
                text: 'No',
                cssClass: 'alert-button no'
            }
            ],
        });

        await alert.present();
        await alert.onDidDismiss(); 
    }

    refreshPipe(): void {
        this.trigger++;

        this.setEndDate();

        const data = this.allLiaisonHistory.find(a => a.id === this.selectedData.id);

        if (data) {
            // Updates descrepancy check 
            data.hours = this.selectedData.hours;
            this.isDescrepancyInTimeRange();
        }
    }

    setEndDate(): void {
        var previous: ILiaisonAccountHistory = null;
        var next: ILiaisonAccountHistory = null;

        this.history.sort((a, b) => {
            if (a.start_date > b.start_date) {
                return -1;
            }
            if (a.start_date < b.start_date) {
                return 1;
            }
            
            return 0;
        }).forEach((b: ILiaisonAccountHistory) => {
            if (!previous) {
                b.end_date = (b.end_date && ((b.end_date as any) !== 'NaN-NaN-NaN')) ? b.end_date : null;

                if (b.end_date && (new Date(b.end_date) < new Date(b.start_date))) {
                    (b.end_date as any) = this.sharedService.getDefaultDate(new Date(new Date(b.start_date).setDate(new Date(b.start_date).getDate() + 1)).toISOString());
                }
            } else {
                b.end_date = previous.start_date;

                if (b.end_date && (new Date(b.end_date) < new Date(b.start_date))) {
                    (b.end_date as any) = this.sharedService.getDefaultDate(new Date(new Date(b.start_date).setDate(new Date(b.start_date).getDate() + 1)).toISOString());
                }
            }

            previous = b;
        });
    }

    isNewestEntry(entry: ILiaisonAccountHistory): boolean {
        var isNewest: boolean = true;

        this.history.forEach(h => {
            if (h.start_date > entry.start_date) {
                isNewest = false;
            }
        });

        return isNewest;
    }

    compare(a: ILiaisonAccountHistory, b: ILiaisonAccountHistory): boolean {
        return (a.end_date === b.end_date) && (a.hours === b.hours) && (a.start_date === b.start_date) && (a.rate == b.rate) && (a.liaison === b.liaison);
    }

    dateUnfocused(): void {
        this.setEndDate();
        this.trigger++;
    }

    isLastEntry(entry: ILiaisonAccountHistory): boolean {
        return this.history.length <= 1;
    }

    async remove(data: ILiaisonAccountHistory): Promise<void> {
        const alert = await this.alertController.create({
            header: `The selected liaison account history for ${data.start_date} to ${data?.end_date ?? 'Future'} will be permanently deleted.`,
            message: `Would you like to continue?`,
            cssClass: 'custom-alert alert-button large-max-width-alert',
            buttons: [
                {
                    text: 'Confirm',
                    handler: async () => {
                        try {
                            // Delete entry
                            await this.adminApiService.deleteHistoryByLiaisonAccount(data?.id);
                            // Update data on timeline
                            this.history = this.history.filter(h => h.id !== data?.id);
                            this.selectedData = this.history[0];
                            this.sharedService.presentToast('primary', '', 'Liaison Account history deleted successfully.', 'med');
                        } catch (error) {
                            console.log(error);
                            this.sharedService.presentToast('danger', 'Liaison Account history failed to delete successfully.', 'Unknown Error Occurred', 'long');
                        }
                    },
                    cssClass: 'alert-button'
                },
                {
                    text: 'Cancel',
                    handler: async () => {
                        
                    },
                    cssClass: 'alert-button no'
                }
            ],
        });
        
        await alert.present();
        await alert.onDidDismiss();
    }

    getCurrentHistory(): ILiaisonAccountHistory {
        const currentHistory = this.history.find(h => this.sharedService.stringToStartDate(h.start_date as any) <= new Date() &&
         ((this.sharedService.stringToEndDate(h.end_date as any) >= new Date()) || !h.end_date));

        return currentHistory;
    }

    async addLiaisonCover(): Promise<void> {
        const modal = await this.modalController.create({
            component: AddLiaisonCoverComponent,
            componentProps: {
                accountId: this.accountId,
                liaisonAccountId: this.liaisonAccountId,
                userId: this.user?.id,
                payRate: this.getCurrentHistory()?.rate
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            await this.getLiaisonCovers();
            this.sharedService.presentToast('primary', 'Liaison account cover was created successfully.', 'Success!', 'med');
        }

    }

    async editCover(coverData: ILiaisonAccountCover): Promise<void> {
        const modal = await this.modalController.create({
            component: AddLiaisonCoverComponent,
            componentProps: {
                accountId: this.accountId,
                liaisonAccountId: this.liaisonAccountId,
                userId: this.user?.id,
                liaisonCover: coverData
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            await this.getLiaisonCovers();
            this.sharedService.presentToast('primary', 'Liaison account cover was updated successfully.', 'Success!', 'med');
        }
    }

    close(update: boolean = true): void {
        this.modalController.dismiss(update);
    }

}