// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SharedService } from '../../../../app/shared/services/shared.service';
import { NavController } from '@ionic/angular';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router,
    private sharedService: SharedService,
    private navController: NavController) { }

  isAuthenticated(): boolean {
    const loginToken = this.sharedService.localStorageGet('authToken');
    // Check whether the token is expired and return
    // true or false
    return loginToken && loginToken !== '';
     // implement tighter checking here maybe an additional sep api call to check if token expired
     // we dont want the app to route at all without proper login
  }

  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.navController.navigateRoot(['/login']);
      return false;
    }
    return true;
  }
}
