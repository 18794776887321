import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ViewWillEnter } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { LiaisonIssue } from "../../../../../../../app/shared/models/liaison-issue.model";
import { LiaisonIssueUpdate } from "../../../../../../../app/shared/models/liaison-issue-updates.model";
import { LiaisonApiService } from "../../../../../../../app/features/liaison/services/liaison-api-service";
import { LiaisonStateService } from "../../../../../../../app/features/liaison/services/liaison-state-service";
import { Contact, PrimaryContact, ExtendedContact } from "../../../../../../../app/shared/models/contact.model";
import { Router } from "@angular/router";
import { AddContactComponent } from "../add-contact/add-contact.component";
import { SharedService } from "../../../../../../../app/shared/services/shared.service";
import { ILiaisonAccount } from "../../../../../../../app/shared/models/liaison-account.model";
import { ILiaisonContact } from "../../../../../../../app/shared/models/liaison-contact.model";
import { EditContactComponent } from "../edit-contact/edit-contact.component";
import { AlertController } from "@ionic/angular";

@Component({
  selector: "contact-list-component",
  templateUrl: "contact-list.component.html",
  styleUrls: ["./contact-list.component.scss"],
  providers: [LiaisonApiService],
})
export class ContactListComponent implements OnInit {
  reportId: number[] = [];
  accountId: number = null;
  primaryContact: ExtendedContact = null;
  liaisonAccount: ILiaisonAccount = null;
  allEmails: any[] = [];

  showExplaination: boolean = false;

  primaryEmail: string = null;

  contacts: ILiaisonContact[] = [];

  syncUpdates: boolean = true;

  loaded: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private liaisonApiService: LiaisonApiService,
    private liaisonStateService: LiaisonStateService,
    private router: Router,
    private sharedService: SharedService,
    private alertController: AlertController
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getAllContacts();
    await this.getPrimaryContact();

    this.loaded = true;
  }

  checkboxChange(email: any, event: any): void {
    email.active = event.detail.checked;
  }

  async deleteRecipient(recipient: any): Promise<void> {
    const alert = await this.alertController.create({
      message: `This recipient will be permanently deleted from this accounts recipient list. This change will only affect your user.`,
      header: `Are you sure?`,
      cssClass: 'custom-alert alert-button large-max-width-alert',
      buttons: [
        {
          text: 'Confirm',
          handler: async () => {
            try {
              // Delete entry
              await this.deleteContactByEmail(recipient.email)
            } catch (error) {
              console.log(error);
              this.sharedService.presentToast('danger', 'Contact failed to delete successfully.', 'Unknown Error Occurred', 'long');
            }
          },
          cssClass: 'alert-button'
        },
        {
          text: 'Cancel',
          handler: async () => {
              
          },
          cssClass: 'alert-button no'
        }
      ],
    });
    
    await alert.present();
    await alert.onDidDismiss();
  }

  async getPrimaryContact(): Promise<void> {
    this.primaryContact = await this.liaisonApiService.getPrimaryContact(
      this.accountId
    );

    this.primaryEmail = this.primaryContact?.contacts?.email;

    if (this.primaryContact && this.contacts && !this.contacts.map(c => c.email).includes(this.primaryContact.contacts?.email)) {
      this.allEmails.push({
        email: this.primaryContact.contacts.email,
        active: true,
      });
    }
  }

  async getAllContacts(): Promise<void> {
    this.contacts = await this.liaisonApiService.getAllLiaisonContactsByAccount(this.liaisonAccount?.id);

    if (this.contacts) {
      this.contacts.forEach(c => {
        this.allEmails.push({
          email: c.email,
          active: true
        });
      });
    }
  }

  async editEmail(email: string): Promise<void> {
    const contact = this.contacts.find(c => c.email === email);

    if (contact) {
      const modal = await this.modalCtrl.create({
        component: EditContactComponent,
        componentProps: {
          contact: contact
        },
        cssClass: "min-width-modal grey-background",
        showBackdrop: true,
        backdropDismiss: true,
        keyboardClose: true,
        swipeToClose: true,
      });
      await modal.present();
  
      const { data } = await modal.onDidDismiss();
  
      if (data && contact) {
        if (this.syncUpdates) {
          await this.liaisonApiService.deleteLiaisonContact(contact.id);
        }
  
        const value = this.allEmails.find(a => a.email === email);
        const index = this.allEmails.indexOf(value);
        this.allEmails.splice(index, 1);
      }
    }
  }

  async deleteContactByEmail(email: string): Promise<void> {
    const contact = this.contacts.find(c => c.email === email);

    if (!contact) {
      return;
    }

    if (this.syncUpdates) {
      await this.liaisonApiService.deleteLiaisonContact(contact.id);
    }

    const value = this.allEmails.find(a => a.email === email);
    const index = this.allEmails.indexOf(value);
    this.allEmails.splice(index, 1);
  }

  async addContact(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: AddContactComponent,
      componentProps: {},
      cssClass: "min-width-modal grey-background",
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (data) {

      const newContact: ILiaisonContact = {
        liaison_account: this.liaisonAccount?.id,
        email: data
      }

      if (this.allEmails.map(a => a.email).includes(data)) {
        this.sharedService.presentToast('primary', '', 'Email already exist for this account!', 'med');
      } else {
        if (this.syncUpdates) {
          const liaisonNewContact: ILiaisonContact = await this.liaisonApiService.postLiaisonContact(newContact);
          this.contacts.push(liaisonNewContact);
        } else {
          this.contacts.push(newContact);
        }

        this.allEmails.push({ email: data, active: true });
      }
    }
  }

  send(): void {
    if (this.allEmails.filter(e => e.active).length > 0) {
      this.modalCtrl.dismiss(this.allEmails);
    } else {
      this.sharedService.presentToast('warning', '', 'Report must contain atleast one recipient to submit!', 'med');
    }
    
  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }
}
