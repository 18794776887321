<ion-row class="issue-row" *ngFor="let issue of issues">
    <ion-col class="ion-no-padding">
        <ion-row class="issue-top-section">
            <ion-col class="ion-no-padding issue-title">
                <span [ngClass]="{ 'concern-text': issue.concern }">{{issue.description}} {{issue.concern ? (' - ' + issue.concern_written) : ''}}</span>
            </ion-col>
            <ion-col *ngIf="issue.concern" size="auto" class="ion-no-padding issue-badge-col">
                <div class="concern-badge badge">
                    <ion-icon name="warning-outline"></ion-icon>
                    <span>Concern Written</span>
                </div>
            </ion-col>
        </ion-row>

        <ng-container *ngIf="issue.closing_update">
            <ion-row class="closing-update">
                <ion-col class="ion-no-padding">
                    <span>Closed {{issue.closing_update.time_created}}</span>
                </ion-col>
            </ion-row>
            <ion-row class="closing-update">
                <ion-col class="ion-no-padding">
                    <span>{{issue.closing_update.content}}</span>
                </ion-col>
            </ion-row>
        </ng-container>

        <ion-row>
            <ion-col class="ion-no-padding open-date-text">
                <span>Opened {{issue.date_created}}</span>
            </ion-col>
        </ion-row>

        <div class="indent">
            <ion-row class="action-taken-section">
                <ion-col class="ion-no-padding">
                    <ion-row *ngIf="issue.impacted_parts">
                        <ion-col class="ion-no-padding">
                            <span>Part Number/Description: {{issue.impacted_parts}}</span>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="issue.parts_found" >
                        <ion-col class="ion-no-padding">
                            <span>{{issue.parts_found}} unit{{issue.parts_found === 1 ? '' : 's'}} found</span>
                            <span *ngIf="issue.status !== 'new' && issue.status !== 'closed'">&nbsp;({{issue.parts_found_this_week}} found this week)</span>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.actionUpdate && issue.actionUpdate?.created_this_week && issue.status === 'new'" class="action-taken-section">
                <ion-col class="ion-no-padding">
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <span>Area in Plant Where Found: {{issue.actionUpdate.area_found}}</span>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <span>Action Taken: {{issue.actionUpdate.content}}</span>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row class="sub-header" *ngIf="(issue.issue_updates | updateFilter).length > 0">
                <ion-col class="ion-no-padding">
                    Updates:
                </ion-col>
            </ion-row>
            <ng-container *ngFor="let update of issue.issue_updates | updateFilter">
                <ion-row class="issue-update-row" *ngIf="!update.dontShow">
                    <ion-col class="ion-no-padding">
                        <ion-row *ngIf="update.time_created">
                            <ion-col class="ion-no-padding open-date-text">
                                {{update.is_closing_update ? 'Closed ' : ''}}{{update.time_created}}
                            </ion-col>
                        </ion-row>
                        <ion-row class="indent action-taken-section" *ngIf="update.content">
                            <span>{{update.content}}</span>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ng-container>
            <ion-row class="sub-header" *ngIf="issue.issue_images?.length > 0">
                <ion-col class="ion-no-padding">
                    Attachments:
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.issue_images?.length > 0">
                <app-attachment-upload
                    #attachmentUpload
                    [condensed]="true"
                    [id]="issue.id"
                    [isDisabled]="true"
                    [context]="'issue'"
                    [startHidden]="true"
                    [showButtons]="false"
                    [showAttachmentCount]="true"
                    [paddingAmount]="'0px'"
                    [imageUIOnly]="true"
                    [showDelete]="false"
                ></app-attachment-upload>
            </ion-row>
        </div>
    </ion-col>
</ion-row>