export interface IAttachment {
    id: number;
    image_id: number;
    images: IImage;
}

export interface IImage {
    id: number;
    image_url: string;
    time_created: Date;
    user_id: number;
}

export interface IReportAttachment {
    userId: number;
    imageUrl: string;
    reportId: number;
}

export interface IIssueAttachment {
    userId: number;
    imageUrl: string;
    issueId: number;
}

export interface IRejectAttachment {
    userId?: number;
    imageUrl?: string;
    rejectId?: number;
    id?: number,
    date?: Date,
    url?: string
}

// export class Image implements IAttachment {
//     id?: number;
//     date: Date;
//     url: string;
//     constructor(url:string){
//         this.url = url;
//     }
// }