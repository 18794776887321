import { Injectable, Type, OnDestroy } from "@angular/core";
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpEvent, HttpClient } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { Observable, catchError, firstValueFrom, from, of, throwError } from "rxjs";
import { ICacheObject, IFirebaseUploadObject, IOfflineIdentifierData, IQueueObject } from "../models/cache.model";
import { finalize, map } from 'rxjs/operators';
import { empty, TimeoutError } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { Network, ConnectionStatus } from '@capacitor/network';
import { HttpMethod } from "../models/cache.model";
import { environment } from "../../../environments/environment";
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage'; 
import { SharedService } from "./shared.service";
import { LiaisonStateService } from "../../../app/features/liaison/services/liaison-state-service";
import { decode } from 'base64-arraybuffer';

@Injectable({
    providedIn: 'root'
})
export class CacheInterceptor implements OnDestroy {

    protected _offline: boolean = false; //false

    API_BASE_URL: string = null;

    get isOffline(): boolean {
        return this._offline;
    }

    get isSyncing(): boolean {
        return this.syncInProgress;
    }

    set setOffline(value: boolean) {
        if ((value === false) && (this._offline === true)) {
            this._offline = value;
            this.syncQueue();
        } else {
            this._offline = value;
        }

        // Recalculates height of items on the screen since the offline border will be add/removed
        window.dispatchEvent( new Event('recalculateScreenHeight') );
    }

    timeoutValue: number = 6000;

    queueStorageKey: string = 'qas-pending-request-queue';
    firebaseQueueStorageKey: string = 'qas-pending-firebase-request-queue';

    syncInProgress: boolean = false;

    connectionListener: any = null;

    constructor(
        private storageService: StorageService,
        private httpClient: HttpClient,
        private angularFireStorage: AngularFireStorage,
        private sharedService: SharedService,
        private liaisonStateService: LiaisonStateService
    ) {
        this.monitorConnection();
        this.initiateStorageQueue();

        this.API_BASE_URL = environment.API_URL;
    }

    async initiateStorageQueue(): Promise<void> {
        const queueStorage = localStorage.getItem(this.queueStorageKey);
        
        if (!queueStorage) {
            localStorage.setItem(this.queueStorageKey, '[]');
        }
    }

    async processRequest<T,>(url: string, method: HttpMethod, returnsArray: boolean, body?: any, parentIdentifierObject?: IOfflineIdentifierData, cacheOverride?: boolean, retryCount: number = 3): Promise<T> {
        //move time out logic to actual call
        // May want to cache here as well so we dont cache admin requests
        
        var $requestObservable: Observable<T> = null;
        
        switch (method) {
            case 'GET':
                $requestObservable = this.httpClient.get<T>(url);
                break;
            case 'POST':
                $requestObservable = this.httpClient.post<T>(url, body ?? {});
                break;
            case 'PATCH':
                $requestObservable = this.httpClient.patch<T>(url, body ?? {});
                break;
            case 'DELETE':
                $requestObservable = this.httpClient.delete<T>(url);
                break;
        }
        
        var response = null;

        await new Promise((resolve, reject) => {
            firstValueFrom(
                $requestObservable.pipe(
                    timeout(this.timeoutValue),
                    catchError((error) => {
                        return [];
                    })
                )
            ).then((data) => {
                response = data;

                if (method === 'GET' || cacheOverride) {
                    const cacheObject: ICacheObject = {
                        url,
                        type: method,
                        value: data,
                        timeStamp: new Date()
                    };   

                    this.storageService.set(url, cacheObject);
                }

                // If request is successful offline mode will be turned off
                this.setOffline = false;

                resolve(null);
            }).catch(async (error) => {
                if (error.name === 'EmptyError') {
                    // If request failed on timeout it will turn on offline mode
                    this.setOffline = true;

                    if (method === 'GET' || cacheOverride) {
                        const cachedValue: ICacheObject = await this.getCacheObjectByURL(url);
    
                        response = cachedValue?.value ?? (returnsArray ? [] : null);
                    } else {
                        // Adds request to queue for when internet returns
                        const queueValue: IQueueObject = {
                            url,
                            type: method,
                            timeStamp: new Date(),
                            body,
                            returnsArray,
                            processed: false,
                            identifier: this.getUniqueId(),
                            parentIdentifier: parentIdentifierObject?.parentIdentifier ?? null,
                            replacementField: parentIdentifierObject?.replacementField ?? null,
                            firebaseIdentifier: parentIdentifierObject?.firebaseIdentifier ?? null,
                            retryRemaining: retryCount
                        };

                        response = queueValue.identifier;
                        
                        if (retryCount > 0) {
                            this.addValueToQueue(queueValue);
                        } else {
                            this.sharedService.presentToast('warning', 'One or more offline request were not completed due to max retry limit being reached. Please try again when connection is restored.', 'Offline sync aborted.', 'extralong');
                        }
                    }
                }

                resolve(null);
            });
        });

        return response;
    }

    async checkConnection(): Promise<boolean> {
        // Check if connection is restored if in offline mode
        const online: boolean = await this.testConnection();
        this.setOffline = !online;

        return online;
    }

    async syncQueue(): Promise<void> {
        
        if (!this.syncInProgress) {
            this.syncInProgress = true;

            if (this.liaisonStateService.getRole() === 'liaison') {
                this.sharedService.presentToast('primary', '', 'Offline Request Syncing In Progress...', 'med');
            }

            // Resolve all failed POST, PATCH, and DELETE requests in queue
            const queueStorage = localStorage.getItem(this.queueStorageKey);

            if (queueStorage) {
                var parsedStorage: IQueueObject[] = JSON.parse(queueStorage);

                // Array that holds all queue object that dont require a parent request to be resolved first
                var parentQueueStorage: IQueueObject[] = parsedStorage.filter(p => (!p.parentIdentifier && !p.firebaseIdentifier)).sort((a, b) => this.sortQueue(a, b));
                // Array that holds all queue object that require a parent request to be resolved first
                var childQueueStorage: IQueueObject[] = parsedStorage.filter(p => (p.parentIdentifier || p.firebaseIdentifier)).sort((a, b) => this.sortQueue(a, b));

                await new Promise(async (resolve, reject) => {
                    if (!parentQueueStorage || (parentQueueStorage.length <= 0)) {
                        resolve(null);
                    }

                    // Use for loop (not foreach) to insure order of queue
                    for (let i = 0; i < parentQueueStorage.length; i++) {
                        if (!parentQueueStorage[i].processed) {
                            const result: any = await this.processRequest(parentQueueStorage[i].url, parentQueueStorage[i].type, parentQueueStorage[i].returnsArray, parentQueueStorage[i].body, undefined, undefined, (parentQueueStorage[i].retryRemaining - 1));

                            const childObjects: IQueueObject[] = childQueueStorage.filter(c => c.parentIdentifier === parentQueueStorage[i].identifier);

                            childObjects.forEach(c => {
                                // Set resolved parent id on all child objects to the id of the created database object
                                c.resolveParentObject = result;
                                c.resolvedParentId = result?.id;
                            });

                            parentQueueStorage[i].processed = true;
                        }

                        if (i === (parentQueueStorage.length - 1)) {
                            resolve(null);
                        }
                    }
                });

                await new Promise(async (resolve, reject) => {
                    if (!childQueueStorage || (childQueueStorage.length <= 0)) {
                        resolve(null);
                    }

                    // Use for loop (not foreach) to insure order of queue
                    for (let i = 0; i < childQueueStorage.length; i++) {

                        if (!childQueueStorage[i].processed) {

                            if (childQueueStorage[i].resolvedParentId) {
                                if (childQueueStorage[i]?.body) {
                                    childQueueStorage[i].body[childQueueStorage[i].replacementField] = childQueueStorage[i].resolvedParentId;
                                }
                            } else if (childQueueStorage[i].resolveParentObject) {
                                childQueueStorage[i].body[childQueueStorage[i].replacementField] = this.processParentObject(childQueueStorage[i].resolveParentObject, childQueueStorage[i].replacementField);
                            }

                            if (childQueueStorage[i].firebaseIdentifier) {
                                // sync firebase object with identifier and return download url
                                const downloadURL: string = await this.syncFirebaseObject(childQueueStorage[i].firebaseIdentifier);

                                if (downloadURL && childQueueStorage[i]?.body) {
                                    childQueueStorage[i].body['imageUrl'] = downloadURL;
                                }
                            }

                            await this.processRequest(childQueueStorage[i].url, childQueueStorage[i].type, childQueueStorage[i].returnsArray, childQueueStorage[i].body, undefined, undefined, (childQueueStorage[i].retryRemaining - 1));

                            childQueueStorage[i].processed = true;
                        }

                        if (i === (childQueueStorage.length - 1)) {
                            resolve(null);
                        }
                    }
                });

                console.log('Queue Processing Complete');

                // Filter paredStorage still works since parent and child lists are updated by reference
                const updatedQueue: IQueueObject[] = parsedStorage.filter(p => !p.processed);

                localStorage.setItem(this.queueStorageKey, JSON.stringify(updatedQueue));

                this.syncInProgress = false;

                window.dispatchEvent( new Event('offlineSyncComplete') );

                if (this.liaisonStateService.getRole() === 'liaison') {
                    this.sharedService.presentToast('primary', '', 'Offline Request Syncing Complete', 'med');
                }
            }
        }
    }

    // This function is for a very specific endpoint result on the sorts page
    processParentObject(parentObject: {sort: any, accept: any} | any, replacementField: string): number {
        if (replacementField === 'sortId') {
            return (parentObject as {sort: any, accept: any})?.sort?.id;
        } else {
            return (parentObject as {sort: any, accept: any})?.accept?.id;
        }
    }

    manuallyCreateCacheObject(url: string, data: any): void {
        const cacheObject: ICacheObject = {
            url,
            type: `GET`,
            value: data,
            timeStamp: new Date()
        };   

        this.storageService.set(url, cacheObject);
    }

    async createFirebaseCacheObject(base64Image: string, filePath: string, type: string): Promise<string> {
        const cacheObject: IFirebaseUploadObject = {
            base64: base64Image,
            filePath,
            type,
            identifier: this.getUniqueId(),
            processed: false,
            retryRemaining: 3
        };

        const firebaseStorage: IFirebaseUploadObject[] = await this.storageService.get(this.firebaseQueueStorageKey);

        if (firebaseStorage) {
            firebaseStorage.push(cacheObject);

            this.storageService.set(this.firebaseQueueStorageKey, firebaseStorage);
        } else {
            this.storageService.set(this.firebaseQueueStorageKey, [cacheObject]);
        }

        return cacheObject.identifier;
    }

    async syncFirebaseObject(identifier: string): Promise<string> {
        const firebaseStorage: IFirebaseUploadObject[] = await this.storageService.get(this.firebaseQueueStorageKey);

        const syncingObject: IFirebaseUploadObject = firebaseStorage.find(f => f.identifier === identifier);

        if (!syncingObject) {
            return null;
        }

        syncingObject.processed = true;

        var returnURL: string = null;

        const blob = new Blob([new Uint8Array(decode(syncingObject.base64))], {
            type: `image/${syncingObject.type}`
        })

        const fileRef = this.angularFireStorage.ref(syncingObject.filePath);
        const task = this.angularFireStorage.upload(syncingObject.filePath, blob);

        if (!fileRef || !task) {
            return null;
        }

        await new Promise((resolve, reject) => {
            task.snapshotChanges().pipe(finalize(() => {
                fileRef.getDownloadURL().subscribe((downloadURL) => {
                    if (downloadURL) {
                        returnURL = downloadURL;
                    }

                    resolve(null);
                });
            })).subscribe();
        });

        // Remove resolved firebase storage 
        this.storageService.set(this.firebaseQueueStorageKey, firebaseStorage.filter(f => !f.processed));

        return returnURL;
    }

    sortQueue(a: IQueueObject, b: IQueueObject): number {
        if (b.timeStamp > a.timeStamp) return -1;
        if (b.timeStamp < a.timeStamp) return 1;
        return 0;
    }

    getQueueLength(): number {
        const queueStorage = localStorage.getItem(this.queueStorageKey);

        if (queueStorage) {
            var parsedStorage = JSON.parse(queueStorage);
            return parsedStorage?.length ?? 0;
        } else {
            return 0;
        }
    }

    addValueToQueue(value: IQueueObject): void {
        const queueStorage = localStorage.getItem(this.queueStorageKey);

        if (queueStorage) {
            var parsedStorage = JSON.parse(queueStorage);
            parsedStorage.push(value);
            localStorage.setItem(this.queueStorageKey, JSON.stringify(parsedStorage));
        } else {
            var storage = [value];
            localStorage.setItem(this.queueStorageKey, JSON.stringify(storage));
        }
    }

    async monitorConnection(): Promise<void> {
        await Network.removeAllListeners();
        // Watches for network changes such as lossing internet
        this.connectionListener = Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
            if (!status.connected) {
                this.setOffline = true;
            } else {
                this.setOffline = false;
            }
        });
    }

    async getCacheObjectByURL(url: string): Promise<ICacheObject> {
        const keys: string[] = await this.storageService.keys();
        if (!keys.includes(url)) {
            window.dispatchEvent( new CustomEvent('NoCacheFound', {detail: {color: 'warning', message: 'Data failed to load in offline mode. Please retry once you are back online.', header: 'No Cache Found', duration: 'long'}}) );

            return null;
        } else {
            const storage = await this.storageService.get(url);

            return storage;
        }
    }

    async testConnection(): Promise<boolean> {
        const reponse = await firstValueFrom(this.httpClient.get<boolean>(`${this.API_BASE_URL}/connectivity/test-connection`)
        .pipe(
            timeout(this.timeoutValue),
            catchError((error)=>{
                console.log(error);
                return of(false);
            })
        ));

        return reponse;
    }

    getUniqueId(): string {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substring(2);
        return dateString + randomness;
    }

    async ngOnDestroy(): Promise<void> {
        await Network.removeAllListeners();
    }
}
       