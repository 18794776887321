import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { LiaisonStateService } from "../../../../features/liaison/services/liaison-state-service";
import { LiaisonApiService } from "../../../../../app/features/liaison/services/liaison-api-service";
import { Platform } from "@ionic/angular";

export enum TABS {
  ISSUES = 1,
  ACTIVITY = 2,
  SORTS = 3
};

@Component({
  selector: "app-report-sort-card",
  templateUrl: "./report-sort-card.component.html",
  styleUrls: ["./report-sort-card.component.scss"],
})

export class ReportSortCardComponent implements OnInit {

  tabs = TABS;

  @Input() activeHeader: TABS;
  @Input() showTabContainer: boolean = true;
  @Input() accountId: number = null;

  @Input() issueCount: number = 0;
  @Input() concernCount: number = 0;

  @Output() tabChange: EventEmitter<TABS> = new EventEmitter<TABS>();

  additionalSafeAreaHeight: number = 0;

  constructor(
    public liaisonStateService: LiaisonStateService,
    private liaisonApiService: LiaisonApiService,
    private platform: Platform
  ) {

  }

  async ngOnInit() {
    this.additionalSafeAreaHeight = 5;
    // Not used but could come in useful if safe area becomes an issue
    if ((this.platform.is('ios') || this.platform.is('iphone') || this.platform.is('ipad')) && this.platform.is('capacitor')) {
      //this.additionalSafeAreaHeight = 20;
    }
  }


  onTabChange(tab: TABS): void {
    this.tabChange.emit(tab);
  }
}
