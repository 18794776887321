<div class="footer-container">
  <div class="button-container">
    <div (click)="navigateToAccounts()" class="image-container">
      <img src="../../../../assets/icons/accounts.svg" />
    </div>
    <span>Accounts</span>
  </div>
  <div class="button-container">
    <div (click)="navigateToReporting()" class="send-container">
      <img src="../../../../assets/icons/add-white.svg" />
    </div>
    <span>{{reportToday ? 'Reporting' : 'New Report'}}</span>
  </div>
  <div class="button-container">
    <div (click)="navigateToTime()" class="image-container">
      <img src="../../../../assets/icons/clock-white.svg" />
    </div>
    <span>Time</span>
  </div>
</div>
