<div class="preview-container">
    <ion-row class="header">
        <ion-col></ion-col>
        <ion-col size="auto">
            <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
        </ion-col>
    </ion-row>
    <div class="image-container">
        <ion-img *ngIf="attachment" [src]="attachment.images.image_url"></ion-img>
    </div>
    <ion-row class="download">
        <ion-col>
            <ion-button (click)="downloadAttachment()">
                <ion-icon name="download-outline"></ion-icon> Download
            </ion-button>
        </ion-col>
    </ion-row>
</div>