import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { CalendarPopoverComponent } from '../calendar-popover/calendar-popover.component';

@Component({
  selector: 'app-calendar-input',
  templateUrl: './calendar-input.component.html',
  styleUrls: [
    './calendar-input.component.scss'
  ]
})
export class CalendarInputComponent {

  @Input() date: Date = null;
  @Input() dateFilter: any = null;
  @Input() highlightedDates: any;
  @Input() placeholder: string = null;
  @Input() showEndDate: boolean = true;
  @Input() height: string = null;
  @Input() whiteBackground: boolean = false;
  @Output() dateChange = new EventEmitter();

  constructor(
    private popoverController: PopoverController
  ) {

  }

  async openCalendar(): Promise<void> {
    const popover = await this.popoverController.create({
      component: CalendarPopoverComponent,
      componentProps: {
          date: this.date,
          dateFilter: this.dateFilter,
          highlightedDates: this.highlightedDates
      },
      event: event,
      cssClass: 'calendar'
    });
  
    await popover.present();

    const {data} = await popover.onDidDismiss();

    if (data) {
      this.date = data;
      this.dateChange.emit(data);
    }
  }

  getWeekEndDate(): Date {
    if (!this.date) {
      return null;
    }

    const dateClone = new Date(JSON.parse(JSON.stringify(this.date)));

    return new Date(dateClone.setDate(dateClone.getDate() + 6));
  }

}
