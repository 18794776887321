import { Component} from '@angular/core';
import { Platform } from '@ionic/angular';
import { SharedService } from '../../services/shared.service';
import { IAttachment, IIssueAttachment, IRejectAttachment, IReportAttachment } from '../../models/attachment.model';
import { ModalController } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource, Photo}  from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Media, MediaSaveOptions, MediaPlugin, MediaField, MediaAlbumResponse } from '@capacitor-community/media';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-preview-attachment',
    templateUrl: 'preview-attachment.component.html',
    styleUrls: ['./preview-attachment.component.scss']
    
})

export class PreviewAttachmentComponent {

    attachment: IAttachment = null;

    albumName = 'QAS Photos';

    constructor(
      private platform: Platform,
      private sharedService: SharedService,
      private modalController: ModalController,
      private storage: AngularFireStorage
    ) { }

    closeModal(): void {
      this.modalController.dismiss();
    }

    async downloadAttachment(): Promise<void> {
      this.sharedService.hapticsImpactLight();
      let savedMedia = null;
  
      try {
        const fileName = new Date().getTime + 'jpeg';

        const downloadURL = await firstValueFrom(this.storage.refFromURL(this.attachment.images.image_url).getDownloadURL());
  
        let albums: MediaAlbumResponse = await Media.getAlbums();
    
        let album = albums.albums.find(a => a.name === this.albumName);
    
        if (!album) {
          Media.createAlbum({name: this.albumName});
    
          albums = await Media.getAlbums();
          album = albums.albums.find(a => a.name === this.albumName);
        }
    
        const mediaOptions: MediaSaveOptions = {
          path: downloadURL,
          album: album.identifier
        };
    
        if (album && album.identifier) {
          savedMedia = await Media.savePhoto(mediaOptions);
        }

        this.sharedService.presentToast('primary', '', 'Media saved successfully!', 'short');
      } catch (error) {
        console.log(error);
        this.sharedService.presentToast('danger', '', 'Media failed to save!', 'short');
      }
    }

}