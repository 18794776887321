import { Component, Input } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { SharedService } from "../../../../../app/shared/services/shared.service";

@Component({
  selector: "back-button",
  templateUrl: "back-button.component.html",
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent {
  @Input() navigateTo: string[] = null;

  @Input() scale: number = 1;

  constructor(private location: Location, private router: Router, private sharedService: SharedService) {}

  navigateBack() {
    this.sharedService.hapticsImpactLight();
    if (this.navigateTo) {
      this.router.navigate(this.navigateTo);
      return;
    }
    this.location.back();
  }
}
