<div [ngClass]="showTabContainer ? 'report-card' : 'report-card-no-tabs'" id="hide-on-camera">
  <div *ngIf="showTabContainer" class="tab-container">
    <div class="issues-tab" (click)="onTabChange(tabs.ISSUES)">
      <div
        class="issues-info" [ngClass]="{'no-issues': issueCount <= 0 && concernCount <= 0, 'only-concerns': concernCount > 0 && issueCount <= 0, 'has-issues': issueCount > 0}">
        <div>
          <span>{{ issueCount + concernCount }}</span>
        </div>
      </div>
      <h6 [ngClass]="activeHeader === tabs.ISSUES ? 'active-header' : 'inactive-header'">
        Issues
      </h6>
    </div>
    <div class="daily-reports-tab" (click)="onTabChange(tabs.ACTIVITY)">
      <h6 [ngClass]="activeHeader === tabs.ACTIVITY ? 'active-header' : 'inactive-header'">
        Activity
      </h6>
    </div>
    <div class="sorts-tab" (click)="onTabChange(tabs.SORTS)">
      <h6 [ngClass]="activeHeader === tabs.SORTS ? 'active-header' : 'inactive-header'" class="sorts-header">
        Sorts
      </h6>
    </div>
  </div>
  <div [ngStyle]="{ height: showTabContainer ? 'calc(100% - 24px);' : '100%' }" class="report-body" id="hide-on-camera">
    <ng-content></ng-content>
  </div>
</div>
