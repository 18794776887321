import { Component, OnInit } from "@angular/core";
import { AdminApiService } from "../../../../../../../app/features/admin/services/admin-api-service";
import { ModalController } from "@ionic/angular";
import { User } from "../../../../../../../app/shared/models/user.model";
import { IAccountData } from "../../../../../../../app/shared/models/account.model";
import { SharedService } from "../../../../../../../app/shared/services/shared.service";
import { ILiaisonAccountCover } from "../../../../../../../app/shared/models/liaison-account-cover.model";
import { LiaisonStateService } from "../../../../../../../app/features/liaison/services/liaison-state-service";

@Component({
    selector: 'add-liaison-cover-component',
    templateUrl: 'add-liaison-cover.component.html',
    styleUrls:['./add-liaison-cover.component.scss'],
    providers:[ AdminApiService, LiaisonStateService ]
})
export class AddLiaisonCoverComponent implements OnInit {

    liaisonCover: ILiaisonAccountCover = null;

    liaisonAccountId: number = null;
    accountId: number = null;
    account: IAccountData = null;

    userId: number = null;

    liaisons: User[] = [];

    coverHistory: ILiaisonAccountCover[] = [];

    selectedUser: User = null;

    startDate: Date = null;
    endDate: Date = null;

    allowDirectReporting: boolean = false;

    payRate: number = null;

    loading: boolean = true;

    constructor(
        private modalController: ModalController,
        private adminApiService: AdminApiService,
        public sharedService: SharedService,
        private liaisonStateService: LiaisonStateService
    ) {

    }

    async ngOnInit(): Promise<void> {
        const promises: any[] = [];

        promises.push(this.getLiaisons());
        promises.push(this.getCurrentCovers());
        promises.push(this.getAccount());

        await Promise.all(promises);

        if (this.liaisonCover) {
            this.startDate = this.sharedService.getDefaultDate(this.liaisonCover.start_date as any) as any;
            this.endDate = this.sharedService.getDefaultDate(this.liaisonCover.end_date as any) as any;
            this.selectedUser = this.liaisons.find(l => l.id === this.liaisonCover.user_id);
            this.payRate = this.liaisonCover.pay_rate;
            this.allowDirectReporting = this.liaisonCover.allow_direct_reporting;
        } else {
            this.startDate = this.sharedService.getDefaultDate(new Date().toISOString()) as any;
        }

        this.loading = false;
    }

    async getAccount(): Promise<void> {
        this.account = await this.adminApiService.getAccountsDataById(this.accountId);
    }

    async getCurrentCovers(): Promise<void> {
        this.coverHistory = await this.adminApiService.getLiaisonAccountCoverByLiaisonAccountId(this.liaisonAccountId);
    }

    async getLiaisons(): Promise<void> {
        const users = await this.adminApiService.getUsers('liaison');
        this.liaisons = this.sharedService.sortFirstNameLastName(users.filter(u => u.active && (u.id !== this.userId)));
    }

    checkValidCover(): boolean {
        var isValid = true;

        // Check if the user is already a user for this account
        const existingLiaisonAccount = this.account.liaison_accounts.find(l => l.user_id === this.selectedUser.id);
        console.log(existingLiaisonAccount);

        if (existingLiaisonAccount && existingLiaisonAccount.liaison_account_history?.length) {
            isValid = false;
            this.sharedService.presentToast('warning', 'Selected liaison is already associated with this account. Use the liaison history screen to exchange hours between these liaison.', 'Cross coverage is not valid.', 'extralong');
        }

        // Check if there is an existing cover in the time range 
        const existingCover = this.coverHistory.find(c => {
            return ((c.user_id === this.selectedUser.id) &&
            ((this.sharedService.stringToStartDate(this.startDate as any) <= this.sharedService.stringToEndDate(c.end_date as any)) || !c.end_date) &&
            ((this.sharedService.stringToEndDate(this.endDate as any) >= this.sharedService.stringToStartDate(c.start_date as any)) || !this.endDate))
        });

        if (existingCover) {
            isValid = false;
            this.sharedService.presentToast('warning', 'An existing entry for this liaison already exist in the range defined.', 'Cross coverage is not valid.', 'extralong');
        }

        return isValid;
    }

    async save(): Promise<void> {
        if (!this.startDate || !this.selectedUser) {
            this.sharedService.presentToast('danger', 'Form is incomplete.', 'Invalid data!', 'med');
            return;
        }

        const data: ILiaisonAccountCover = {
            id: this.liaisonCover?.id ?? null,
            user_id: this.selectedUser.id,
            liaison_account_id: this.liaisonAccountId,
            start_date: this.startDate,
            end_date: this.endDate,
            pay_rate: this.payRate ?? 0,
            allow_direct_reporting: this.allowDirectReporting
        }

        if (this.liaisonCover) {
            await this.adminApiService.updateLiaisonAccountCover(data);
            this.close(true);
        } else {
            const validCover: boolean = this.checkValidCover();

            if (validCover) {
                await this.adminApiService.createLiaisonAccountCover(data);
                this.close(true);
            }
        }
    }

    async remove(): Promise<void> {
        await this.adminApiService.deleteLiaisonAccountCover(this.liaisonCover.id);

        this.close(true);
    }

    close(update: boolean = false): void {
        this.modalController.dismiss(update);
    }

}