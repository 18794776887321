import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, OnChanges, AfterContentInit, OnDestroy, SimpleChanges } from "@angular/core";
import { ColumnSortHeaderComponent } from "../column-sort-header/column-sort-header.component";
import { Subscription } from "rxjs";

@Component({
  selector: "sort-container",
  templateUrl: "column-sort-container.component.html",
  styleUrls: ["./column-sort-container.component.scss"],
})
export class ColumnSortContainerComponent implements AfterContentInit, OnChanges, OnDestroy {

    @ContentChildren(ColumnSortHeaderComponent, {descendants: true}) sortHeaderComponents: QueryList<ColumnSortHeaderComponent>;

    @Input() data: any[] = [];
    @Input() resetDirection: string = 'asc';
    @Input() resetAttribute: string = 'name';
    @Input() resetOnDeselect: boolean = false;
    private originalDataOrder: any[] = [];

    @Output() updatedData: EventEmitter<any[]> = new EventEmitter<any[]>();

    subscriptions: Subscription[] = [];

    constructor() {

    }

    ngAfterContentInit(): void {
        if (this.sortHeaderComponents) {
            this.sortHeaderComponents.forEach(s => {
                const subscription = s.getData().subscribe(event => {
                    var hasActiveSort: boolean = false;

                    if (this.sortHeaderComponents) {
                        this.sortHeaderComponents.forEach(x => {
                            if (event.active && (event.id !== x.identifier)) {
                                x.setActive(false);
                            }

                            if (event.active) {
                                hasActiveSort = true;
                            }
                        });

                        if (this.data && hasActiveSort) {
                            const orderedData = this.orderData(event.id, event.direction);
                            this.updatedData.emit(orderedData);
                        }

                        if (!hasActiveSort && this.resetOnDeselect) {
                            this.orderData(this.resetAttribute, this.resetDirection);
                        }
                    }
                });

                this.subscriptions.push(subscription);
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.data && (this.data.length > 0) && (this.originalDataOrder.length <= 0)) {
            this.originalDataOrder = JSON.parse(JSON.stringify(this.data));
        }
    }

    orderData(attribute: string, direction: string): any[] {
        const greater: number = (direction === 'asc') ? 1 : -1;
        const less: number = (direction === 'asc') ? -1 : 1;
    
        const sortedData = this.data.sort((a, b) => {
            var aAttribute = this.getValue(a, attribute);
            var bAttribute = this.getValue(b, attribute);

            if ((aAttribute === null) || (aAttribute === undefined)) {
                return greater;
            }

            if ((bAttribute === null) || (bAttribute === undefined)) {
                return less;
            }

            // Handle string comparison
            if (typeof aAttribute === 'string' && typeof bAttribute === 'string') {
                return less * aAttribute.localeCompare(bAttribute, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            }

            if (aAttribute < bAttribute ) return less;
            if (aAttribute > bAttribute ) return greater;
            return 0;
        });

        return sortedData;
    }

    getValue(obj: any, path: string) {
        if (!path) return obj;

        if (!obj) return null;

        const properties = path.split('.');
        return this.getValue(obj[properties.shift()], properties.join('.'))
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
    }

}
