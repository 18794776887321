<ion-grid>
    <ion-row class="top-row">
        <ion-col>
            Manual Barcode Entry
        </ion-col>
        <ion-col size="auto">
            <ion-icon (click)="close(true)" name="close-outline"></ion-icon>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <ion-input [(ngModel)]="barcode"  placeholder="Enter barcode"></ion-input>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <ion-button [disabled]="!barcode || (barcode?.length <= 0)" (click)="close(false)">Save</ion-button>
        </ion-col>
    </ion-row>
</ion-grid>