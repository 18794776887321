import { Pipe, PipeTransform } from "@angular/core";
import { IAccountData } from "../../../../../../../../app/shared/models/account.model";

@Pipe({
    name: 'filterOutExistingLiaisonAccounts'
})

export class FilterOutExistingLiaisonAccountsPipe implements PipeTransform {


    transform(items: IAccountData[], userId: number, trigger?: number) {

        if (!items) {
            return [];
        }

        if (!userId) {
            return items.filter(i => i.active);
        }

        return items.filter(i => i.active).filter(i => !i.liaison_accounts.map(l => l.user_id).includes(userId));

    }
}