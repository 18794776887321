import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ViewWillEnter } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ILocation } from '../../../../../../../app/shared/models/location.model';
import { AdminApiService } from '../../../../../../../app/features/admin/services/admin-api-service';
import { SharedService } from '../../../../../../../app/shared/services/shared.service';
import { StateList } from '../../../location-details/models/states-list';

@Component({
    selector: 'new-location-popup-component',
    templateUrl: 'new-location.component.html',
    styleUrls: ['./new-location.component.scss'],
    providers: [AdminApiService]
})

export class NewLocationPopupComponent implements ViewWillEnter, OnInit {

    stateList: string[] = StateList;

    locationName: string = null;
    locationAddress: string = null;
    locationCity: string = null;
    locationState: string = null;
    locationPostal: string = null;
    notes: string = null;
    active: boolean = true;
    country: 'US' | 'MX' | 'CA' | 'KO' = null;
    address2: string = null;
    
    newLocationForm: FormGroup = new FormGroup({
        name: new FormControl(this.locationName),
        address: new FormControl(this.locationAddress),
        city: new FormControl(this.locationCity),
        state: new FormControl(this.locationState),
        postal: new FormControl(this.locationPostal),
        notes: new FormControl(this.notes),
        active: new FormControl(this.active),
        country: new FormControl(this.country),
        address2: new FormControl(this.address2)
    });

    constructor(
        private modalCtrl: ModalController,
        private adminApiService: AdminApiService,
        private sharedService: SharedService
    ) {

    }

    ngOnInit(): void {
        this.newLocationForm.patchValue({country: 'US'});
    }

    ionViewWillEnter(): void {
        
    }

    async submitNewLocation(): Promise<void> {
        const newLocation: ILocation = {
            name: this.newLocationForm.value.name,
            address: this.newLocationForm.value.address,
            city: this.newLocationForm.value.city,
            state: this.newLocationForm.value.state,
            postal: this.newLocationForm.value.postal,
            notes: this.newLocationForm.value.notes,
            active: this.newLocationForm.value.active,
            country: this.newLocationForm.value.country,
            address2: this.newLocationForm.value.address2
        };

        var location: ILocation = null;

        try {
            location = await this.adminApiService.addLocations(newLocation);
        } catch (error) {
            if (error.error === 'Duplicate Location') {
                this.sharedService.presentToast('danger', 'This location already exists.', 'Failed!', 'long');
            } else {
                this.sharedService.presentToast('danger', 'An unknown error occurred while processing your request.', 'Failed!', 'long');
            }
        }

        if (location) {
            this.closeModal(location);
        }
    }

    closeModal(location: ILocation = null){
        this.modalCtrl.dismiss(location);
    }
}