import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { IonContent, ViewWillEnter } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { LiaisonIssue } from "../../models/liaison-issue.model";
import { LiaisonIssueUpdate } from "../../models/liaison-issue-updates.model";
import { LiaisonApiService } from "../../../features/liaison/services/liaison-api-service";
import { LiaisonStateService } from "../../../features/liaison/services/liaison-state-service";

import { SharedService } from "../../services/shared.service";


@Component({
  selector: "create-issue-update-component",
  templateUrl: "create-issue-update.component.html",
  styleUrls: ["./create-issue-update.component.scss"],
  providers: [LiaisonApiService],
})
export class CreateIssueUpdateComponent implements OnInit {

    @ViewChild('barcodeAccordion') barcodeAccordion: any;
    @ViewChild('attachmentUpload', { static: true }) attachmentComponent: any;

    activeIssue: LiaisonIssue = null;

    numberOfPartsNotApplicable: boolean = false;
    areaFoundNotApplicable: boolean = false;

    newUpdate: LiaisonIssueUpdate = null;

    constructor(
        private modalCtrl: ModalController,
        public liaisonStateService: LiaisonStateService,
    ) {

    }

    ngOnInit(): void {
        var currentDate = new Date();
        
        this.newUpdate = new LiaisonIssueUpdate();
        this.newUpdate.is_local = false;
        this.newUpdate.created_by = this.liaisonStateService.getUserId();
        this.newUpdate.daily_report_id =this.liaisonStateService.getActiveDailyReport().id;
        this.newUpdate.serial_numbers = [];
        this.newUpdate.time_created = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1) < 10 ? '0' : ''}${(currentDate.getMonth() + 1)}-${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}`;
    }

    manualAttachmentComponentPopup(): void {
        if (this.attachmentComponent) {
          this.attachmentComponent.presentActionSheet();
        }
    }

    onBarcodeScan(data: string): void {
        if (data) {
            this.newUpdate.serial_numbers.push(data);
          
            if (this.barcodeAccordion) {
                this.barcodeAccordion.open();
            }
        }
    }
    
    test() {
        this.newUpdate.serial_numbers.push('123456789');
    }

    async closeModal(includeUpdate: boolean = true): Promise<void> {
        // Remove all attachments if user cancels update after upload
        if (!includeUpdate && this.attachmentComponent) {
            await this.attachmentComponent.deleteAllAttachments();
        }

        if (this.areaFoundNotApplicable) {
            this.newUpdate.area_found = '';
        }

        if (this.numberOfPartsNotApplicable) {
            this.newUpdate.parts_found = 0;
        }

        this.modalCtrl.dismiss((includeUpdate ? this.newUpdate : null));
    }

}