import { Component, Input, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { LiaisonStateService } from "../../../../app/features/liaison/services/liaison-state-service";
import { LiaisonApiService } from "../../../../app/features/liaison/services/liaison-api-service";
import { LiaisonReport } from "../../models/liaison-report.model";

@Component({
  selector: "app-account-overview-footer",
  templateUrl: "account-overview-footer.component.html",
  styleUrls: ["./account-overview-footer.component.scss"],
  providers: [LiaisonApiService],
})
export class AccountOverviewFooterComponent implements OnInit {
  userId: number;
  accountId: number;

  @Input() reportToday: LiaisonReport = null;

  constructor(
    private router: Router,
    private liaisonApiService: LiaisonApiService,
    private liaisonStateService: LiaisonStateService
  ) {}

  async ngOnInit() {
    this.userId = this.liaisonStateService.getUserId();
    this.accountId = this.liaisonStateService.getActiveAccountId();
  }

  navigateToAccounts() {
    this.router.navigate([(this.liaisonStateService.getRole() === 'liaison') ? 'liaison/home' : 'admin/home']);
  }

  async navigateToReporting() {
    if (!this.reportToday) {
      const report: LiaisonReport = new LiaisonReport(
        null,
        null,
        null,
        null,
        null,
        null, 
        false
      );
      const results = await this.liaisonApiService.postDailyReport(
        this.userId,
        this.accountId,
        report
      );
      this.liaisonStateService.setActiveDailyReport(results);
      this.router.navigate(["/liaison/issues"]);
    } else {
      this.liaisonStateService.setActiveDailyReport(this.reportToday);
      this.router.navigate(["/liaison/issues"]);
    }
  }

  navigateToTime() {
    this.router.navigate(["/liaison/time"]);
  }
}
