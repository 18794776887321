<ion-grid>
    <ion-row class="header-row">
        <ion-col>
            <span>Overtime Rules</span>
        </ion-col>
        <ion-icon class="cross" (click)="close()" name="close-outline"></ion-icon>
    </ion-row>
    <ion-row class="hours-row">
        <ion-col size="auto">
            Overtime if hours exceed 
        </ion-col>
        <ion-col class="overtime-hours" size="auto">
            <ion-input [(ngModel)]="overtimeRules.overtimeAfter" placeholder=""></ion-input>
        </ion-col>
        <ion-col size="auto">
            <select [(ngModel)]="overtimeRules.overtimeOption">
                <option [value]="OVERTIMEOPTIONS.WEEKLY">
                    Weekly
                </option>
                <option [value]="OVERTIMEOPTIONS.DAILY">
                    Daily
                </option>
            </select>
        </ion-col>
    </ion-row>
    <!-- <ion-row>
        <ion-col class="checkbox-col">
            <ion-checkbox labelPlacement="end" [(ngModel)]="overtimeRules.noOvertime"></ion-checkbox>
            <span>No Overtime</span>
        </ion-col>
    </ion-row> -->
    <ion-row>
        <ion-col class="checkbox-col">
            <ion-checkbox labelPlacement="end" [(ngModel)]="overtimeRules.overtimeOnWeekends"></ion-checkbox>
            <span>Overtime on Weekends</span>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col class="checkbox-col">
            <ion-checkbox labelPlacement="end" [(ngModel)]="overtimeRules.overtimeOnHolidays"></ion-checkbox>
            <span>Overtime on Holidays</span>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col class="checkbox-col">
            <ion-checkbox labelPlacement="end" [(ngModel)]="overtimeRules.doubleTimeSundays"></ion-checkbox>
            <span>Double Time on Sundays</span>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col class="checkbox-col">
            <ion-checkbox labelPlacement="end" [(ngModel)]="overtimeRules.doubleTimeOnHolidays"></ion-checkbox>
            <span>Double Time on Holidays</span>
        </ion-col>
    </ion-row>

    <ion-row class="save-row">
        <ion-col>
            <ion-button [disabled]="!hasChange()" (click)="save()">Save</ion-button>
        </ion-col>
    </ion-row>
</ion-grid>