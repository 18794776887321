
<div id="show-on-camera" class="top-level-div">
    <div class="footer-container">
        <ion-row class="close-barcode">
            <ion-col>
                <ion-button (click)="manualEntry()">Manual Entry</ion-button>
            </ion-col>
            <ion-col>
                <ion-button class="close-button" (click)="closeCamera()">Close Scanner</ion-button>
            </ion-col>
        </ion-row>
    </div>
</div>
