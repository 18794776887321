import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUpdate } from '../../models/update.model';
import { IIssue } from '../../../features/liaison/models/daily-report.model';
import * as _ from 'lodash';

@Component({
    selector: 'create-defect-component',
    templateUrl: 'create-defect.component.html',
    styleUrls:['./create-defect.component.scss'],
})

export class CreateDefectComponent implements OnInit {

    label: string = '';

    constructor(
        private modalCtrl: ModalController
    ) {

    }

    ngOnInit(): void {
        return
    }

    closeModal(): void {
        this.modalCtrl.dismiss();
    }

    createDefect(): void {
        this.modalCtrl.dismiss(this.label);
    }

}