import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NavController } from '@ionic/angular';

@Injectable()
export class ResponseHttpInterceptor implements HttpInterceptor {
  isToastPresent: boolean;

  constructor(private router: Router, private toastr: ToastController, private navController: NavController) {
    this.isToastPresent = false;
  }

  private async presentToast(color: string, message: string, header: string, duration = 5000) {
    const toast = await this.toastr.create({
      color,
      message,
      header,
      duration,
      position: 'top'
    });

    toast.present();
    toast.onDidDismiss().then(() => (this.isToastPresent = false));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError(
        (error: HttpErrorResponse) => {

          switch (error.status) {
            case 401:
              if (error.url.indexOf('login') === -1) {
                //remove the JWT from local storage so it doesn't try to auto login
                localStorage.removeItem('authToken');
                this.navController.navigateRoot(["/login"]);
                if (!this.isToastPresent) {
                  this.isToastPresent = true;
                  this.presentToast('primary', 'Please login again.', 'Login expired.');
                }
              }
              break;
            case 404:
              if (!this.isToastPresent) {
                this.isToastPresent = true;
                this.presentToast('warning', 'The target is offline or unavailable. Please try again later.', null);
              }
              break;
            case 500:
              if (!this.isToastPresent) {
                this.isToastPresent = true;
                this.presentToast('danger', 'Please try again.', 'Something went wrong.');
              }
              break;
          }

          return throwError(error);
        }
      )
    );
  }
}
