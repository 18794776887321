import { Injectable } from '@angular/core';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CrashlyticService {

    constructor(
        private router: Router
    ) {
      
    }

    async init(): Promise<void> {
        const { enabled } = await FirebaseCrashlytics.isEnabled();

        if (!enabled) {
            await FirebaseCrashlytics.setEnabled({
            enabled: true
            });
        }
    }

    async testCrash(): Promise<void> {
        await FirebaseCrashlytics.crash({
            message: 'TEST CRASH'
        });
    }

    async setCustomKey(url: string): Promise<void> {
        await FirebaseCrashlytics.setCustomKey({
          key: 'route',
          value: url,
          type: 'string',
        });
    };

    async logError(message: string): Promise<void> {
        await this.setCustomKey(this.router.url);

        await FirebaseCrashlytics.log({
            message
        });
    }
  
}