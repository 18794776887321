import { Component, OnInit } from "@angular/core";
import { AdminApiService } from "../../services/admin-api-service";
import { ViewWillEnter } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { ILiaisonAccount, LiaisonAccount } from "../../../../shared/models/liaison-account.model";
import { User } from "../../../../shared/models/user.model";
import { ILiaisonAccountHistory } from "../../../../shared/models/liaison-account-history.model";
import { SharedService } from "../../../../../app/shared/services/shared.service";

@Component({
    selector: 'account-history-component',
    templateUrl: 'account-history.component.html',
    styleUrls:['./account-history.component.scss'],
    providers:[AdminApiService]
})
export class LiaisonAccountHistoryComponent implements OnInit {

    liaisonAccount: ILiaisonAccount = null;
    user: User = null;
    history: ILiaisonAccountHistory[] = [];
    originalHistory: ILiaisonAccountHistory[] = [];

    trigger: number = 0;
    loading: boolean = true;

    constructor(
        private modalController: ModalController,
        private adminApiService: AdminApiService,
        private sharedService: SharedService
    ) {

    }

    async ngOnInit(): Promise<void> {
        await this.getBaseHours();

        this.loading = false;
    }


    async getBaseHours(): Promise<void> {
        this.history = await this.adminApiService.getAllHistoryByLiaisonAccount(this.liaisonAccount.id);

        this.history.forEach((b: ILiaisonAccountHistory) => {
            b.start_date = this.sharedService.getDefaultDate(b.start_date as any) as any;
            b.end_date = b.end_date ? this.sharedService.getDefaultDate(b.end_date as any) as any : null;
        });
        
        this.originalHistory = JSON.parse(JSON.stringify(this.history));
    }

    async addNew(): Promise<void> {

        var startDate: Date = new Date(new Date().setHours(0, 0, 0, 0));;
        var prev: ILiaisonAccountHistory = null;

        if (this.history.length > 0) {
            prev = this.history.sort((a, b) => {
                if (a.start_date > b.start_date) {
                    return -1;
                }
                if (a.start_date < b.start_date) {
                    return 1;
                }
                
                return 0;
            })[0];

            const prevStart = new Date(prev?.start_date);

            startDate = new Date(prevStart.setDate(prevStart.getDate() + 1));
        }

        const newHistory: ILiaisonAccountHistory = {
            id: null,
            liaison_account: this.liaisonAccount.id,
            liaison: prev?.liaison ?? this.user.id,
            rate: prev?.rate ?? 0,
            hours: prev?.hours ?? 0,
            start_date: this.sharedService.getDefaultDate(prev ? startDate.toString() : startDate.toISOString().split('T')[0]) as any,
            end_date: null
        };

        this.history.push(newHistory);

        this.setEndDate();
    }

    async save(): Promise<void> {
        const promises: any[] = [];

        this.history.forEach((b: ILiaisonAccountHistory) => {
            if (b.id === null) {
                promises.push(this.adminApiService.postHistoryByLiaisonAccount(b));
            }
        });

        this.originalHistory.forEach((b: ILiaisonAccountHistory) => {
            const data: ILiaisonAccountHistory = this.history.find(x => x.id === b.id);

            if (!data) {
                promises.push(this.adminApiService.deleteHistoryByLiaisonAccount(b.id));
            } else {
                if (!this.compareBaseHours(data, b)) {
                    promises.push(this.adminApiService.updateHistoryByLiaisonAccount(data));
                }
            }
        });

        await Promise.all(promises);

        this.close(true);
    }

    refreshPipe(): void {
        this.trigger++;

        this.setEndDate();
    }

    setEndDate(): void {
        var previous: ILiaisonAccountHistory = null;

        this.history.sort((a, b) => {
            if (a.start_date > b.start_date) {
                return -1;
            }
            if (a.start_date < b.start_date) {
                return 1;
            }
            
            return 0;
        }).forEach((b: ILiaisonAccountHistory) => {
            if (!previous) {
                b.end_date = null;
            } else {
                b.end_date = previous.start_date;
            }

            previous = b;
        });
    }

    compareBaseHours(a: ILiaisonAccountHistory, b: ILiaisonAccountHistory): boolean {
        return (a.end_date === b.end_date) && (a.hours === b.hours) && (a.start_date === b.start_date) && (a.rate == b.rate) && (a.liaison === b.liaison);
    }

    async remove(baseHour: ILiaisonAccountHistory): Promise<void> {
        const index = this.history.indexOf(baseHour);
        this.history.splice(index, 1);

        this.setEndDate();
    }

    dateUnfocused(): void {
        this.setEndDate();
        this.trigger++;
    }

    close(update: boolean = false): void {
        this.modalController.dismiss(update);
    }

}