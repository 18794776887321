<div id="content-report-export" class="data-section">
    <ion-row class="section-header">
        <ion-col class="ion-no-padding">
            Liaison
        </ion-col>
    </ion-row>

    <ion-row class="liaison-data">
        <ion-col class="ion-no-padding">
            <ion-row>
                <ion-col class="ion-no-padding">
                    {{report?.liaison?.first_name ?? 'No Name'}} {{report?.liaison?.last_name ?? ''}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="report?.liaison?.email">
                <ion-col class="ion-no-padding">
                    <a [href]="'mailto: ' + report.liaison.email">{{report.liaison.email ?? 'No Email'}}</a>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="report?.liaison?.phone_number">
                <ion-col class="ion-no-padding">
                    <a [href]="'tel: ' + report.liaison.phone_number">{{report.liaison.country_code ? ('+' + report.liaison.country_code + '') : ''}}{{report.liaison.phone_number ?? 'No Phone'}}</a>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>

    <ion-row class="section-header" *ngIf="issueData?.new.length > 0">
        <ion-col class="ion-no-padding">
            New Issues
        </ion-col>
    </ion-row>

    <issue-list-component *ngIf="issueData?.new && issueData?.new.length > 0" [issues]="issueData?.new"></issue-list-component>

    <ion-row class="section-header" *ngIf="issueData?.update.length > 0">
        <ion-col class="ion-no-padding">
            Updated Issues
        </ion-col>
    </ion-row>

    <issue-list-component *ngIf="issueData?.update && issueData?.update.length > 0" [issues]="issueData?.update"></issue-list-component>

    <ion-row class="section-header" *ngIf="issueData?.closed.length > 0">
        <ion-col class="ion-no-padding">
            Closed Issues
        </ion-col>
    </ion-row>

    <issue-list-component *ngIf="issueData?.closed && issueData?.closed.length > 0" [issues]="issueData?.closed"></issue-list-component>

    <ion-row class="section-header" *ngIf="reportData?.report.sorts.length > 0">
        <ion-col class="ion-no-padding">
            Sorts
        </ion-col>
    </ion-row>

    <ng-container *ngIf="reportData?.report.sorts && (reportData?.report.sorts.length > 0)">
        <sort-list-component [sorts]="reportData?.report.sorts"></sort-list-component>
    </ng-container>

    <ion-row class="section-header" *ngIf="report?.dailyActivity.length > 0">
        <ion-col class="ion-no-padding">
            Daily Activity Summary
        </ion-col>
    </ion-row>

    <ng-container>
        <ng-container *ngFor="let activity of reportData?.report.dailyActivity">
            <ion-row class="activity-data" *ngIf="activity?.activity?.length > 0">
                <ion-col class="ion-no-padding">
                    <ng-container *ngIf="report.id.length > 1">
                        <span>{{activity.activity}}</span>
                    </ng-container>
                    
                    <ng-container *ngIf="report.id.length === 1">
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <span class="activity-text">Daily Activity:</span> <br><span>{{activity.activity}}</span>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <span class="activity-text">Production Count:</span> {{activity.productionCount}}
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <span class="activity-text">Scrap Count:</span> {{activity.scrapCount}}
                            </ion-col>
                        </ion-row>
                    </ng-container>
                </ion-col>
            </ion-row>
        </ng-container>
    </ng-container>

</div>