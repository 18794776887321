import { Component, OnInit } from "@angular/core";
import { AdminApiService } from "../../services/admin-api-service";
import { ViewWillEnter } from "@ionic/angular";
import { OVERTIMEOPTIONS } from "../../../../../app/shared/models/liaison-account.model";
import { ModalController } from "@ionic/angular";
import { IAccountData } from "../../../../../app/shared/models/account.model";
import { SharedService } from "../../../../../app/shared/services/shared.service";

@Component({
    selector: 'choose-account-popup-component',
    templateUrl: 'choose-account.component.html',
    styleUrls:['./choose-account.component.scss'],
    providers:[AdminApiService]
})
export class ChooseAccountPopupComponent implements ViewWillEnter, OnInit {

    accountId: number = null;

    accounts: IAccountData[] = [];

    constructor(
        private modalController: ModalController,
        private adminApiService: AdminApiService,
        public sharedService: SharedService
    ) {

    }

    ngOnInit(): void {
        this.getAccounts();
    }

    ionViewWillEnter(): void {
        
    }

    async getAccounts(): Promise<void> {
        // Will filter accounts if not an admin (accessed by a liaison manager)
        this.accounts = (await this.adminApiService.getAccountsData(!this.sharedService.getAuthorizationStatus()))
        .filter(a => this.sharedService.isActiveAccount(a)).sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }

            if (a.name < b.name) {
                return -1;
            }

            return 0;
        });
    }

    select(): void {
        this.modalController.dismiss(this.accountId)
    }

    close(): void {
        this.modalController.dismiss();
    }

}