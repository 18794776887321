<ion-row class="sub-row" *ngFor="let sort of sorts">
    <ion-col class="ion-no-padding">
        <ion-row class="sort-header">
            <ion-col class="ion-no-padding">
                Part #
            </ion-col>
            <ion-col class="ion-no-padding">
                Qty
            </ion-col>
            <ion-col class="ion-no-padding">
                Rejected
            </ion-col>
            <ion-col class="ion-no-padding">
                Reworked
            </ion-col>
        </ion-row>
        <ion-row class="table-data">
            <ion-col class="ion-no-padding">
                {{sort.part_numbers?.part_number ? sort.part_numbers?.part_number : sort.part_numbers?.barcode}}
            </ion-col>
            <ion-col class="ion-no-padding">
                {{sort.total}}
            </ion-col>
            <ion-col class="ion-no-padding reject-text">
                {{sort.rejectsQuantity}}
            </ion-col>
            <ion-col class="ion-no-padding rework-text">
                {{sort.reworksQuantity}}
            </ion-col>
        </ion-row>

        <div class="defect-container">
            <ion-row class="sort-header" *ngIf="sort.defects?.length > 0">
                <ion-col class="ion-no-padding">
                    Defect
                </ion-col>
                <ion-col class="ion-no-padding">
                    Rejected
                </ion-col>
                <ion-col class="ion-no-padding">
                    Reworked
                </ion-col>
                <ion-col class="ion-no-padding">
                    Pass Rate
                </ion-col>
                <ion-col class="image-icon">
                    
                </ion-col>
            </ion-row>

            <ng-container *ngFor="let defect of sort.defects">
                <ion-row class="table-data defect-data" >
                    <ion-col class="ion-no-padding" (click)="openDefectReport(defect, sort)">
                        {{defect.name}}
                    </ion-col>
                    <ion-col class="ion-no-padding reject-text" (click)="openDefectReport(defect, sort)">
                        {{defect.rejects}}
                    </ion-col>
                    <ion-col class="ion-no-padding rework-text" (click)="openDefectReport(defect, sort)">
                        {{defect.reworks}}
                    </ion-col>
                    <ion-col class="ion-no-padding" (click)="openDefectReport(defect, sort)">
                        {{defect.rate | percent}}
                    </ion-col>
                    <ion-col class="image-icon ion-no-padding" (click)="openDefectImages(defect)">
                        <ion-icon *ngIf="defect.images?.length > 0" name="images-outline"></ion-icon>
                    </ion-col>
                </ion-row>
    
                <ion-row class="attachment-container" *ngIf="defect.images?.length > 0">
                    <app-attachment-upload
                        #attachmentUpload
                        [condensed]="true"
                        [attachments]="defect.images"
                        [isDisabled]="true"
                        [context]="'issue'"
                        [startHidden]="true"
                        [showButtons]="false"
                        [showAttachmentCount]="true"
                        [paddingAmount]="'0px'"
                        [imageUIOnly]="true"
                        [showDelete]="false"
                    ></app-attachment-upload>
                </ion-row>
            </ng-container>
        </div>
    </ion-col>
</ion-row>