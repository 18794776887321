import {
  APP_INITIALIZER,
  NgModule,
  Optional,
  PLATFORM_ID,
  Injectable,
} from "@angular/core";
import {
  BrowserModule,
  BrowserTransferStateModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RESPONSE } from "@nguniversal/express-engine/tokens";
import { isPlatformServer } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { ReactiveFormsModule } from "@angular/forms";

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppUIModule } from "./shared/ui/app-ui.module";
import { AdminComponentsModule } from "./features/admin/modules/admin-components.module";

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { FormsModule } from "@angular/forms";
import { LiaisonStateService } from "./features/liaison/services/liaison-state-service";
import { AttachmentUploadService } from "./shared/services/attachment-upload.service";
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
  HAMMER_LOADER,
} from "@angular/platform-browser";
import * as Hammer from "hammerjs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthInterceptor } from "./shared/services/auth-interceptor.service";
import { AuthService } from "./shared/services/auth.service";

import { NgxMaskModule } from 'ngx-mask';

import { ResponseHttpInterceptor } from "./features/login/interceptors/response.interceptor";

import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { CacheInterceptor } from "./shared/services/cache.service";
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { InjectionToken } from "@angular/core";


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/starter-kit/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserTransferStateModule,
    IonicModule.forRoot({
      animated: false,
      scrollPadding: false,
      scrollAssist: true,
      swipeBackEnabled: false
    }),
    AppRoutingModule,
    HttpClientModule,
    AppUIModule,
    AdminComponentsModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularFireModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FormsModule,
    HammerModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    IonicStorageModule.forRoot({
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (platformId: object, response: any) => {
        return () => {
          // In the server.ts we added a custom response header with information about the device requesting the app
          if (isPlatformServer(platformId)) {
            if (response && response !== null) {
              // Get custom header from the response sent from the server.ts
              const mobileDeviceHeader = response.get("mobile-device");

              // Set Ionic config mode?
            }
          }
        };
      },
      deps: [PLATFORM_ID, [new Optional(), RESPONSE]],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseHttpInterceptor, multi: true },
    AngularFireStorage,
    LiaisonStateService,
    AttachmentUploadService,
    AuthService,
    CacheInterceptor,
    OpenNativeSettings,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
