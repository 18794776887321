import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ModalController } from '@ionic/angular';
import { IAttachment } from '../../models/attachment.model';
import { PreviewAttachmentComponent } from '../preview-attachment/preview-attachment.component';

@Component({
    selector: 'reject-image-popup-component',
    templateUrl: 'reject-image-popup.component.html',
    styleUrls:['./reject-image-popup.component.scss']
})

export class RejectImagePopupComponent implements OnInit {
    
    @Input() images: IAttachment[] = null;

    constructor(
        private sharedService: SharedService,
        private modalController: ModalController
    ) {

    }

    ngOnInit(): void {
        console.log(this.images)
    }

    async previewAttachment(attachment: IAttachment): Promise<void> {
        const createModal = await this.modalController.create({
            component: PreviewAttachmentComponent,
            cssClass: 'min-width-modal grey-background',
            showBackdrop: true,
            componentProps: {
                attachment: attachment
            },
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true
        });

        await createModal.present();
        const { data } = await createModal.onDidDismiss();
    }

    closeModal(): void {
        this.modalController.dismiss();
    }


}