import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { IAdditionalTime } from "../../../../../../../app/shared/models/additional-time.model";
import { ManualApprovalComponent } from "../manual-approval/manual-approval.component";
import { LiaisonStateService } from "../../../../../../../app/features/liaison/services/liaison-state-service";
import { AdminApiService } from "../../../../../../../app/features/admin/services/admin-api-service";
import { SharedService } from "../../../../../../../app/shared/services/shared.service";
import { LiaisonApiService } from "../../../../../../../app/features/liaison/services/liaison-api-service";
import { ExtendedContact } from "../../../../../../../app/shared/models/contact.model";

@Component({
  selector: "edit-approval-modal",
  templateUrl: "edit-approval.component.html",
  styleUrls: ["./edit-approval.component.scss"],
  providers: [AdminApiService, LiaisonStateService, LiaisonApiService]
})
export class EditApprovalComponent implements OnInit {

    additionalTime: IAdditionalTime = null;
    currentHours: number = null;

    email: string = null;
    primaryContact: ExtendedContact

    canUpdate: boolean = false;
    loaded: boolean = false;

    constructor(
        private modalController: ModalController,
        private liaisonStateService: LiaisonStateService,
        private adminApiService: AdminApiService,
        private sharedService: SharedService,
        private liaisonApiService: LiaisonApiService
    ) {

    }

    async ngOnInit(): Promise<void> {
        this.currentHours = this.additionalTime.hours;

        await this.getPrimaryContact();

        this.loaded = true;
    }

    async getPrimaryContact(): Promise<void> {
        this.primaryContact = await this.liaisonApiService.getPrimaryContact(this.liaisonStateService.getActiveAccountId());
 
        if (this.primaryContact) {
            this.email = this.primaryContact?.contacts?.email;
        }
    }

    hoursChanged(): void {
        if ((this.additionalTime.hours as any).length <= 0) {
            this.canUpdate = false;
        } 
        // else if (Number(this.additionalTime.hours) === this.currentHours) {
        //     this.canUpdate = false;
        // } 
        else {
            this.canUpdate = true;
        }
    }

    async cancel(): Promise<void> {
        try {
            const timeRequest: IAdditionalTime = await this.adminApiService.cancelAdditionalHours(this.additionalTime.id);

            this.sharedService.presentToast('primary', 'The additional time request was been canceled successfully!', 'Success!', 'short');

            this.modalController.dismiss({remove: this.additionalTime.id});
        } catch (error) {
            this.sharedService.presentToast('danger', 'The additional time request was not canceled successfully!', 'Failed!', 'med');
        }

    }

    async update(): Promise<void> {
        const newTimeApproval: IAdditionalTime = {
            id: null,
            user_id: this.liaisonStateService.getUserId(),
            account_id: this.liaisonStateService.getActiveAccountId(),
            hours: this.additionalTime.hours,
            reason: this.additionalTime.reason,
            approved: this.additionalTime.approved ?? false,
            approved_by: this.additionalTime.approved_by ?? null,
            approval_date: this.additionalTime.approval_date ?? null,
            approval_method: this.additionalTime.approval_method ?? null,
            date: this.additionalTime.date,
            rejected: this.additionalTime.rejected ?? false
        };

        try {
            if (!this.additionalTime?.id) {
                this.sharedService.presentToast('danger', 'An error occured with this request.', 'Error!', 'med');
                return;
            }

            const timeRequest: IAdditionalTime = await this.adminApiService.addAdditionalHours(newTimeApproval);
    
            if (timeRequest) {
              const success: boolean = await this.liaisonApiService.sendAdditionalTimeToCustomerViaEmail(timeRequest, this.email);

              await this.adminApiService.cancelAdditionalHours(this.additionalTime.id);

              this.sharedService.presentToast('primary', 'The additional time request was updated successfully.', 'Success!', 'short');

              this.modalController.dismiss({remove: this.additionalTime.id, add: timeRequest});
            }
        } catch (error) {
            this.sharedService.presentToast('danger', 'The additional time request was not updated successfully.', 'Failed!', 'med');
        }
    }

    close(): void {
        this.modalController.dismiss();
    }

}