import { Injectable } from "@angular/core";
import decode from "jwt-decode";
import { User } from "../models/user.model";

@Injectable()
export class AuthService {
  getToken() {
    return localStorage.getItem("authToken");
  }
  
  setToken(token: any) {
    localStorage.setItem("authToken", token);
  }

  setUser(user: any) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user")) as User;
  }

  isAuthenticated() {
    const token = this.getToken();
    return token !== null;
  }
}
