import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable, Subject } from "rxjs";

type direction = 'asc' | 'desc';

interface ISortData {
    id: string,
    direction: direction,
    active: boolean
}

@Component({
  selector: "sort-header",
  templateUrl: "column-sort-header.component.html",
  styleUrls: ["./column-sort-header.component.scss"],
})
export class ColumnSortHeaderComponent {

    @Input() text: string = null;
    @Input() identifier: string = null;
    @Input() active: boolean = false;
    @Input() direction: direction = 'asc';
    @Input() center: boolean = false;

    @Output() sortData: EventEmitter<ISortData> = new EventEmitter<ISortData>();

    private dataSubject = new Subject<ISortData>();

    private dataObservable = this.dataSubject.asObservable()

    constructor() {}

    public getData(): Observable<ISortData>{
        return this.dataObservable;
    }

    toggleSort(): void {
        if (this.active && (this.direction === 'asc')) {
            this.direction = 'desc';
        } else if (this.active && (this.direction === 'desc')) {
            this.active = false;
            this.direction = 'asc';
        } else {
            this.active = true;
            this.direction = 'asc';
        }

        const data: ISortData = {id: this.identifier, direction: this.direction, active: this.active};
        this.sortData.emit(data);
        this.dataSubject.next(data);
    }

    setActive(value: boolean) {
        this.active = value;
        this.direction = 'asc';
    }

}
