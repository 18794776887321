<ion-grid>
    <ion-row class="header-row">
        <ion-col class="modal-header-container">
            <span class="modal-header-text">Recipients</span>
            <span id="click-trigger" class="modal-desc">How does this work?</span>
            <ion-popover trigger="click-trigger" triggerAction="click">
                <ng-template>
                    <div class="modal-explained">
                        <span>Unchecking the box will remove this recipient from the current report only. Tapping the trash icon will remove them from your account's recipient list permanently.</span>
                    </div>
                </ng-template>
            </ion-popover>
        </ion-col>
        <ion-col size="auto">
            <ion-icon
            class="cross"
            (click)="closeModal()"
            name="close-outline"
            ></ion-icon>
        </ion-col>
    </ion-row>
    <div class="container">
    <div class="contacts-container" *ngIf="loaded && allEmails.length > 0">
        <div class="contact-wrapper" *ngFor="let email of allEmails">
            <ion-row *ngIf="email.email === primaryEmail">
                <span> Primary Contact</span>
            </ion-row>
            <ion-row>
                <ion-col class="checkbox-col" size="auto">
                    <ion-checkbox [(ngModel)]="email.active" (ionChange)="checkboxChange(email, $event)"></ion-checkbox>
                </ion-col>
                <ion-col class="email">
                    {{email.email}}
                </ion-col>
                <ion-col (click)="deleteRecipient(email)" class="trash-container" size="auto">
                    <ion-icon name="trash-outline"></ion-icon>
                </ion-col>
            </ion-row>
        </div>
    </div>
    <div class="empty-container" *ngIf="loaded && allEmails.length <= 0">
        <ion-row>
            <ion-col>
                <span>No Recipients</span>
            </ion-col>
        </ion-row>
    </div>
    <div class="empty-container" *ngIf="!loaded">
        <ion-row>
            <ion-col>
                <ion-spinner></ion-spinner>
            </ion-col>
        </ion-row>

    </div>
    <ion-row (click)="addContact()" class="add-contact">
        <ion-col>
        Add Recipient
        </ion-col>
    </ion-row>
    </div>
    <ion-row>
        <ion-col>
            <button (click)="send()" class="send-button">Send</button>
        </ion-col>
    </ion-row>
</ion-grid>