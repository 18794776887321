import { Pipe, PipeTransform } from "@angular/core";
import { IAccountData } from "../../../shared/models/account.model";

@Pipe({
    name: 'filterAccountByCustomer'
})

export class FilterAccountByCustomerPipe implements PipeTransform {


    transform(items: IAccountData[], customerId: number) {

        if (!items) {
            return [];
        }

        return items.filter(i => i.customers.id === customerId).sort((a, b) => {
            if (b.name < a.name) return 1;
            if (b.name > a.name) return -1;
            return 0;
        });

    }
}