<ion-grid>
    <!-- <ion-row class="close-row">
        <ion-col>
            <ion-icon name="close-outline"></ion-icon>
        </ion-col>
    </ion-row> -->
    <ion-row class="header">
        <ion-col>
            <span *ngIf="!overrideText">{{isUpdate ? 'Update Created!' : 'Issue Created!'}}</span>
            <span *ngIf="overrideText">{{overrideText}}</span>
        </ion-col>
    </ion-row>
    <ion-row class="body">
        <ion-col>
            <span *ngIf="!overrideMessage">Does the customer need to be notified immediately?</span>
            <span *ngIf="overrideMessage">{{overrideMessage}}</span>
        </ion-col>
    </ion-row>
    <ion-row class="button-row">
        <ion-col>
            <ion-button (click)="closeModal(false)" class="no">No</ion-button>
        </ion-col>
        <ion-col>
            <ion-button (click)="closeModal(true)" class="yes">Yes</ion-button>
        </ion-col>
    </ion-row>
</ion-grid>