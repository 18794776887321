import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IonSelect, ViewWillEnter } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ICustomer } from '../../../../../../shared/models/customer.model';
import { AdminApiService } from '../../../../services/admin-api-service';
import { Contact } from '../../../../../../../app/shared/models/contact.model';
import { CustomerContact } from '../../../../../../../app/shared/models/contact.model';
import { NewContactPopupComponent } from '../../../contacts/components/new-contact/new-contact.component';
import { SharedService } from '../../../../../../../app/shared/services/shared.service';

@Component({
    selector: 'new-customer-popup-component',
    templateUrl: 'new-customer.component.html',
    styleUrls:['./new-customer.component.scss'],
    providers:[AdminApiService]
})

export class NewCustomerPopupComponent implements ViewWillEnter, OnInit {

    contacts: Contact[] = [];

    loaded: boolean = false;

    customerName: string = null;
    customerAddress: string = null;
    customerCity: string = null;
    customerState: string = null;
    customerPostal: string = null;
    notes: string = null;
    active: boolean = true;
    supplierCode: string = null;
    paymentTerm: string = null;
    primaryApprover: number = null;
    invoice_period: string = null;
    seperate_line_items_by_account: boolean = false;
    seperate_line_items_by_liaison: boolean = false;
    invoice_approver: number = null;
    creating: boolean = false;
    country: 'US' | 'MX' | 'CA' | 'KO' = null;
    address2: string = null;
    
    newCustomerForm: FormGroup = new FormGroup({
        name: new FormControl(this.customerName),
        address: new FormControl(this.customerAddress),
        city: new FormControl(this.customerCity),
        state: new FormControl(this.customerState),
        postal: new FormControl(this.customerPostal),
        notes: new FormControl(this.notes),
        active: new FormControl(this.active),
        supplierCode: new FormControl(this.supplierCode),
        paymentTerm: new FormControl(this.paymentTerm),
        primaryApprover: new FormControl(this.primaryApprover),
        invoice_period: new FormControl(this.invoice_period),
        seperate_line_items_by_account: new FormControl(this.seperate_line_items_by_account),
        seperate_line_items_by_liaison: new FormControl(this.seperate_line_items_by_liaison),
        invoice_approver: new FormControl(this.invoice_approver),
        country: new FormControl(this.country),
        address2: new FormControl(this.address2)
    });

    constructor(
        private modalCtrl: ModalController,
        private adminApiService: AdminApiService,
        private sharedService: SharedService
    ) {

    }

    async ngOnInit(): Promise<void> {
        await this.getContacts();

        this.newCustomerForm.patchValue({country: 'US'});

        this.loaded = true;
    }

    ionViewWillEnter(): void {
        setTimeout(() => {
            this.newCustomerForm.patchValue({
                invoice_period: 'weekly'
            });
        }, 200);
    }

    async getContacts(): Promise<void> {
        const contacts = this.sharedService.sortFirstNameLastName<Contact>(await this.adminApiService.getContacts())?.filter(c => c.active);

        if (contacts) {
            contacts.forEach(c => {
                if (!this.contacts.map(x => x.email).includes(c.email)) {
                    this.contacts.push(c);
                }
            });
        }
    }

    async addNewContact(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: NewContactPopupComponent,
            componentProps: {
              
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            this.contacts.push(data.contact);

            setTimeout(() => {
                this.newCustomerForm.patchValue({
                    primaryApprover: data.contact.id
                });
            }, 400);
        }
    }

    async submitNewCustomer(): Promise<void> {
        if (this.creating) {
            return;
        }

        const newCustomer: ICustomer = {
            company_name: this.newCustomerForm.value.name,
            address: this.newCustomerForm.value.address,
            city: this.newCustomerForm.value.city,
            state: this.newCustomerForm.value.state,
            postal: this.newCustomerForm.value.postal,
            notes: this.newCustomerForm.value.notes,
            is_active: this.newCustomerForm.value.active,
            supplier_code: this.newCustomerForm.value.supplierCode,
            payment_terms: this.newCustomerForm.value.paymentTerm,
            invoice_period: this.newCustomerForm.value.invoice_period,
            seperate_line_items_by_account: this.newCustomerForm.value.seperate_line_items_by_account,
            seperate_line_items_by_liaison: this.newCustomerForm.value.seperate_line_items_by_liaison,
            invoice_approver: this.newCustomerForm.value.invoice_approver,
            address2: this.newCustomerForm.value.address2,
            country: this.newCustomerForm.value.county
        };

        this.creating = true;

        var customer: ICustomer = null;

        try {
            customer = await this.adminApiService.addCustomer(newCustomer);
        } catch (error) {
            console.log(error);
            this.creating = false;
            if (error.error === 'Quickbooks failed to sync') {
                this.sharedService.presentToast('danger', 'Quickbooks failed to process customer. This may be caused by quickbooks not being synced with the QAS server.', 'Failed!', 'long');
            } else if (error.error === 'Quickbooks customer exists') {
                this.sharedService.presentToast('danger', 'Quickbooks failed to process customer. This may be caused by a duplicate customer name.', 'Failed!', 'long');
            } else if (error.error === 'Quickbooks failed to inactivate') {
                this.sharedService.presentToast('danger', 'The Quickbooks balance for this customer is greater than 0 and cannot be inactivated until resolved.', 'Failed!', 'long');
            } else {
                this.sharedService.presentToast('danger', 'Customer was not added successfully.', 'Failed!', 'med');
            }
        }

        if (customer) {
            const customerContact: CustomerContact = 
            new CustomerContact(null, this.newCustomerForm.value.primaryApprover, customer.id, false, false, false, false, null, 'Quality', null, true);
    
            const returnCustomerContact: CustomerContact = await this.adminApiService.addCustomerContact(customerContact);
    
            customer.invoice_approver = returnCustomerContact.id;
    
            await this.adminApiService.updateCustomer(customer);
     
            this.sharedService.presentToast('primary', 'Customer was successfully created in QAS Track and Quickbooks.', 'Success!', 'med');

            this.closeModal(customer);
        }

        this.creating = false;
    }

    closeModal(update: ICustomer = null){
        this.modalCtrl.dismiss(update);
    }
}