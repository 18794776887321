import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../../services/auth.service";
import { PopoverController } from "@ionic/angular";
import { SharedService } from "../../../../../../app/shared/services/shared.service";
import { ModalController } from "@ionic/angular";
import { ReportIssueComponent } from "../report-issue/report-issue.component";
import { NavController } from "@ionic/angular";
import { LoginService } from "../../../../../../app/features/login/services/login.service";

@Component({
  selector: "app-profile-popup",
  templateUrl: "profile-popup.component.html",
  styleUrls: ["./profile-popup.component.scss"],
  providers: [LoginService],
})
export class ProfilePopupComponent implements OnInit {

  payEntryUrl: string = 'https://identity.payentry.com/Account/Login';

  constructor(
    private router: Router,
    private authSerivce: AuthService,
    private popoverController: PopoverController,
    private sharedService: SharedService,
    private modalController: ModalController,
    private navController: NavController,
    private loginService: LoginService
  ) {}

  ngOnInit() {

  }

  isAdmin(): boolean {
    const role = JSON.parse(this.sharedService.localStorageGet('user'))?.role;
    return role === 'admin';
  }

  isLiaison(): boolean {
    const role = JSON.parse(this.sharedService.localStorageGet('user'))?.role;
    return role === 'liaison';
  }

  isCustomer(): boolean {
    const role = JSON.parse(this.sharedService.localStorageGet('user'))?.role;
    return role === 'customer';
  }

  close(): void {
    this.popoverController.dismiss();
  }

  goToPayentry(): void {
    window.open(this.payEntryUrl);
  }

  goToCompanySettings(): void {
    this.router.navigate(['/admin/company-settings']);
    this.close()
  }

  async reportBug(): Promise<void> {
    this.close()
    const modal = await this.modalController.create({
      component: ReportIssueComponent,
      componentProps: {

      },
      cssClass: "full-width-full-height-modal",
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
      breakpoints: [0, 1]
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();

    if (data) {
      
    }
  }

  async goToSettings(): Promise<void> {
    this.router.navigate(['/settings']);
    this.close()
  }

  restartWalkthrough(): void {
    this.router.navigate(["/onboard"]);
    this.close()
  }

  async signOut(): Promise<void> {
    await this.loginService.signOut();
    this.close();
    this.navController.navigateRoot(["/login"]);
  }
}
