import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ViewWillEnter } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { LiaisonIssue } from "../../../../../../../app/shared/models/liaison-issue.model";
import { LiaisonIssueUpdate } from "../../../../../../../app/shared/models/liaison-issue-updates.model";
import { LiaisonApiService } from "../../../../../../../app/features/liaison/services/liaison-api-service";
import { LiaisonStateService } from "../../../../../../../app/features/liaison/services/liaison-state-service";
import { Contact } from "../../../../../../../app/shared/models/contact.model";
import { Router } from "@angular/router";

@Component({
  selector: "add-contact-component",
  templateUrl: "add-contact.component.html",
  styleUrls: ["./add-contact.component.scss"],
  providers: [LiaisonApiService],
})
export class AddContactComponent implements OnInit {

    email: string = null;

    constructor(
        private modalCtrl: ModalController,
        private liaisonApiService: LiaisonApiService,
        private liaisonStateService: LiaisonStateService,
        private router: Router
    ) {}

    ngOnInit(): void {

    }

    addContact(): void {
        this.modalCtrl.dismiss(this.email);
    }

    async closeModal() {
        this.modalCtrl.dismiss();
    }
}
