import { Injectable } from '@angular/core';
import { LiaisonMessage } from '../../../shared/models/liaison-message.model';
import { LiaisonReport } from '../../../shared/models/liaison-report.model';
import { LiaisonAccount } from '../../../shared/models/liaison-account.model';
import { ISort } from '../models/daily-report.model';
//providedIn: 'root' is important to ensure that this service is a singleton!
//since it is hoisting state up to multiple components, this is important!

@Injectable({
    providedIn: 'root'
})
export class LiaisonStateService {

    dailyReports:LiaisonReport[];

    messages:LiaisonMessage[];

    accounts:LiaisonAccount[];


    //We will likely need to add various validations and behaviors, so I would rather these properties be private
    //placeholder, hardcoded
    #userId:number;
    #activeDailyReport:LiaisonReport;
    #activeAccountId:number;

    //placeholder
    #activeSort:ISort;

    //#activeSort:ISort;

    constructor() {
        //placeholder for api calls
        //this service will eventually check for local data that is more up to date than the db
        //right now we are just using this service to give the illusion of persistent data with our local json 
    }

    setActiveDailyReport(newReport:LiaisonReport){
        localStorage.setItem('activeDailyReport', JSON.stringify(newReport));
        this.#activeDailyReport = newReport;
    }

    getUserId(){
        if (!this.#userId){
            return Number(localStorage.getItem("userId"))
        }
        return this.#userId;
    }

    getRole(){
        const user = JSON.parse(localStorage.getItem("user"));

        return user?.role ?? 'liaison';
    }

    setUserId(userId: number){
        localStorage.setItem('userId', String(userId));
        this.#userId = userId;
    }

    getUserContactId(){
        const user = JSON.parse(localStorage.getItem("user"));

        return user?.contact_id ?? 0;
    }

    getActiveDailyReport():LiaisonReport{
        //if not in memory, try to pull from localstorage. This is currently used mainly for navigation
        if (!this.#activeDailyReport){
            return JSON.parse(localStorage.getItem('activeDailyReport'));
        }
        return this.#activeDailyReport;
    }

    setActiveAccountId(accountId:number){
        localStorage.setItem('activeAccountId', JSON.stringify(accountId));
        this.#activeAccountId = accountId;
    }

    getActiveAccountId():number{
        //if not in memory, try to pull from localstorage. This is currently used mainly for navigation
        if (!this.#activeAccountId){
            return JSON.parse(localStorage.getItem('activeAccountId'))
        }
        return this.#activeAccountId;
    }

    setActiveTestSort(sort:ISort){
        localStorage.setItem('activeSort', JSON.stringify(sort));
        this.#activeSort = sort;
    }

    getActiveTestSort():ISort{
       if (!this.#activeSort){
            if (JSON.parse(localStorage.getItem('activeSort'))){
                return JSON.parse(localStorage.getItem('activeSort')) as ISort;
            }
            else{
                return null;
            }
       }
    }

    
}