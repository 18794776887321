import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Platform } from "@ionic/angular";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { SharedService } from "../../services/shared.service";
import { BarcodeManualEntryComponent } from "./components/manual-entry/manual-entry.component";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-barcode-scanner",
  templateUrl: "./barcode-scanner.component.html",
  styleUrls: ["./barcode-scanner.component.scss"],
})
export class BarcodeScannerComponent implements OnInit {
  @Output() barcodeScanEvent = new EventEmitter<string>();
  @Input() fullWidth: boolean = false;
  @Input() useSmallButton: boolean = false;
  @Input() useDefaultButton: boolean = false;

  constructor(
    private sharedService: SharedService,
    private platform: Platform,
    private modalController: ModalController
  ) {
    window.addEventListener('manualBarcodeEntry', (e) => {
      this.manualEntry();
    });

  }

  ngOnInit(): void {
    return;
  }

  async manualEntry() {
    const modal = await this.modalController.create({
      component: BarcodeManualEntryComponent,
      componentProps: {

      },
      cssClass: "min-width-modal stay-on-scanner",
      id: 'stay-on-scanner',
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    await modal.present();
    const {data} = await modal.onDidDismiss();

    if (data && (data?.length > 0)) {
      if (this.platform.is("capacitor")) {
        document.body.classList.remove("scanner-active");
        BarcodeScanner.showBackground();
      }

      this.barcodeScanEvent.emit(data);
    }
  }

  async startScan() {
    await this.checkBarcodePermission();
    if (this.platform.is("capacitor")) {
      document.body.classList.add("scanner-active");
      //(window.document.querySelector('ion-app') as HTMLElement).classList.add('cameraView');
      BarcodeScanner.hideBackground();
    }
    BarcodeScanner.prepare();
    const res = await BarcodeScanner.startScan();
    if (this.platform.is("capacitor")) {
      document.body.classList.remove("scanner-active");
      //(window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');
      BarcodeScanner.showBackground();
    }
    this.barcodeScanEvent.emit(res.content);
  }

  stopScan(): void {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  };

  async checkBarcodePermission() {
    return new Promise(async (resolve, reject) => {
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        resolve(true);
      } else if (status.denied) {
        await this.sharedService.presentToast(
          "warning",
          "Could not access camera, please check your permissions",
          "Camera Permission Error",
          "med"
        );
        BarcodeScanner.openAppSettings();
        resolve(false);
      } else {
        resolve(false);
      }
    });
  }
}
