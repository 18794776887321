import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { LiaisonStateService } from "../../../../../../app/features/liaison/services/liaison-state-service";
import { SharedService } from "../../../../../../app/shared/services/shared.service";
import { LiaisonApiService } from "../../../../../../app/features/liaison/services/liaison-api-service";
import { Keyboard } from "@capacitor/keyboard";
import { Platform } from "@ionic/angular";

@Component({
  selector: "app-report-issue-popup",
  templateUrl: "report-issue.component.html",
  styleUrls: ["./report-issue.component.scss"],
  providers: [LiaisonApiService, LiaisonStateService, SharedService],
})
export class ReportIssueComponent implements OnInit {

    userId: number = null;
    content: string = null;

    keyboardStatus = false;

    constructor(
        private router: Router,
        private modalController: ModalController,
        private liaisonStateService: LiaisonStateService,
        private sharedService: SharedService,
        private liaisonApiService: LiaisonApiService,
        public platform: Platform
    ) {}

    async ngOnInit(): Promise<void> {
        this.userId = this.liaisonStateService.getUserId();
    }

    async sendData(): Promise<void> {

        const status = await this.liaisonApiService.sendBugReportEmail(this.userId, this.content);

        if (status) {
            this.sharedService.presentToast('primary', 'Your issue report has been sent successfully.', 'Success!', 'med');
            this.content = null;
            this.close();
        } else {
            this.sharedService.presentToast('danger', 'Your issue report failed to send.', 'Failed!', 'med');
        }

    }

    close(): void {
        this.modalController.dismiss();
    }

}