import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../../services/auth.service";
import { ModalController } from "@ionic/angular";
import { ILocation } from "../../../../../../app/shared/models/location.model";
import { LiaisonApiService } from "../../../../../../app/features/liaison/services/liaison-api-service";
import { SharedService } from "../../../../../../app/shared/services/shared.service";
import { CustomerApiService } from "../../../../../../app/features/customer/services/customer-api.service";
import { IPersonnel } from "../../../../../../app/features/customer/pages/account-page/account-page.page";

@Component({
  selector: "app-contact-us-popup",
  templateUrl: "contact-us-popup.component.html",
  styleUrls: ["./contact-us-popup.component.scss"],
  providers: [AuthService, LiaisonApiService, CustomerApiService],
})
export class ContactUsComponent implements OnInit {

    locations: ILocation[] = [];
    locationDisplay: ILocation[] = [];
    search: string = '';

    qasPersonnel: IPersonnel[] = [];

    constructor(
        private router: Router,
        private modalController: ModalController,
        private liaisonApiService: LiaisonApiService,
        public sharedService: SharedService,
        private customerApiService: CustomerApiService
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.sharedService.isLiaison()) {
            this.qasPersonnel = await this.customerApiService.getQASPersonnel();
        } else {
            this.locations = await this.liaisonApiService.getLocations();
            this.locationDisplay = this.locations;
        }
    }

    close(): void {
        this.modalController.dismiss();
    }

    updateLocations(): void {
        this.locationDisplay = this.locations.filter(l => (l.name?.toLowerCase().includes(this.search.toLowerCase())) || 
        (l.city && l.city?.toLowerCase().includes(this.search.toLowerCase())) || 
        (l.state && l.state?.toLowerCase().includes(this.search.toLowerCase())));
    }

}