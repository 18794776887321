<ion-grid>
    <ion-row class="header-row" id="hide-on-camera">
        <ion-col class="header-text">
          <span class="modal-header-text">Defect Attachments</span>
        </ion-col>
        <ion-col size="auto">
          <ion-icon
            class="cross"
            (click)="closeModal()"
            name="close-outline"
          ></ion-icon>
        </ion-col>
    </ion-row>

    <div class="image-container" *ngIf="images.length > 0">
        <div class="container-div" [ngClass]="{'rework-text': attachment.reject?.is_rework, 'reject-text': !attachment.reject?.is_rework}" *ngFor="let attachment of images">
            <div>  
                <ion-img (click)="previewAttachment(attachment)" [src]="attachment?.images?.image_url"></ion-img>
            </div>
        </div>
    </div>

    <div class="no-attachment-text" *ngIf="images.length === 0">
        No Attachments
    </div>

</ion-grid>