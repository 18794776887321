import { Component, Input, OnInit, OnDestroy} from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource, GalleryPhoto, Photo}  from '@capacitor/camera';
import { Platform } from '@ionic/angular';
import { SharedService } from '../../services/shared.service';
import { decode } from 'base64-arraybuffer';
import { IAttachment, IIssueAttachment, IRejectAttachment, IReportAttachment } from '../../models/attachment.model';
import { ModalController } from '@ionic/angular';
import { PreviewAttachmentComponent } from '../preview-attachment/preview-attachment.component';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { AngularFireStorage } from '@angular/fire/compat/storage'; 
import { getStorage, ref} from 'firebase/storage';
import { catchError, finalize, timeout } from "rxjs/operators";
import { firstValueFrom, of } from 'rxjs';
import { AttachmentUploadService } from '../../services/attachment-upload.service';
import { Output, EventEmitter } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { LiaisonStateService } from '../../../../app/features/liaison/services/liaison-state-service';


@Component({
    selector: 'app-defect-attachment-upload',
    templateUrl: 'defect-attachment-upload.component.html',
    styleUrls: ['./defect-attachment-upload.component.scss']
    
})

export class DefectAttachmentUploadComponent implements OnInit {

    @Input() attachments: IAttachment[] = [];
    @Input() context: string = null;
    @Input() id: number = null;
    @Input() idList: number[] = null;
    @Input() showButtons: boolean = true;
    @Input() showDelete: boolean = true;
    @Input() hasPadding: boolean = true;
    @Input() startHidden: boolean = false;
    @Input() showAttachmentCount: boolean = false;
    @Input() isDisabled: boolean = false;

    @Output() generateId = new EventEmitter<{}>();
    @Output() imageCount = new EventEmitter<number>();
    @Output() unsyncedAttachmentEvent = new EventEmitter<any>();

    unsyncedAttachments: any[] = [];
    activeImageUrl: string = null;

    loading: boolean = false;
 
    filePath: string = 'Uploads';

    constructor(
        private platform: Platform,
        private sharedService: SharedService,
        private modalController: ModalController,
        private storage: AngularFireStorage,
        private attachmentUploadService: AttachmentUploadService,
        private actionSheetController: ActionSheetController,
        private liaisonStateService: LiaisonStateService
    ) { }

    async ngOnInit(): Promise<void> {
        if (this.context === 'report' && this.id && (this.id as any !== 'hide-on-camera')) {
            this.attachments = await this.attachmentUploadService.getReportAttachment(this.id);
        } else if (this.context === 'issue' && this.id && (this.id as any !== 'hide-on-camera')) {
            this.attachments = await this.attachmentUploadService.getIssueAttachment(this.id);
        } else if (this.context === 'reject' && this.id && (this.id as any !== 'hide-on-camera')) {
            this.attachments = await this.attachmentUploadService.getRejectAttachment(this.id);
        } else if (this.context === 'reject' && this.idList) {
            this.idList.forEach(async (innerId) => {
                const rejectAttachments: IAttachment[] = await this.attachmentUploadService.getRejectAttachment(innerId);
                if (rejectAttachments) {
                    rejectAttachments.forEach((attachment: IAttachment) => {
                        this.attachments.push(attachment);
                    });
                }
            })
        }
    }

    async pickImagesForUpdate(): Promise<void> {
        const options = {
            quality: 90,
            limit: 5 // Set limit for number of images
        };
    
        const { photos } = await Camera.pickImages(options);

        // Convert each GalleryPhoto into a Blob and upload
        for (const photo of photos) {
            const blob = await this.convertGalleryPhotoToBlob(photo);
            if (blob) {
                this.firebaseUpload(blob); // Upload each blob
            }
        }
    }

    async convertGalleryPhotoToBlob(photo: GalleryPhoto): Promise<Blob> {
        try {
          // You can use the base64 representation or fetch the image from the webPath URL.
          const response = await fetch(photo.webPath); // Fetch the image using webPath
          const blob = await response.blob(); // Convert to blob
          return blob;
        } catch (error) {
          console.error('Error converting photo to Blob', error);
          return null;
        }
    }

    async processUpload(source: CameraSource): Promise<void> {
        const base64Image: Photo = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            quality: 80,
            source: source
        });

        //const displayString: string = `data:image/${base64Image.format};base64,${base64Image.base64String}`;

        const blob = new Blob([new Uint8Array(decode(base64Image.base64String))], {
            type: `image/${base64Image.format}`
        });

        this.firebaseUpload(blob, source);
    }

    firebaseUpload(blob: Blob, source?: CameraSource): void {
        try {
            this.loading = true;
            const fileName = `${this.context}-${new Date().getTime()}`
            const fullPath = `${this.filePath}/${fileName}`;
            const fileRef = this.storage.ref(fullPath);
            const task = this.storage.upload(fullPath, blob);

            task.snapshotChanges()
                .pipe(
                    timeout(10000),
                    catchError((error) => {
                        this.sharedService.presentToast('danger', 'Attachment failed to upload. This can be due to low connectivity issues.', 'Upload Failed!', 'long');
                        task.cancel();
                        this.loading = false;

                        return of(null);
                    }),
                    finalize(() => {
                        const getURL = fileRef.getDownloadURL().subscribe(async (downloadURL) => {
                            if (downloadURL) {
                                this.activeImageUrl = downloadURL;
                                if (this.context === 'report') {
                                    await this.handleReportUpload(downloadURL);
                                } else if (this.context === 'issue') {
                                    await this.handleIssueUpload(downloadURL);
                                } else if (this.context === 'reject') {
                                    await this.handleRejectUpload(downloadURL);
                                }
                            }

                            this.loading = false;
                            this.sharedService.presentToast('primary', 'Attachment has been uploaded successfully.', 'Success!', 'med');
                        });
                    })
                ).subscribe();
        } catch (error) {
            console.log(error);
            this.sharedService.presentToast('danger', 'Attachment failed to upload. This can be due to low connectivity issues.', 'Upload Failed!', 'long');
        }
    }

    async handleReportUpload(downloadURL: string): Promise<void> {
        if (this.id) {
            const newAttachment: IReportAttachment = {
                userId: Number(this.sharedService.localStorageGet('userId')),
                imageUrl: downloadURL,
                reportId: this.id
            };
    
            const attachment = await this.attachmentUploadService.postReportAttachment(newAttachment);
            this.attachments.push(attachment);
        } else {
            const unsyncedAttachment = {
                images: {
                    image_url: downloadURL
                }
            }
            this.unsyncedAttachments.push(unsyncedAttachment);
        }
    }

    async handleIssueUpload(downloadURL: string): Promise<void> {
        if (this.id) {
            const newAttachment: IIssueAttachment = {
                userId: Number(this.sharedService.localStorageGet('userId')),
                imageUrl: downloadURL,
                issueId: this.id
            };
    
            const attachment = await this.attachmentUploadService.postIssueAttachment(newAttachment);
            this.attachments.push(attachment);
        } else {
            const unsyncedAttachment = {
                images: {
                    image_url: downloadURL
                }
            }
            this.unsyncedAttachments.push(unsyncedAttachment);
            this.imageCount.emit(this.unsyncedAttachments?.length ?? 0);
            this.unsyncedAttachmentEvent.emit(unsyncedAttachment);
        }
    }

    async handleRejectUpload(downloadURL: string): Promise<void> {
        console.log('Handle Reject Upload', this.id);
        if (this.id) {
            const newAttachment: IRejectAttachment = {
                userId: Number(this.sharedService.localStorageGet('userId')),
                imageUrl: downloadURL,
                rejectId: this.id
            };
    
            const attachment = await this.attachmentUploadService.postRejectAttachment(newAttachment);
            this.attachments.push(attachment);
        } else {
            const unsyncedAttachment = {
                images: {
                    image_url: downloadURL
                }
            };
            this.unsyncedAttachments.push(unsyncedAttachment);
            this.imageCount.emit(this.unsyncedAttachments?.length ?? 0);
            this.unsyncedAttachmentEvent.emit(unsyncedAttachment);
        }
    }

    uploadFromGallery(): void {
        if (!this.id) {
            this.generateId.emit({});
        }
        // this.processUpload(CameraSource.Photos);
        this.pickImagesForUpdate();
    }

    uploadFromCamera(): void {
        if (this.platform.is('capacitor')) {
            this.processUpload(CameraSource.Camera);
        } else {
            this.sharedService.presentToast('danger', '', 'Feature not available on current platform.', 'med');
        }
    }

    async presentActionSheet() {
        const actionSheet = this.actionSheetController.create({
            header: 'Add Attachment',
            buttons: [
                {
                    text: 'Take Photo',
                    handler: () => this.uploadFromCamera(),
                    cssClass: 'photo-button'
                },
                {
                    text: 'Existing Photo',
                    handler: () => this.uploadFromGallery(),
                    cssClass: 'photo-button'
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'cancel-button',
                    data: {
                        action: 'cancel'
                    }
                }
            ]
        });

        (await actionSheet).present();
    }

    async previewAttachment(attachment: IAttachment): Promise<void> {
        const createModal = await this.modalController.create({
            component: PreviewAttachmentComponent,
            cssClass: 'min-width-modal',
            showBackdrop: true,
            componentProps: {
                attachment: attachment
            },
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true
        });

        await createModal.present();
        const { data } = await createModal.onDidDismiss();
    }

    async removeAttachment(attachment: IAttachment, partOfSync: boolean = false): Promise<void> {
        if (!partOfSync) {
            await firstValueFrom(this.storage.refFromURL(attachment.images.image_url).delete());
            const index = this.attachments.indexOf(attachment);

            if (index !== -1) {
                this.attachments.splice(index, 1);
    
                if (attachment.id) {
                    await this.attachmentUploadService.deleteAttachment(attachment.id);
                    // this.sharedService.presentToast('primary', 'Attachment has been removed successfully.', 'Success!', 'med');
                }
            } else {
                // this.sharedService.presentToast('danger', 'Attachment Failed to be removed successfully.', 'Failed!', 'med');
            }
        } else {
            const unsyncedIndex = this.unsyncedAttachments.indexOf(attachment);

            if (unsyncedIndex !== -1) {
                this.unsyncedAttachments.splice(unsyncedIndex, 1);
                // this.sharedService.presentToast('primary', 'Attachment has been removed successfully.', 'Success!', 'med');
            } else {
                // this.sharedService.presentToast('danger', 'Attachment Failed to be removed successfully.', 'Failed!', 'med');
            }
        }

    }

    async downloadAttachment(attachment: IAttachment): Promise<void> {
        // TO DO: will need to form base64 from actual returned url
        const url = await firstValueFrom(this.storage.refFromURL(attachment.images.image_url).getDownloadURL());
    }

    getAllAttachments(): any[] {
        return this.attachments.concat(this.unsyncedAttachments);
    }

    async manualSync(id: number, deletedAttachments: any[] = []): Promise<void> {
        const removeUrls = [];
        this.unsyncedAttachments = this.unsyncedAttachments.filter(a => !deletedAttachments.map(d => d.images.image_url).includes(a.images.image_url));

        console.log(JSON.parse(JSON.stringify(this.unsyncedAttachments)), JSON.parse(JSON.stringify(deletedAttachments)));
        console.log(JSON.parse(JSON.stringify(this.unsyncedAttachments)).map(a => a.images.image_url), JSON.parse(JSON.stringify(deletedAttachments)).map(a => a.images.image_url));

        if (this.unsyncedAttachments.length > 0) {
            if (id) {
                this.id = id;
                this.unsyncedAttachments.forEach(downloadURL => {
                    if (this.context === 'report') {
                        this.handleReportUpload(downloadURL.images.image_url);
                        // this.removeAttachment(downloadURL);
                        removeUrls.push(downloadURL);
                    } else if (this.context === 'issue') {
                        this.handleIssueUpload(downloadURL.images.image_url);
                        // this.removeAttachment(downloadURL);
                        removeUrls.push(downloadURL);
                    } else if (this.context === 'reject') {
                        this.handleRejectUpload(downloadURL.images.image_url);
                        // this.removeAttachment(downloadURL, true);
                        removeUrls.push(downloadURL);
                    }
                });

                removeUrls.forEach((url: any) => {
                    this.removeAttachment(url, (this.context === 'reject'));
                });

                this.unsyncedAttachments = [];
                this.imageCount.emit(this.unsyncedAttachments.length);
            }
        }
    }



    async addAttachmentsById(context: string, id: number, idList: number[] = []): Promise<void> {
        if (context === 'issue') {
            const issueAttachments: IAttachment[] = await this.attachmentUploadService.getIssueAttachment(id);
            if (issueAttachments) {
                issueAttachments.forEach((attachment: IAttachment) => {
                    this.attachments.push(attachment);
                });
            }
        }

        if (context === 'reject') {
            const rejectAttachments: IAttachment[] = await this.attachmentUploadService.getRejectAttachment(id);
            if (rejectAttachments) {
                rejectAttachments.forEach((attachment: IAttachment) => {
                    this.attachments.push(attachment);
                });
            }
        }
    }

    // ngOnDestroy(): void {
    //     if (this.unsyncedAttachments.length > 0) {
    //         if (this.id) {
    //             this.unsyncedAttachments.forEach(downloadURL => {
    //                 if (this.context === 'report') {
    //                     this.handleReportUpload(downloadURL);
    //                 } else if (this.context === 'issue') {
    //                     this.handleIssueUpload(downloadURL);
    //                 } else if (this.context === 'reject') {
    //                     this.handleRejectUpload(downloadURL);
    //                 }
    //             });
    //         }
    //     }
    // }

}