<div class="accordion-container">
  <div class="header-container" (click)="toggleHidden()">
    <img
      *ngIf="!hidden"
      class="drop-down-logo"
      src="../../../../../assets/icons/down.svg"
    />
    <img
      *ngIf="hidden"
      class="drop-down-logo"
      src="../../../../../assets/icons/up.svg"
    />
    <h2 [ngStyle]="{'font-size': fontSize + 'px'}" class="accordion-header">{{ title }}</h2>
  </div>
  <div *ngIf="!hidden">
    <ng-content></ng-content>
  </div>
</div>
