import { AccountContact } from "./contact.model";

export class LiaisonReport {
  id?: number;
  daily_activity?: string;
  last_updated?: string;
  date_created?: string;
  production_count: number = 0;
  scrap_count: number = 0;
  is_submitted: boolean;
  deleted: boolean;
  issues_issues_closed_report_idTodaily_reports?: any[] = [];
  issues_issues_initial_report_idTodaily_reports?: any[] = [];
  issue_updates?: any[] = [];
  account_id?: number;
  combine_report: boolean;
  customer_report_status?: any[];
  account_contacts?: AccountContact[]

  constructor(id, daily_activity, last_updated, date_created, production_count, scrap_count, combine_report, deleted?) {

    const currentDate = new Date();

    this.id = id;
    this.daily_activity = daily_activity;
    this.date_created = date_created ?? `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1) < 10 ? '0' : ''}${(currentDate.getMonth() + 1)}-${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}`;
    this.last_updated = last_updated ?? new Date().toISOString();
    this.production_count = production_count;
    this.scrap_count = scrap_count;
    this.is_submitted = false;
    this.combine_report = combine_report ?? false
    this.deleted = deleted ?? false;
  }
}
