import { ILiaisonAccountHistory } from "./liaison-account-history.model";
import { IBusiness } from "./business.model";
import { ILiaisonAccount } from "./liaison-account.model";
import { ILiaisonCrossCoverage } from "./liaison-cross-coverage.model";

export class User {
  id?: number;
  email: string = "";
  payentry_email?: string = "";
  first_name: string = "";
  last_name: string = "";
  role: string = "";
  active: boolean = true;
  address?: string = "";
  city?: string = "";
  state?: string = "";
  postal?: string = "";
  phone_number?: string = "";
  eligible_for_1099: boolean = false;
  manager?: number | User = null;
  country_code?: number = null;
  business_id?: number = null;
  business?: IBusiness = null;
  pay_entry_id?: number = null;
  liaison_account_history?: ILiaisonAccountHistory[] = [];
  liaison_account_cover?: ILiaisonCrossCoverage[] = [];
  country?: string = null;
  address2?: string = null;
  account_creation_push? = false;
  account_update_push? = false;
  customer_creation_push? = false;
  customer_update_push? = false;
  liaison_creation_push? = false;
  liaison_update_push? = false;
  location_creation_push? = false;
  location_update_push? = false;
  contact_creation_push? = false;
  contact_update_push? = false;
  invoice_push? = false;
  time_approval_push? = false;
  message_push? = false;
  email_notifications? = false;
  total?: number;
  inactive_date?: string;
  access_override?: boolean = false;
  constructor(id, email, first_name, last_name, role, active, address, city, state, postal, phone_number, eligible_for_1099, country_code?, manager?, business_id?, payentry_email?) {
    this.id = id;
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
    this.role = role;
    this.active = active;
    this.address = address;
    this.phone_number = phone_number;
    this.eligible_for_1099 = eligible_for_1099;
    this.city = city;
    this.state = state;
    this.postal = postal;
    this.manager = manager ?? null;
    this.country_code = country_code ?? null;
    this.business_id = business_id ?? null;
    this.payentry_email = payentry_email ?? null;
  }
}
