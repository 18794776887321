import { Pipe, PipeTransform } from "@angular/core";
import { IAdditionalTime } from "../../../../../../app/shared/models/additional-time.model";


@Pipe({
  name: "sortByDate",
})
export class SortByDatePipe implements PipeTransform {
    transform(value: IAdditionalTime[], trigger: number): IAdditionalTime[] {
        return value.sort((a, b) => {
            if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
                return 1;
            }
            if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
                return -1;
            }
            
            return 0;
        });

    }
}