<ion-grid name="add-new-location-popup">
    <ion-row class="header-row">
        <ion-col>
            Add New Location
        </ion-col>
        <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
    </ion-row>
    <form [formGroup]="newLocationForm" (ngSubmit)="submitNewLocation()">
        <ion-row class="label-row">
            <ion-label>
                Name *
            </ion-label>
        </ion-row>
        <ion-row class="center-item">
            <ion-col>
                <ion-input name="location-name" formControlName="name" placeholder="Enter name"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row class="label-row web-address" >
            <!-- <ion-col class="ion-no-padding">
                <ion-label>
                    Country *
                </ion-label>
            </ion-col> -->
            <ion-col class="ion-no-padding">
                <ion-label>
                    Address
                </ion-label>
            </ion-col>
            <ion-col class="ion-no-padding">
                <ion-label>
                    {{(newLocationForm.value.country === 'MX') ? 'Neighborhood, Municipality' : 'Apt. Suite Number'}}
                </ion-label>
            </ion-col>
        </ion-row>
        <ion-row class="address-row web-address" >
            <!-- <ion-col>
                <select name="location-country" formControlName="country" [ngClass]="{'text-color-grey': !newLocationForm.value.country}">
                    <option [value]="null" disabled selected>Enter Country</option>
                    <option [ngValue]="'CA'">
                        Canada
                    </option>
                    <option [ngValue]="'KO'">
                        Korea
                    </option>
                    <option [ngValue]="'MX'">
                        Mexico
                    </option>
                    <option [ngValue]="'US'">
                        United States
                    </option>
                </select>
            </ion-col> -->
            <ion-col>
                <ion-input name="location-address" formControlName="address" placeholder="Enter address"></ion-input>
            </ion-col>
            <ion-col>
                <ion-input name="location-address2" formControlName="address2" placeholder="Enter address"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row class="label-row web-address">
            <!-- <ion-col class="ion-no-padding">
                <ion-label>
                    {{(newLocationForm.value.country === 'MX') ? 'Neighborhood, Municipality' : 'Apt. Suite Number'}}
                </ion-label>
            </ion-col> -->
            <ion-col class="md-input-col ion-no-padding">
                <ion-label>
                    City *
                </ion-label>
            </ion-col>
            <ion-col class="md-input-col ion-no-padding">
                <ion-label>
                    {{(newLocationForm.value.country === 'CA') ? 'Province/Territory *' : 'State/Country'}}{{(newLocationForm.value.country === 'US' ? ' *' : '')}}
                </ion-label>
            </ion-col>
            <ion-col class="md-input-col ion-no-padding">
                <ion-label>
                    Postal Code
                </ion-label>
            </ion-col>
        </ion-row>
        <ion-row class="address-row web-address">
            <!-- <ion-col>
                <ion-input name="location-address2" formControlName="address2" placeholder="Enter address"></ion-input>
            </ion-col> -->
            <ion-col class="md-input-col">
                <ion-input name="location-city" formControlName="city" placeholder="Enter city"></ion-input>
            </ion-col>
            <ion-col class="md-input-col">
                <!-- <ion-input name="location-state" formControlName="state" placeholder="Enter state"></ion-input> -->
                <select formControlName="state" placeholder="Enter state">
                    <option [value]="s" *ngFor="let s of stateList">
                        {{s}}
                    </option>
                </select>
            </ion-col>
            <ion-col class="md-input-col">
                <ion-input name="location-postal" formControlName="postal" placeholder="Enter postal"></ion-input>
            </ion-col>
        </ion-row>


        <!-- Switches address row to vertical when screen size is small -->

        <div class="mobile-address">
            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Country
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <select formControlName="country" [ngClass]="{'text-color-grey': !newLocationForm.value.country}">
                                <option [value]="null" disabled selected>Enter Country</option>
                                <option [ngValue]="'CA'">
                                    Canada
                                </option>
                                <option [ngValue]="'KO'">
                                    Korea
                                </option>
                                <option [ngValue]="'MX'">
                                    Mexico
                                </option>
                                <option [ngValue]="'US'">
                                    United States
                                </option>
                            </select>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Address
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input formControlName="address" placeholder="Enter address"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            {{(newLocationForm.value.country === 'MX') ? 'Neighborhood, Municipality' : 'Apt. Suite Number'}}
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input formControlName="address2" placeholder="Enter address"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            City *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input formControlName="city" placeholder="Enter city"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row class="double-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            {{(newLocationForm.value.country === 'CA') ? 'Province/Territory *' : 'State'}}{{(newLocationForm.value.country === 'US' ? ' *' : '')}}
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input formControlName="state" placeholder="Enter state"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-row class="label-row full-width">
                        <ion-label>
                            Postal Code *
                        </ion-label>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input formControlName="postal" placeholder="Enter postal"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </div>

        <ion-row class="label-row">
            <ion-label>
                Notes
            </ion-label>
        </ion-row>
        <ion-row class="center-item">
            <ion-col>
                <ion-textarea name="location-notes" formControlName="notes" placeholder="Enter notes"></ion-textarea>
            </ion-col>
        </ion-row>
        <ion-row class="button-row">
            <ion-col>
                <ion-button name="add-location" [disabled]="!newLocationForm.value.name || !newLocationForm.value.city || (!newLocationForm.value.state)" 
                type="submit">Add Location</ion-button>
            </ion-col>
        </ion-row>
    </form>

</ion-grid>