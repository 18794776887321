<ion-grid>
  <ion-row class="header-row" id="hide-on-camera">
    <ion-col class="header-text">
      <span class="modal-header-text">{{
        activeIssue.id ? "Edit Issue" : "Create Issue"
      }}</span>
    </ion-col>
    <ion-col size="auto">
      <ion-icon
        class="cross"
        (click)="closeModal()"
        name="close-outline"
      ></ion-icon>
    </ion-col>
  </ion-row>

  <ion-row class="description-row" id="hide-on-camera">
    <ion-col>
      <span class="qas-blue label">Description of Nonconformance</span>
      <ion-textarea
        class="description-box"
        [(ngModel)]="activeIssue.description"
        type="text"
        placeholder="Enter description"
        [disabled]="activeIssue?.id && !activeIssue?.active"
        autocomplete=“true” 
        spellcheck=“true”
        autocapitalize="true"
        [ngModelOptions]="{standalone: true}"
        [autoGrow]="true"
        spellcheck="true" 
        autosize 
        rows="1"
      ></ion-textarea>
    </ion-col>
  </ion-row>

  <ion-row class="description-row" id="hide-on-camera">
    <ion-col>
      <span class="qas-blue label">Impacted Part Number/Part Description</span>
      <ion-textarea
        class="description-box"
        [(ngModel)]="activeIssue.impacted_parts"
        type="text"
        placeholder="Enter impacted part number/part description"
        [disabled]="activeIssue?.id && !activeIssue?.active"
        autocomplete=“true” 
        spellcheck=“true”
        autocapitalize="true"
        [ngModelOptions]="{standalone: true}"
        [autoGrow]="true"
        spellcheck="true" 
        autosize 
        rows="1"
      ></ion-textarea>
    </ion-col>
  </ion-row>


  <ion-row id="hide-on-camera" [ngClass]="{'total-parts-row': activeIssue?.id,'total-parts-row-create': !activeIssue?.id}">
    <ion-col>
      <span [ngClass]="{'label': activeIssue?.id,'label-aligned': !activeIssue?.id}" class="qas-blue">
        {{activeIssue?.id ? 'Total Nonconformance Parts' : 'Number of Nonconforming Parts'}}
      </span>
      <ion-input
        [disabled]="activeIssue?.id"
        placeholder="Enter # of parts"
        [(ngModel)]="activeIssue.parts_found"
        type="number"
      ></ion-input>
    </ion-col>
  </ion-row>

  <ng-container *ngIf="!activeIssue?.id">
    <ion-row class="action-taken-row" id="hide-on-camera">
      <ion-col>
        <span class="qas-blue label">Area in Plant Where Found</span>
        <ion-input
        class="action-taken"
        placeholder="Enter area where found"
        [(ngModel)]="areaFound"
        type="text"
      ></ion-input>
      </ion-col>
    </ion-row>
  </ng-container>

  <ion-row class="concern-row" id="hide-on-camera">
    <ion-col>
      <ng-container>
        <span class="qas-blue label">Concern Written</span>
        <ion-input
          placeholder="Enter concern Written"
          [disabled]="!activeIssue.concern"
          class="concern-written-input"
          [(ngModel)]="activeIssue.concern_written"
          type="text"
        >
        <select (change)="activeIssueChange($event)" [(ngModel)]="activeIssue.concern" [ngStyle]="{ color: (activeIssue.concern ? 'red' : '#fbbc05') }" [disabled]="activeIssue?.id && !activeIssue?.active">
          <option [ngValue]="true">
            <span><span>&#x26A0;</span> Yes</span>
          </option>
          <option [ngValue]="false">
            <span><span>&#x26A0;</span> No</span>
          </option>
        </select>
        </ion-input>
      </ng-container>
    </ion-col>
  </ion-row>

  <ion-row class="description-row" id="hide-on-camera" *ngIf="!activeIssue?.id">
    <ion-col>
      <span class="qas-blue label">Action Taken</span>
      <ion-textarea
        class="description-box"
        [(ngModel)]="actionTaken"
        type="text"
        placeholder="Enter action taken"
        autocomplete=“true” 
        spellcheck=“true”
        autocapitalize="true"
        [ngModelOptions]="{standalone: true}"
        [autoGrow]="true"
        spellcheck="true" 
        autosize 
        rows="1"
      ></ion-textarea>
    </ion-col>
  </ion-row>

  <!-- <ng-container *ngIf="!activeIssue?.id">
    <ion-row class="action-taken-row" id="hide-on-camera">
      <ion-col>
        <span class="qas-blue label">Action Taken</span>
        <ion-input
        class="action-taken"
        placeholder="Enter action taken"
        [(ngModel)]="actionTaken"
        type="text"
      ></ion-input>
      </ion-col>
    </ion-row>
  </ng-container> -->

  <ng-container *ngIf="activeIssue?.id">
    <ion-row class="updates-row" id="hide-on-camera">
      <ion-col class="container-col">
        <span class="qas-blue label update-label">Updates</span>
        <div class="updates-container">
          <ion-row *ngFor="let updateGroup of groupedUpdates">
            <ion-col class="ion-no-padding">
              <ion-row class="update-date">
                <ion-col class="ion-no-padding">
                  {{updateGroup.date | date: 'fullDate'}}
                </ion-col>
              </ion-row>
              <ion-row class="update-group-row" *ngFor="let update of updateGroup.updates">
                <ion-col class="ion-no-padding">
                  <ion-row *ngIf="update.content">
                    <ion-col class="ion-no-padding">
                      <span>Action Taken: </span>{{update.content}}
                    </ion-col>
                  </ion-row>
                  <ion-row class="update-group-sub-text" *ngIf="update.parts_found">
                    <ion-col class="ion-no-padding">
                      <span>Number of Nonconforming Parts: </span>{{update.parts_found}}
                    </ion-col>
                  </ion-row>
                  <ion-row class="update-group-sub-text" *ngIf="update.area_found">
                    <ion-col class="ion-no-padding">
                      <span>Area in Plant Where Found: </span>{{update.area_found}}
                    </ion-col>
                  </ion-row>
                  <ion-row class="update-group-sub-text" *ngIf="update.issue_serial_numbers && (update.issue_serial_numbers.length > 0)">
                    <ion-col class="ion-no-padding">
                      <span>Traceability Barcodes: </span>{{getTraceabilityBarcodes(update)}}
                    </ion-col>
                  </ion-row>
                  <ion-row class="update-group-sub-text" *ngIf="update.clean_point_required">
                    <ion-col class="ion-no-padding">
                      <span>Clean Point Date: </span>{{update.clean_point_date | date: 'shortDate'}}
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </div>
      </ion-col>
    </ion-row>
    <ion-row [ngStyle]="{'opacity': !isWritingUpdate ? 1 : .8}" class="add-update" id="hide-on-camera">
      <ion-col class="update">
        <div (click)="addUpdate()">
          <span>+ Add Update</span>
        </div>
      </ion-col>
      <ion-col *ngIf="activeIssue?.id" class="close">
        <button *ngIf="activeIssue?.active" (click)="setActiveFlag(0)" class="close-issue-button">Close Issue</button>
        <button *ngIf="!activeIssue?.active" (click)="setActiveFlag(1)" class="open-issue-button">Open Issue</button>
      </ion-col> 
    </ion-row>
  </ng-container>

  <div class="upload-container" id="hide-on-camera">
    <ion-row id="hide-on-camera">
      <ion-col>
        <!-- Start Photo Upload Area -->
        <app-attachment-upload
        #attachmentUpload
        [condensed]="true"
        [id]="activeIssue.id"
        [isDisabled]="activeIssue?.id && !activeIssue?.active"
        [context]="'issue'"
        [startHidden]="true"
        [showButtons]="!activeIssue?.id"
        [showAttachmentCount]="true"
        [paddingAmount]="'10px'"
        [openAccordionOnUpload]="activeIssue?.id"
        [slimUploadButton]="true"
        [onlyShowButtonOnZeroAttachments]="!activeIssue?.id"
      ></app-attachment-upload>
      <!-- End Photo Upload Area -->
      </ion-col>
      <ion-col *ngIf="!activeIssue?.id">
        <app-barcode-scanner (barcodeScanEvent)="onBarcodeScan($event)" [useDefaultButton]="true"></app-barcode-scanner>
      </ion-col>
    </ion-row>
  </div>

  <div id="hide-on-camera" *ngIf="!activeIssue?.id && (serialNumbers?.length > 0)">
    <accordion #barcodeAccordion [fontSize]="14" [title]="'Traceability Barcodes (' + (serialNumbers?.length ?? 0) + ')'" [hidden]="true">
      <ion-row class="scan-row" id="hide-on-camera">
        <ion-col>
          <div class="traceability-info">
            <div *ngFor="let serialNumber of serialNumbers">
              <span>{{serialNumber}}</span>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </accordion>
  </div>

  <ion-row class="button-row" id="hide-on-camera">
    <ion-col>
      <button class="save-button" (click)="addOrUpdateIssue()"
      [disabled]="(activeIssue?.id && !activeIssue?.active) || creatingIssueInProgress">{{
        activeIssue.id ? "Save Issue" : "Create Issue"
      }}</button>
    </ion-col>
  </ion-row>
</ion-grid>
 