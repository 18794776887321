import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updateFilter'
})
export class UpdateFilterPipe implements PipeTransform {

    transform(items: any[], count: number): any[] {
        // return empty array if array is falsy
        if (!items) { return []; }

        // sort array so the selected defects go on top
        return items.filter(i => !i.dontShow);
    }
}