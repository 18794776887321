<ion-grid>
    <ng-container *ngIf="initialLoad && loaded">
        <div>
            <ion-row class="initial-load-header">
                <ion-col class="account-title">
                    Accounts
                </ion-col>
                <ion-col class="close-button" size="auto">
                    <ion-icon class="cross sm-cross" (click)="closeModal()" name="close-outline"></ion-icon>
                </ion-col>
            </ion-row>

            <div class="account-scroll-container">
                <ion-row class="discrepancy-account-row" (click)="selectAccount(account)" *ngFor="let account of accounts">
                    <ion-col>
                        {{account.name}} - {{account?.qbo_name ?? account?.locations?.name}}
                    </ion-col>
                </ion-row>

                <ion-row class="all-account-row" (click)="selectAccount(account)" *ngFor="let account of allAccounts">
                    <ion-col>
                        {{account.name}} - {{account?.qbo_name ?? account?.locations?.name}}
                    </ion-col>
                </ion-row>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!initialLoad">
        <ion-row class="header-row">
            <ion-col>
                <select *ngIf="sharedService.isNative()" [(ngModel)]="selectedAccount" (change)="accountUpdated()">
                    <ng-container *ngIf="accounts && ((accounts.length > 0) || allAccounts.length > 0)">
                        <option *ngIf="accounts.length > 0" [disabled]="true">
                            Accounts With Discrepancies
                        </option>
                        <option class="red-option" [value]="account.id" *ngFor="let account of accounts">
                            {{account.name}} - {{account?.qbo_name ?? account?.locations?.name}}
                        </option>
                        <option *ngIf="allAccounts.length > 0" [disabled]="true">
                            All Accounts
                        </option>
                        <option [value]="account.id" *ngFor="let account of allAccounts">
                            {{account.name}} - {{account?.qbo_name ?? account?.locations?.name}}
                        </option>
                    </ng-container>
                </select>
                <ng-select placeholder="Select Account" *ngIf="!sharedService.isNative()" appendTo="body" class="ng-select-no-border" [(ngModel)]="selectedAccount" (change)="accountUpdated()">
                    <ng-container *ngIf="accounts && ((accounts.length > 0) || allAccounts.length > 0)">
                        <ng-option *ngIf="accounts.length > 0" [disabled]="true">
                            Accounts With Discrepancies
                        </ng-option>
                        <ng-option [value]="account.id" *ngFor="let account of accounts">
                            <div class="red-text">{{account.name}} - {{account?.qbo_name ?? account?.locations?.name}}</div>
                        </ng-option>
                        <ng-option *ngIf="allAccounts.length > 0"  [disabled]="true">
                            All Accounts
                        </ng-option>
                        <ng-option [value]="account.id" *ngFor="let account of allAccounts">
                            {{account.name}} - {{account?.qbo_name ?? account?.locations?.name}}
                        </ng-option>
                    </ng-container>
                </ng-select>
            </ion-col>
            <ion-col class="close-button" size="auto">
                <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
            </ion-col>
        </ion-row>
        <div class="form-container" *ngIf="loaded && ((accounts.length > 0) || allAccounts.length > 0) && selectedAccount">
    
            <ng-container *ngIf="!accountLoading">
                <!-- LIAISON SECTION -->
                <div>
        
                    <div class="liaison-scroll">
            
                        <ion-row class="liaison-header-row liaison-row">
                            <ion-col class="name-col">
                                Name
                            </ion-col>
                            <ion-col class="data-col">
                                Pay Rate
                            </ion-col>
                            <ion-col class="data-col">
                                Base Hours
                            </ion-col>
                            <ion-col class="data-col">
                                <div class="show-inactive">
                                    <ion-toggle (ionChange)="showInactiveChange()" [(ngModel)]="showInactiveLiaisonAccount"></ion-toggle>
                                    <span>Show Inactive</span>
                                </div>
                            </ion-col>
                        </ion-row>

                        <ion-row class="liaison-row" *ngFor="let liaison of liaisonAccounts" 
                        [ngClass]="{'inactive-liaison-row': !sharedService.isActiveLiaisonAccount(liaison)}">
                            <ion-col class="name-col">
                                {{liaison.users.first_name}} {{liaison.users.last_name}}
                            </ion-col>
                            <ion-col class="data-col">
                                {{(liaison.pay_rate_value ?? 0) | currency:'USD'}}
                            </ion-col>
                            <ion-col class="data-col">
                                {{liaison.base_hours_value ?? 0}}
                            </ion-col>
                            <ion-col class="data-col">
                                <div (click)="editLiaisonList(liaison)" class="edit-button">Edit</div>
                            </ion-col>
                        </ion-row>

                        <ion-row class="add-liaison-row">
                            <ion-col>
                                <div (click)="addNewLiaison()">+ Liaison</div>
                            </ion-col>
                            <ion-col class="total-hours-text">
                                Total hours: {{totalBaseHours}}
                            </ion-col>
                        </ion-row>
        
                    </div>
        
                    <ion-row class="top-liaison-data">
                        <ion-col class="ion-no-padding">
                            <div>
                                <span><ng-container *ngIf="getCurrentInvoiceRateAndHours().isUpcoming">Upcoming</ng-container
                                    ><ng-container *ngIf="!getCurrentInvoiceRateAndHours().isUpcoming">Current</ng-container> 
                                    Contracted Hours: {{getCurrentInvoiceRateAndHours()?.hours}}</span>
                            </div>
                            <div *ngIf="sharedService.getAuthorizationStatus()" class="invoice-rate">
                                <span><ng-container *ngIf="getCurrentInvoiceRateAndHours().isUpcoming">Upcoming</ng-container>
                                    <ng-container *ngIf="!getCurrentInvoiceRateAndHours().isUpcoming">Current</ng-container> 
                                    Invoice Rate: {{(getCurrentInvoiceRateAndHours()?.invoiceRate ?? 0) | currency:'USD'}}</span>
                            </div>
                        </ion-col>
                    </ion-row>
        
                    <div class="hours-changing-section">
                        <div class="discrepancies-text" *ngIf="discrepancyList?.length > 0">
                            <span>Contracted hours discrepancies found</span>
                        </div>
                        <div class="discrepancies-timeline" *ngFor="let history of discrepancyList">
                            <div class="date">
                                <span>{{history.start}} - {{history.end ?? 'Future'}}</span>
                            </div>
                            <div class="value">
                                <span>Contracted hours is {{history.accountTotal}} and assigned hours is {{history.liaisonTotal}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="accountLoading">
                <div class="liaison-account-loading-container">
                    <ion-spinner></ion-spinner>
                </div>
            </ng-container>
    
        </div>
    
        <div *ngIf="loaded && ((accounts.length > 0) || allAccounts.length > 0) && !selectedAccount" class="loading">
            <ion-row>
                <ion-col>
                    <span>Select Account</span>
                </ion-col>
            </ion-row>
        </div>
    </ng-container>

    <div *ngIf="!loaded" class="loading">
        <ion-row>
            <ion-col>
                <ion-spinner></ion-spinner>
            </ion-col>
        </ion-row>
    </div>
</ion-grid>