import { Component, HostListener, Input, OnInit } from "@angular/core";
import { AuthService } from "../../../../services/auth.service";
import { INav } from "../../../../../../app/features/admin/pages/home/models/nav.model";
import { NavigationStart, Router } from "@angular/router";
import { ModalController } from "@ionic/angular";


@Component({
  selector: "app-mobile-nav",
  templateUrl: "./mobile-nav.component.html",
  styleUrls: ["./mobile-nav.component.scss"],
})

export class MobileNavComponent implements OnInit {

    options: INav[] = [];

    constructor(
        private authService: AuthService,
        private router: Router,
        private modalController: ModalController
    ) {

    }

    ngOnInit(): void {

    }

    routeTo(route: string): void {
        this.router.navigate([route]);
        this.modalController.dismiss();
    }

}