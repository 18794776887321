import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { IAdditionalTime } from "../../../../../../../app/shared/models/additional-time.model";
import { ManualApprovalComponent } from "../manual-approval/manual-approval.component";
import { LiaisonStateService } from "../../../../../../../app/features/liaison/services/liaison-state-service";
import { AdminApiService } from "../../../../../../../app/features/admin/services/admin-api-service";
import { SharedService } from "../../../../../../../app/shared/services/shared.service";
import { EditApprovalComponent } from "../edit-approval/edit-approval.component";

@Component({
  selector: "pending-hours-modal",
  templateUrl: "pending-hours.component.html",
  styleUrls: ["./pending-hours.component.scss"],
  providers: [AdminApiService, LiaisonStateService]
})
export class PendingHoursComponent implements OnInit {

    trigger: number = 0;
    additionalTimeArray: IAdditionalTime[] = [];

    constructor(
        private modalController: ModalController,
        private liaisonStateService: LiaisonStateService,
        private adminApiService: AdminApiService,
        private sharedService: SharedService
    ) {

    }

    ngOnInit(): void {
        
    }

    async approveAdditionalTime(additionalTime: IAdditionalTime): Promise<void> {
      const createModal = await this.modalController.create({
        component: ManualApprovalComponent,
        cssClass: "min-width-modal grey-background",
        showBackdrop: true,
        componentProps: {
          additionalTime: additionalTime
        },
        backdropDismiss: true,
        keyboardClose: true,
        swipeToClose: true,
      });
  
      await createModal.present();
      const { data } = await createModal.onDidDismiss();
  
      if (data) {
        additionalTime.approval_method = data.method;
        additionalTime.approver_name = data.name;
        additionalTime.approval_date = data.date;
        this.approveTime(additionalTime);
      }
    }

    async approveTime(additionalTime: IAdditionalTime): Promise<void> {
      additionalTime.approved = true;
      additionalTime.approved_by = this.liaisonStateService.getUserId();
      additionalTime.rejected = false;
        
      const newAdditionalTime: IAdditionalTime = await this.adminApiService.updateAdditionalHours(additionalTime);
  
      this.sharedService.presentToast('primary', 'Additional hours request has been approved successfully.', 'Success', 'short');
    }

    async editAdditionalHours(additionalTime: IAdditionalTime): Promise<void> {
      const createModal = await this.modalController.create({
        component: EditApprovalComponent,
        cssClass: "min-width-modal grey-background",
        showBackdrop: true,
        componentProps: {
          additionalTime: additionalTime
        },
        backdropDismiss: true,
        keyboardClose: true,
        swipeToClose: true,
      });
    
      await createModal.present();
      const { data } = await createModal.onDidDismiss();
    
      if (data) {
        const removeData = this.additionalTimeArray.find(a => a.id === data.remove);
        const index = this.additionalTimeArray.indexOf(removeData);
        this.additionalTimeArray.splice(index, 1);
        if (data.add) {
          this.additionalTimeArray.push(data.add);
        }
      }
    }

    close(): void {
      this.modalController.dismiss(this.additionalTimeArray);
    }

}