import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IonInput, ViewWillEnter } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { AdminApiService } from '../../services/admin-api-service';
import { IAccount } from '../../../../../app/shared/models/account.model';
import { ILocation } from '../../../../../app/shared/models/location.model';
import { ICustomer } from '../../../../../app/shared/models/customer.model';
import { ViewChild } from '@angular/core';
import { ViewDidEnter } from '@ionic/angular';

import { NewContactPopupComponent } from '../../pages/contacts/components/new-contact/new-contact.component';
import { NewCustomerPopupComponent } from '../../pages/customer/components/new-customer/new-customer.component';
import { NewLocationPopupComponent } from '../../pages/locations/components/new-location/new-location.component';
import { NewUserPopupComponent } from '../../pages/liaison-setup/components/new-liaison/new-liaison.component';
import { ILiaisonAccount } from '../../../../../app/shared/models/liaison-account.model';
import { User } from '../../../../../app/shared/models/user.model';
import { AccountContact, CustomerContact } from '../../../../../app/shared/models/contact.model';
import { Contact } from '../../../../../app/shared/models/contact.model';
import { SharedService } from '../../../../../app/shared/services/shared.service';
import { OvertimeRulesPopupComponent } from '../overtime-rules/overtime-rules.component';
import { IOvertimeRules } from '../overtime-rules/overtime-rules.component';
import { CurrencyPipe } from '@angular/common';

import { ChangeDetectorRef } from '@angular/core';
import { ILiaisonAccountHistory } from '../../../../shared/models/liaison-account-history.model';
import { OVERTIMEOPTIONS } from '../../../../../app/shared/models/liaison-account.model';
import { IAccountHistory } from '../../../../../app/shared/models/account-history.model';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AssignLiaisonHistoryComponent } from '../../pages/liaison-details/components/assign-liaison-history/assign-liaison-history.component';
import { AssignLiaisonNewAccountComponent } from '../../pages/liaison-details/components/assign-liaison-new-account/assign-liaison-new-account.component';

enum Tabs {
    CUSTOMER = 1,
    LOCATION = 2,
    ACCOUNT = 3,
    LIAISON = 4,
    CONTACTS = 5,
    DONE = 6
};

@Component({
    selector: 'new-account-walkthrough-popup-component',
    templateUrl: 'new-account-walkthrough.component.html',
    styleUrls:['./new-account-walkthrough.component.scss'],
    providers:[AdminApiService, CurrencyPipe]
})

export class NewAccountWalkthroughPopupComponent implements ViewWillEnter, OnInit {

    @ViewChild('rate') rateElement: IonInput;

    OVERTIMEOPTIONS = OVERTIMEOPTIONS;

    passedInCustomer: number = null;

    Tabs = Tabs;

    activeTab = Tabs.CUSTOMER;

    name: string = null;
    qbo_name: string = null;
    customer: number = null;
    prevCustomer: number = null;
    prevLocation: number = null;
    location: number = null;

    locations: ILocation[] = [];
    customers: ICustomer[] = [];

    contacts: Contact[] = [];
    assignedContacts: AccountContact[] = [];
    manualTriggerContact: number = 0;
    trigger: number = 0;

    liaisons: User[] = [];
    allLiaison: User[] = [];
    assignedLiaisons: ILiaisonAccount[] = [];
    liaisonAccountHistory: ILiaisonAccountHistory[] = [];
    manualTrigger: number = 0;

    history: IAccountHistory[] = [];

    selectedData: IAccountHistory = null;

    refreshCurrencyInput: boolean = false;

    processing: boolean = false;

    program: string = null;
    productDesc: string = null;
    poNumber: string = null;

    totalBaseHours: number = 0;

    constructor(
        private modalCtrl: ModalController,
        private adminApiService: AdminApiService,
        private sharedService: SharedService,
        private cdr: ChangeDetectorRef,
        private currencyPipe: CurrencyPipe,
        private router: Router,
        private alertController: AlertController
    ) {

    }

    async ngOnInit(): Promise<void> {
        if ((this.locations.length <= 0) && (this.customers.length <= 0)) {
            const promises: any[] = [];

            promises.push(this.getCustomers());
            promises.push(this.getLocations());
            promises.push(this.getLiaisons());

            await Promise.all(promises);

            if (this.passedInCustomer) {
                this.customer = this.passedInCustomer;
                this.customerUpdate();
            }
        }
    }

    omitSemiColon(e: any): void {
        if (e.key === ';') {
            e.preventDefault();
        }
    }

    async customerUpdate(): Promise<void> {
        if (!this.prevCustomer || (this.prevCustomer !== this.customer)) {
            const selectedCustomer = this.customers.find(c => c.id === this.customer);
            this.name = {...selectedCustomer}?.company_name;
            await this.getContacts();

            this.prevCustomer = this.customer;
        }
    }

    async locationUpdated(): Promise<void> {
        if (!this.prevLocation || (this.prevLocation !== this.location)) {
            const selectedLocation = this.locations.find(l => l.id === this.location);
            this.qbo_name = {...selectedLocation}?.name;

            this.prevLocation = this.location;
        }
    }


    async getCustomers(): Promise<void> {
        this.customers = (await this.adminApiService.getCustomers())?.filter(c => c.is_active).sort((a, b) => {
            if (a.company_name > b.company_name) {
                return 1;
            }

            if (a.company_name < b.company_name) {
                return -1;
            }

            return 0;
        });
    }
    
    async getLocations(): Promise<void> {
        this.locations = (await this.adminApiService.getLocations())?.filter(l => l.active).sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }

            if (a.name < b.name) {
                return -1;
            }

            return 0;
        });
    }

    async getLiaisons(): Promise<void> {
        this.allLiaison = (await this.adminApiService.getUsers('liaison'));
        this.liaisons = this.sharedService.sortFirstNameLastName<User>(this.allLiaison?.filter(l => l.active));
    }

    async getContacts(): Promise<void> {
        this.contacts = this.sharedService.sortFirstNameLastName<Contact>((await this.adminApiService.getCustomerContacts(this.customer)).map(c => c.contacts));
    }

    getLiaisonDropdown(userId: number): User[] {
        return this.sharedService.sortFirstNameLastName<User>(this.liaisons.filter(i => (!this.assignedLiaisons.map(r => r.user_id).includes(i.id)) || (i.id === userId)));
    }

    ionViewWillEnter(): void {
        
    }

    primaryContactUpdated(event, contact: AccountContact): void {
        if (event.detail.checked) {
            this.assignedContacts.forEach(c => {
                if (c.contact_id !== contact.contact_id) {
                    c.primary_contact = false;
                } else {
                    c.primary_contact = true;
                }
            });
        }
    }

    async createNewCustomer(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: NewCustomerPopupComponent,
            componentProps: {

            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            await this.getCustomers();

            this.customer = data?.id;
            this.customerUpdate();
        }
    }

    async createNewLocation(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: NewLocationPopupComponent,
            componentProps: {

            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            await this.getLocations();

            this.location = data?.id;
            this.locationUpdated();
        }
    }

    async addNewLiaison(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: AssignLiaisonNewAccountComponent,
            componentProps: {
                selectUser: true,
                initialAccountId: 0,
                excludeUserIdList: this.assignedLiaisons.map(l => l.user_id),
                returnData: true
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            setTimeout(() => {
                console.log(data)
                
                if (data.liaisonAccount) {
                    data.liaisonAccount.users = data.user;
                    data.liaisonHistory.liaison = data.user?.id;
                    data.liaisonAccount.pay_rate = data.liaisonHistory.rate;
                    data.liaisonAccount.base_hours = data.liaisonHistory.hours;

                    this.assignedLiaisons.push(data.liaisonAccount);
                    this.liaisonAccountHistory.push(data.liaisonHistory);

                    this.totalBaseHours += data.liaisonHistory.hours;
                }
            }, 0);
        }
    }

    async createNewContact(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: NewContactPopupComponent,
            componentProps: {
                showCustomerOptions: true
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const {data} = await modal.onDidDismiss();
      
        if (data) {
            const contact = new CustomerContact(null, data.contact.id, this.customer,
                data.values.primaryApprover, data.values.approver, data.values.accountsPayable, data.values.payable, data.values.notes,
                data.values.role, data.values.job_title, data.values.receive_invoice, data.values.app_access, data.values.all_account_access);

            await this.adminApiService.addCustomerContact(contact);

            await this.getContacts();

            contact.contact_id = data.contact.id;

            if (this.assignedContacts.length > 0) {
                this.assignedContacts[0].contact_id = data.contact.id;
            }
        }
    }

    updateManualTrigger(): void {
        this.manualTrigger++;
    }

    refreshPipe(): void {
        this.trigger++;

        this.setEndDate();
    }

    addContactRow() {
        const newAssigned: AccountContact = {
            contact_id: null,
            account_id: null,
            primary_contact: false
        }

        this.assignedContacts.push(newAssigned);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTriggerContact++;
    }

    removeContactRow(row: AccountContact): void {
        const index = this.assignedContacts.indexOf(row);
        this.assignedContacts.splice(index, 1);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTriggerContact++;
    }

    addLiaisonRow() {
        const newAssigned: ILiaisonAccount = {
            account_id: null,
            base_hours: null,
            pay_rate: null,
            user_id: null
        }

        this.assignedLiaisons.push(newAssigned);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTrigger++;
    }

    removeLiaisonRow(row: ILiaisonAccount): void {
        const index = this.assignedLiaisons.indexOf(row);
        this.assignedLiaisons.splice(index, 1);

        // Triggers an update on filter pipe so it updates list of available reps
        this.manualTrigger++;
    }

    async finish(): Promise<void> {
        this.processing = true;
        const promises = [];
        const selectedCustomer = this.customers.find(c => c.id === this.customer);

        var hasUnfinishedData = false;

        const newAccount: IAccount = {
            name: this.name ?? selectedCustomer?.company_name,
            customer_id: this.customer,
            location_id: this.location,
            active: true,
            product_description: this.productDesc,
            program: this.program,
            qbo_name: this.qbo_name,
            po_number: this.poNumber
        };

        if (!this.accountHistoryValid()) {
            this.sharedService.presentToast('danger', 'Invalid data exist in account history.', 'Failed!', 'long');
            this.processing = false;
            return;
        }

        var account: IAccount = null;

        try {
            account = await this.adminApiService.addAccount(newAccount);
        } catch (error) {
            if (error.error === 'Quickbooks failed to sync') {
                this.sharedService.presentToast('danger', 'Quickbooks failed to process customer. This may be caused by quickbooks not being synced with the QAS server.', 'Failed!', 'long');
            } else if (error.error === 'Quickbooks customer exists') {
                this.sharedService.presentToast('danger', 'Quickbooks failed to process customer. This may be caused by a duplicate customer name.', 'Failed!', 'long');
            } else if (error.error === 'Quickbooks failed to inactivate') {
                this.sharedService.presentToast('danger', 'The Quickbooks balance for this account is greater than 0 and cannot be inactivated until resolved.', 'Failed!', 'long');
            } else {
                this.sharedService.presentToast('danger', 'Account was not added successfully.', 'Failed!', 'med');
            }
        }

        if (!account) {
            this.processing = false;
            return;
        }

        this.assignedLiaisons.forEach(async a => {
            a.account_id = account?.id;
            if (a.base_hours && a.pay_rate && a.user_id) {
                const accountLiaison = await this.adminApiService.addLiaisonAccount(a);
                

                const history: ILiaisonAccountHistory = {
                    id: null,
                    liaison: accountLiaison.user_id,
                    liaison_account: accountLiaison.id,
                    rate: a.pay_rate,
                    hours: a.base_hours,
                    start_date: this.liaisonAccountHistory.find(l => l.liaison === accountLiaison.user_id)?.start_date ?? new Date(),
                    end_date: null
                };

                promises.push(this.adminApiService.postHistoryByLiaisonAccount(history));
            } else {
                if (a.base_hours || a.pay_rate || a.user_id) {
                    hasUnfinishedData = true;
                }
            }
        });

        this.assignedContacts.forEach(c => {
            c.account_id = account.id;
            if (c.contact_id) {
                c.primary_contact = true;
                promises.push(this.adminApiService.addAccountContact(c));
            }
        });

        this.history.forEach((b: IAccountHistory) => {
            if (b.id === null) {
                b.account_id = account.id;
                promises.push(this.adminApiService.postAccountHistory(b));
            }
        });

        try {
            await Promise.all(promises);
        } catch (error) {
            this.sharedService.presentToast('danger', '', 'An Unknown Error Occurred!', 'med');
        }

        if (hasUnfinishedData) {
            this.sharedService.presentToast('warning', 'One or more liaisons failed to save due to incomplete data. Please fill out all liaison feilds.'
            , 'Account was created!', 'long');
        } else {
            this.sharedService.presentToast('primary', '', 'Account was created!', 'short');
        }

        this.processing = false;

        setTimeout(() => {
            // If not on customer screen navigate to screen
            if (!this.router.url.includes('customer-details')) {
                this.router.navigate([`/admin/customer-details/${this.customer}`]);
            }
        }, 500);
 
        this.closeModal(true);
    }

    accountHistoryValid(): boolean {
        return (this.history.length > 0) && !(this.history.map(h => h.invoice_rate).includes(null)) &&
        !(this.history.map(h => h.invoice_rate).includes(0)) && !(this.history.map(h => h.contracted_hours).includes(null));
    }

    next(): void {
        this.activeTab++;

        if ((this.history.length === 0) && (this.activeTab === Tabs.ACCOUNT)) {
            this.addNew();
        }

        if ((this.assignedContacts.length === 0 && (this.activeTab === Tabs.CONTACTS))) {
            this.addContactRow();
        }
    }

    back(): void {
        if (this.activeTab > 1) {
            this.activeTab--;
        }

        if ((this.history.length === 0) && (this.activeTab === Tabs.ACCOUNT)) {
            this.addNew();
        }

        if ((this.assignedContacts.length === 0 && (this.activeTab === Tabs.CONTACTS))) {
            this.addContactRow();
        }
    }

    updateActiveTab(tab: Tabs): void {
        switch (tab) {
            case Tabs.CONTACTS:
                this.activeTab = tab;
                if (this.assignedContacts.length === 0) {
                    this.addContactRow();
                }
                break;
            case Tabs.ACCOUNT:
                this.activeTab = tab;
                if (this.history.length === 0) {
                    this.addNew();
                }
                break;
            default:
                this.activeTab = tab;
                break;
        }
    }

    setData(data: IAccountHistory): void {
        this.refreshCurrencyInput = true;
        this.selectedData = data;
        
        setTimeout(() => {
            this.refreshCurrencyInput = false;
        }, 0);
    }

    async addNew(): Promise<void> {

        var startDate: Date = new Date(new Date().setHours(0, 0, 0, 0));

        var prev: IAccountHistory = null;

        if (this.history.length > 0) {
            prev = this.history.sort((a, b) => {
                if (a.start_date > b.start_date) {
                    return -1;
                }
                if (a.start_date < b.start_date) {
                    return 1;
                }
                
                return 0;
            })[0];

            const localStartDate = new Date(new Date(prev?.start_date).setHours(24, 0, 0, 0));
            const localDate = new Date(new Date().setHours(0,0,0,0));

            if ((localStartDate >= localDate) && !prev?.end_date) {
                startDate = new Date(localStartDate.setDate(localStartDate.getDate() + 1));
            } else {
                startDate = prev?.end_date ? new Date(prev?.end_date) : new Date();
            }
        }

        const newHistory: IAccountHistory = {
            id: null,
            account_id: prev?.account_id ?? null,
            invoice_rate: prev?.invoice_rate ?? null,
            contracted_hours: prev?.contracted_hours ?? null,
            has_overtime: prev?.has_overtime ?? false,
            overtime_holidays: prev?.overtime_holidays ?? false,
            overtime_hours: prev?.overtime_hours ?? 40,
            overtime_option: prev?.overtime_option ?? OVERTIMEOPTIONS.WEEKLY,
            overtime_weekends: prev?.overtime_weekends ?? false,
            doubletime_holidays: prev?.doubletime_holidays ?? false,
            doubletime_sundays: prev?.doubletime_sundays ?? false,
            signed_contract: prev?.signed_contract ?? false,
            start_date: this.sharedService.getDefaultDate(prev ? startDate.toISOString() : startDate.toISOString().split('T')[0]) as any,
            end_date: null
        };

        this.history.push(newHistory);

        this.setEndDate();

        this.setData(newHistory);
    }

    setEndDate(): void {
        var previous: IAccountHistory = null;
        var next: IAccountHistory = null;

        this.history.sort((a, b) => {
            if (a.start_date > b.start_date) {
                return -1;
            }
            if (a.start_date < b.start_date) {
                return 1;
            }
            
            return 0;
        }).forEach((b: IAccountHistory) => {
            if (!previous) {
                b.end_date = (b.end_date && ((b.end_date as any) !== 'NaN-NaN-NaN')) ? b.end_date : null;

                if (b.end_date && (new Date(b.end_date) < new Date(b.start_date))) {
                    (b.end_date as any) = this.sharedService.getDefaultDate(new Date(new Date(b.start_date).setDate(new Date(b.start_date).getDate() + 1)).toISOString());
                }
            } else {
                b.end_date = previous.start_date;

                if (b.end_date && (new Date(b.end_date) < new Date(b.start_date))) {
                    (b.end_date as any) = this.sharedService.getDefaultDate(new Date(new Date(b.start_date).setDate(new Date(b.start_date).getDate() + 1)).toISOString());
                }
            }

            previous = b;
        });
    }

    isNewestEntry(entry: IAccountHistory): boolean {
        var isNewest: boolean = true;

        this.history.forEach(h => {
            if (h.start_date > entry.start_date) {
                isNewest = false;
            }
        });

        return isNewest;
    }

    async remove(data: IAccountHistory): Promise<void> {
        const index = this.history.indexOf(data);
        this.history.splice(index, 1);

        this.setEndDate();

        if (this.history?.length > 0) {
            this.setData(this.history[0]);
        }
    }

    async checkForQualityContacts(): Promise<void> {
        if (!this.contacts || (this.contacts?.length <= 0)) {
            const alert = await this.alertController.create({
                header: `No Customer Contacts Found`,
                message: `Would you like to create a new contact?`,
                cssClass: 'custom-alert alert-button large-max-width-alert',
                buttons: [
                    {
                    text: 'Confirm',
                    handler: async () => {
                        this.createNewContact();
                    },
                    cssClass: 'alert-button'
                    },
                    {
                    text: 'Cancel',
                    cssClass: 'alert-button no'
                    }
                ],
              });
            
              await alert.present();
              await alert.onDidDismiss(); 
        }
    }

    dateUnfocused(): void {
        this.setEndDate();
        this.trigger++;
    }

    getFormattedDate(date: Date): string {
        return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' : ''}${(date.getMonth() + 1)}-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;;
    }

    closeModal(update: boolean = false){
        this.modalCtrl.dismiss(update);
    }
}