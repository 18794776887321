import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ViewWillEnter } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { AdminApiService } from '../../../../services/admin-api-service';
import { User } from '../../../../../../../app/shared/models/user.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SharedService } from '../../../../../../../app/shared/services/shared.service';
import { Contact } from '../../../../../../../app/shared/models/contact.model';
import { AlertController } from '@ionic/angular';
import { IAccountData } from '../../../../../../../app/shared/models/account.model';
import { IonSelect } from '@ionic/angular';
import { ICustomerContactAccountInvoice } from '../../../../../../../app/shared/models/customer-contact-account-invoice.model';

@Component({
    selector: 'new-contact-popup-component',
    templateUrl: 'new-contact.component.html',
    styleUrls:['./new-contact.component.scss'],
    providers:[AdminApiService]
})

export class NewContactPopupComponent implements ViewWillEnter, OnInit {

    @ViewChild('accountSelect') accountSelect: IonSelect;

    showCustomerOptions: boolean = false;
    showAccountPopupOnCreation: boolean = false;
    accounts: IAccountData[] = [];
    selectedAccounts: number[] = [];

    firstName: string = null;
    lastName: string = null;
    email: string = null;
    phone: string = null;
    active: boolean = true;

    primaryApprover: boolean = false;
    approver: boolean = false;
    accountsPayable: boolean = false;
    payable: boolean = false;
    appAccess: boolean = false;
    allAccountAccess: boolean = true;
    accountAccess: IAccountData[] = [];

    notes: string = null;
    receive_invoice: boolean = false;
    job_title: string = null;
    role: string = null;

    phoneExt: string = null;
    deskPhone: string = null;
    deskPhoneExtension: string = null;

    contacts: Contact[] = [];

    countryCodeDropdownOpen: boolean = false;
    countryCode: number = 1;

    invoiceAccounts: IAccountData[] = [];

    sendQASInviteEmail: boolean = false;

    allAccounts: boolean = true;
    
    newForm: FormGroup = new FormGroup({
        firstName: new FormControl(this.firstName),
        lastName: new FormControl(this.lastName),
        email: new FormControl(this.email),
        phone: new FormControl(this.phone),
        phoneExt: new FormControl(this.phoneExt),
        active: new FormControl(this.active),
        primaryApprover: new FormControl(this.primaryApprover),
        approver: new FormControl(this.approver),
        accountsPayable: new FormControl(this.accountsPayable),
        payable: new FormControl(this.payable),
        notes: new FormControl(this.notes),
        receive_invoice: new FormControl(this.receive_invoice),
        job_title: new FormControl(this.job_title),
        role: new FormControl(this.role),
        sendQASInviteEmail: new FormControl(this.sendQASInviteEmail),
        deskPhone: new FormControl(this.deskPhone),
        deskPhoneExtension: new FormControl(this.deskPhoneExtension),
        invoiceAccounts: new FormControl(this.invoiceAccounts),
        all_accounts: new FormControl(this.allAccounts),
        app_access: new FormControl(this.appAccess),
        all_account_access: new FormControl(this.allAccountAccess),
        accountAccess: new FormControl(this.accountAccess),
    });

    validEmail: boolean = false;

    countryCodeDeskDropdownOpen: boolean = false;
    countryCodeDesk: number = 1;

    constructor(
        private modalCtrl: ModalController,
        private adminApiService: AdminApiService,
        private angularFireAuth: AngularFireAuth,
        private sharedService: SharedService,
        private alertController: AlertController
    ) {

    }

    ngOnInit(): void {
    
    }

    ionViewWillEnter(): void {
        this.newForm.patchValue({
            invoiceAccounts: this.accounts ?? [],
            accountAccess: this.accounts ?? []
        });
    }

    checkEmailValidity(): void {
        const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        
        this.validEmail = regex.test(this.newForm.value.email);
    }

    async submitNewContact(): Promise<void> {
        const newContact: Contact = {
            id: null,
            first_name: this.newForm.value.firstName,
            last_name: this.newForm.value.lastName,
            phone: this.newForm.value.phone,
            active: this.newForm.value.active,
            email: this.newForm.value.email,
            phoneExtension: this.newForm.value.phoneExt,
            deskPhone: this.newForm.value.deskPhone,
            deskPhoneExtension: this.newForm.value.deskPhoneExtension
        };

        const customerValues: any = {
            primaryApprover: this.newForm.value.primaryApprover,
            approver: this.newForm.value.approver,
            accountsPayable: this.newForm.value.accountsPayable,
            payable: this.newForm.value.payable,
            notes: this.newForm.value.notes,
            receive_invoice: this.newForm.value.receive_invoice,
            job_title: this.newForm.value.job_title,
            role: this.newForm.value.role,
            all_accounts: this.newForm.value.all_accounts,
            app_access: this.newForm.value.app_access,
            all_account_access: this.newForm.value.all_account_access
        };

        const recieveInvoice: ICustomerContactAccountInvoice[] = this.accounts.map(c => {
            const recieveData = this.newForm.value.receive_invoice && (this.newForm.value.invoiceAccounts.find(i => i.id === c.id) != null);

            return {
                customer_contact_id: 0,
                account_id: c.id,
                recieves_invoice: recieveData
            } as ICustomerContactAccountInvoice
        });

        const accountAccess: ICustomerContactAccountInvoice[] = this.accounts.map(c => {
            const recieveData = this.newForm.value.app_access && (this.newForm.value.accountAccess.find(i => i.id === c.id) != null);

            return {
                customer_contact_id: 0,
                account_id: c.id,
                access: recieveData
            } as ICustomerContactAccountInvoice
        });

        if (!this.validEmail) {
            this.sharedService.presentToast('danger', 'Email entered does not meet the criteria for a valid email address.', 'Invalid email format!', 'med')
        } else {
            var contact: Contact = await this.adminApiService.addContact(newContact);

            if ((contact as any).message) {
                const alert = await this.alertController.create({
                    header: `Contact already exists`,
                    message: `Would you like to use the existing contact?`,
                    cssClass: 'custom-alert alert-button large-max-width-alert',
                    buttons: [
                        {
                        text: 'Confirm',
                        handler: async () => {
                            // this.closeModal((contact as any).contact, customerValues);
                            contact = (contact as any).contact;
                        },
                        cssClass: 'alert-button'
                        },
                        {
                        text: 'Cancel',
                        cssClass: 'alert-button no'
                        }
                    ],
                });
                
                await alert.present();
                await alert.onDidDismiss(); 
            }

            if (this.showAccountPopupOnCreation && (this.newForm.value.role === 'Quality')) {
                await this.showAccountSelectionPopup();
            }

            if (this.newForm.value.sendQASInviteEmail && (this.newForm.value.role === 'Quality')) {
                const result = this.adminApiService.sendWelcomeEmail({email: this.newForm.value.email});
               
                if (result) {
                    this.sharedService.presentToast('primary', '', 'Welcome email sent.', 'long');
                }
            }

            this.closeModal(contact, customerValues, recieveInvoice, accountAccess);
        }
    }

    async showAccountSelectionPopup(): Promise<void> {
        const alert = await this.alertController.create({
            header: `Quality Contact`,
            message: `Would you like to assign this contact as a quality contact for any existing accounts?`,
            cssClass: 'custom-alert alert-button large-max-width-alert',
            buttons: [
                {
                text: 'Assign & Create Contact',
                handler: async () => {
                    if (this.accountSelect) {
                        const selector = await this.accountSelect.open();

                        await new Promise((resolve, reject) => {
                            this.accountSelect.ionDismiss.subscribe((event) => {
                                resolve(null);
                            });
                        });
                    } else {
                        this.sharedService.presentToast('danger', 'The account assignment box failed to open. Contact was still processed successfully.', 'An error occured!', 'long');
                    }
                },
                cssClass: 'alert-button'
                },
                {
                text: 'Create Contact',
                cssClass: 'alert-button'
                }
            ],
        });
        
        await alert.present();
        await alert.onDidDismiss(); 
    }

    closeModal(contact: Contact = null, customerValues: any = null, recieveInvoice: any[] = null, accountAccess: any[] = null){
        this.modalCtrl.dismiss(contact ? {contact: contact, values: customerValues, accounts: this.selectedAccounts, recieveInvoice, accountAccess} : null);
    }
}