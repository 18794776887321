<ion-grid>
  <ion-row class="header-row">
    <ion-col>
      <span class="modal-header-text">Add Recipients</span>
    </ion-col>
    <ion-col size="auto">
      <ion-icon
        class="cross"
        (click)="closeModal()"
        name="close-outline"
      ></ion-icon>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-input [(ngModel)]="email" placeholder="Enter email"></ion-input>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-button (click)="addContact()">Add Recipient</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
