import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ViewWillEnter } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { AdminApiService } from '../../../../services/admin-api-service';
import { User } from '../../../../../../../app/shared/models/user.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SharedService } from '../../../../../../../app/shared/services/shared.service';
import { MaskService } from 'ngx-mask';
import { IBusiness } from '../../../../../../../app/shared/models/business.model';

@Component({
    selector: 'new-liaison-popup-component',
    templateUrl: 'new-liaison.component.html',
    styleUrls:['./new-liaison.component.scss'],
    providers:[AdminApiService, MaskService],
})

export class NewUserPopupComponent implements ViewWillEnter, OnInit {

    role: string = 'liaison';

    businesses: IBusiness[] = [];

    firstName: string = null;
    lastName: string = null;
    email: string = null;
    country: 'US' | 'MX' | 'CA' | 'KO' = null;
    address: string = null;
    address2: string = null;
    city: string = null;
    state: string = null;
    postal: string = null;
    active: boolean = true;
    phone_number: string = null;
    eligible_for_1099: boolean = true;
    manager: number = null;
    business_id: string = null;
    payentry_email: string = null;

    requireLiaisonManager: boolean = true;
    requireAddress: boolean = true;
    require1099: boolean = true;
    requirePersonalEmail: boolean = true;
    requireBusiness: boolean = true;
    
    newForm: FormGroup = new FormGroup({
        firstName: new FormControl(this.firstName),
        lastName: new FormControl(this.lastName),
        email: new FormControl(this.email, Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])),
        address: new FormControl(this.address),
        city: new FormControl(this.city),
        state: new FormControl(this.state),
        postal: new FormControl(this.postal),
        active: new FormControl(this.active),
        phone_number: new FormControl(this.phone_number),
        eligible_for_1099: new FormControl(this.eligible_for_1099),
        manager: new FormControl(this.manager),
        business_id: new FormControl(this.business_id),
        payentry_email: new FormControl(this.payentry_email),
        country: new FormControl(this.country),
        address2: new FormControl(this.address2)
    });

    countryCodeDropdownOpen: boolean = false;
    countryCode: number = 1;

    validEmail: boolean = false;

    liaisons: User[] = [];

    managers: User[] = [];

    constructor(
        private modalCtrl: ModalController,
        private adminApiService: AdminApiService,
        private angularFireAuth: AngularFireAuth,
        private sharedService: SharedService
    ) {

    }

    async ngOnInit(): Promise<void> {
        const promises: any[] = [];

        promises.push(this.getLiaisonManagers());
        promises.push(this.getBusinesses());

        await Promise.all(promises);

        this.newForm.patchValue({country: 'US'});
    }

    ionViewWillEnter(): void {
        
    }

    async getBusinesses(): Promise<void> {
        this.businesses = await this.adminApiService.getAllBusinesses();
    }

    async getLiaisonManagers(): Promise<void> {
        this.managers = await this.adminApiService.getUsers('liaison manager');
    }

    checkEmailValidity(): void {
        const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        
        this.validEmail = regex.test(this.newForm.value.email);
    }

    async submitNewLiaison(): Promise<void> {
        const newUser: User = {
            first_name: this.newForm.value.firstName,
            last_name: this.newForm.value.lastName,
            address: this.newForm.value.address,
            city: this.newForm.value.city,
            state: this.newForm.value.state,
            postal: this.newForm.value.postal,
            active: this.newForm.value.active,
            phone_number: this.newForm.value.phone_number,
            eligible_for_1099: this.newForm.value.eligible_for_1099,
            email: this.newForm.value.email,
            role: this.role,
            manager: this.newForm.value.manager,
            country_code: this.countryCode,
            business_id: this.newForm.value.business_id,
            payentry_email: this.newForm.value.payentry_email,
            country: this.newForm.value.country,
            address2: this.newForm.value.address2
        };

        if (!this.validEmail) {
            this.sharedService.presentToast('danger', 'The email entered doe not match the predefined pattern for a valid email address.', 'Email is invalid!', 'long');
        } else if (this.liaisons.find(l => l.email === this.newForm.value.email)) {
            this.sharedService.presentToast('danger', 'The email entered is already associated with another liaison.', 'Email already exists!', 'long');
        } else {
            try {
                const user: User = await this.adminApiService.addLiaison(newUser);

                if (user) {
                    this.sharedService.presentToast('primary', `${(this.role === 'liaison') ? 'Liaison' : 'User'} was created successfully.`, 'Success!', 'short');
                    this.closeModal(true);
                } else {
                    this.sharedService.presentToast('danger', 'Liaison was not created successfully.', 'Failed!', 'short');
                }
    
            } catch (error) {
                console.log(error);

                if (error.error === 'Quickbooks failed to sync') {
                    this.sharedService.presentToast('danger', 'Quickbooks failed to process customer. This may be caused by quickbooks not being synced with the QAS server.', 'Failed!', 'long');
                } else {
                    this.sharedService.presentToast('danger', 'Liaison was not created successfully.', 'Failed!', 'short');
                }
            }
        }
    }

    removeBusiness(): void {
        this.newForm.patchValue({
            business_id: null
        })
    }

    disabledCheck(): boolean {
        return !this.newForm.value.firstName || !this.newForm.value.lastName || (!this.newForm.value.address && this.requireAddress && !this.newForm.value.business_id)
         || (!this.newForm.value.city && this.requireAddress && !this.newForm.value.business_id) ||
        (!this.newForm.value.state && this.requireAddress && (this.newForm.value.country === 'US') && !this.newForm.value.business_id) || 
        (!this.newForm.value.postal && this.requireAddress && !this.newForm.value.business_id) || !this.newForm.value.email || 
        (!this.newForm.value.payentry_email && this.requirePersonalEmail)
    }

    closeModal(update: boolean = false){
        this.modalCtrl.dismiss(update);
    }
}