<ion-header [ngClass]="{
  'offline-header': isOffline()
}">
  <!-- what is this name dude -->
  <div class="new-container">
    <ng-container *ngIf="!(environment === 'staging') && !(environment === 'development')">
      <img
      (click)="openContactUsModal()"
      class="qas-logo"
      src="../.././../../../assets/logos/QAS Logo-main.svg"
      />
    </ng-container>
    <ng-container *ngIf="environment === 'staging' || environment === 'development'">
      <div class="staging-icon">
        <span>STAGING</span>
      </div>
    </ng-container>
    <div name="dropdown-toggle" class="nav-container" id="dropdown-toggle" (click)="openPopup($event)">
      <div class="user-container">
        <h6 class="profile-tag">{{ (userName.length > 20) ? (userName.substring(0,20) + '...') : userName }}</h6>
        <h6 class="name-tag">{{ user.role | titlecase }}</h6>
      </div>
      <div class="profile-container">
        <img class="profile" src="../../../../assets/icons/profile.svg" />
      </div>
    </div>
  </div>

  <ng-container *ngIf="isOffline()">
    <div class="offline-mode">
      <div class="no-connection">
        <span>No Connection</span>
      </div>
      <div class="icon-container">
        <div (click)="checkConnection()" class="retry">
          <ion-icon [ngClass]="{'spin-animation': retryConnection}" name="refresh-outline"></ion-icon>
          <span>Retry</span>
        </div>
        <!-- <div class="drop-down">
          <ion-icon (click)="expanded = !expanded" [name]="expanded ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
        </div> -->
      </div>
    </div>
    <!-- <div class="pending-requests" *ngIf="expanded">
      <span>Pending Requests: {{cacheInterceptor.getQueueLength()}}</span>
    </div> -->
  </ng-container>

  <ng-container *ngIf="isSyncing() && !isOffline()">
    <div class="offline-mode syncing">
      <div class="no-connection">
        <span>Offline request syncing...</span>
      </div>
      <div class="icon-container">
        <div class="retry">
          <ion-icon class="spin-animation" name="refresh-outline"></ion-icon>
        </div>
      </div>
    </div>
  </ng-container>

</ion-header>
