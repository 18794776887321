<ion-grid>
    <ion-row class="header-row">
        <ion-col>
          
        </ion-col>
        <ion-icon class="cross" (click)="close()" name="close-outline"></ion-icon>
    </ion-row>

    <div class="scroll-area" *ngIf="!loading">

        <ion-row>
            <ion-col *ngIf="!user || selectUser">
                <div class="label-text">
                    <span>Liaison</span>
                    <select [(ngModel)]="user" *ngIf="sharedService.isNative()" (change)="userChanged()" [ngClass]="{'text-color-grey': !user}">
                        <option [value]="null" disabled selected>Select Liaison</option>
                        <ng-container *ngIf="liaisons?.length > 0">
                            <option [ngValue]="user" *ngFor="let user of liaisons">
                                {{user?.first_name}} {{user?.last_name ?? ''}}
                            </option>
                        </ng-container>
                    </select>
                    <ng-select placeholder="Select Liaison" *ngIf="!sharedService.isNative()" appendTo="body" class="ng-select-no-border"
                    [(ngModel)]="user" (change)="userChanged()" [ngClass]="{'text-color-grey': !user}">
                        <ng-container *ngIf="liaisons?.length > 0">
                            <ng-option [value]="user" *ngFor="let user of liaisons">
                                {{user?.first_name}} {{user?.last_name ?? ''}}
                            </ng-option>
                        </ng-container>
                    </ng-select>
                </div>
            </ion-col>
            <ion-col *ngIf="!returnData">
                <div class="label-text">
                    <span>Account</span>
                    <select [(ngModel)]="selectedAccount" *ngIf="sharedService.isNative()" [disabled]="!user && !selectedAccount" (change)="accountUpdate()" [ngClass]="{'text-color-grey': !selectedAccount}">
                        <option [value]="null" disabled selected>Select Account</option>
                        <option [ngValue]="account" *ngFor="let account of accounts | filterOutExistingLiaisonAccounts : user?.id : trigger">
                            {{account?.name}} - {{account?.qbo_name ?? account?.locations?.name}}
                        </option>
                    </select>
                    <ng-select placeholder="Select Account" *ngIf="!sharedService.isNative()" appendTo="body" class="ng-select-no-border" [(ngModel)]="selectedAccount"
                    [disabled]="!user && !selectedAccount" (change)="accountUpdate()" [ngClass]="{'text-color-grey': !selectedAccount}">
                        <ng-option [value]="account" *ngFor="let account of accounts | filterOutExistingLiaisonAccounts : user?.id : trigger">
                            {{account?.name}} - {{account?.qbo_name ?? account?.locations?.name}}
                        </ng-option>
                    </ng-select>
                </div>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <div class="label-text">
                    <span>Pay Rate</span>
                    <currency-input [width]="100" [(value)]="liaisonHistory.rate" [placeholder]="''"></currency-input>
                </div>
            </ion-col>
            <ion-col>
                <div class="label-text">
                    <span>Base Hours</span>
                    <ion-input type="number" [(ngModel)]="liaisonHistory.hours"></ion-input>
                </div>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <div class="label-text">
                    <span>Start Date</span>
                    <ion-input [min]="minStartDate" type="date" [(ngModel)]="liaisonHistory.start_date"></ion-input>
                </div>
            </ion-col>
            <ion-col>
                <div class="label-text">
                    <span>End Date</span>
                    <div>
                        <ion-input [min]="minStartDate" type="date" [(ngModel)]="liaisonHistory.end_date"></ion-input>
                    </div>
                </div>
            </ion-col>
        </ion-row>

    </div>

    <div class="loading-area" *ngIf="loading">
        <ion-spinner name="dots"></ion-spinner>
    </div>

    <ion-row class="save-button">
        <ion-col>
            <div [ngClass]="{'disabled': ((!selectedAccount && !returnData) || !user)}" (click)="save()">
                <span>Save</span>
            </div>
        </ion-col>
    </ion-row>
</ion-grid>