<ion-grid>
  <ng-container *ngIf="loaded">
    <ion-row>
      <ion-col>
        <span class="modal-header-text">Edit Additional Hours</span>
      </ion-col>
      <ion-col size="auto">
        <ion-icon class="cross" (click)="close()" name="close-outline"></ion-icon>
      </ion-col>
    </ion-row>
  
    <ion-row class="label">
      <ion-col class="ion-no-padding">
        <ion-label>Hours</ion-label>
      </ion-col>
    </ion-row>
  
    <ion-row>
      <ion-col>
          <ion-input (ionChange)="hoursChanged()" [(ngModel)]="additionalTime.hours" placeholder=""></ion-input>
      </ion-col>
    </ion-row>
  
    <ion-row class="label">
      <ion-col class="ion-no-padding">
        <ion-label>Email</ion-label>
      </ion-col>
    </ion-row>
  
    <ion-row>
      <ion-col>
          <ion-input [(ngModel)]="email" placeholder="Enter email to send new request to"></ion-input>
      </ion-col>
    </ion-row>
  
    <ion-row class="warning" [ngStyle]="{'opacity': canUpdate ? 1 : 0}">
      <ion-col>
        * An email will be sent to {{email}} when resent and the original request will be invalidated.
      </ion-col>
    </ion-row>
  
    <ion-row class="update-row">
      <ion-col>
          <ion-button (click)="update()" [disabled]="!canUpdate || !email || (email.length <= 6)">Resend Request</ion-button>
      </ion-col>
    </ion-row>
  
    <ion-row class="cancel-row">
      <ion-col>
          <ion-button (click)="cancel()">Cancel Request</ion-button>
      </ion-col>
    </ion-row>
  </ng-container>

  <ng-container *ngIf="!loaded">
    <div class="loading-container">
      <ion-spinner name="dots"></ion-spinner>
    </div>
  </ng-container>

</ion-grid>