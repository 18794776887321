import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AdminApiService } from "../../services/admin-api-service";
import { ModalController } from "@ionic/angular";
import { IAccountData } from "../../../../../app/shared/models/account.model";
import { SharedService } from "../../../../../app/shared/services/shared.service";
import { NewAccountWalkthroughPopupComponent } from "../new-account-walkthrough/new-account-walkthrough.component";
import { ICustomer } from "../../../../../app/shared/models/customer.model";
import { EditAccountWalkthroughPopupComponent } from "../edit-account-walkthrough/edit-account-walkthrough.component";
import { User } from "../../../../../app/shared/models/user.model";
import { ILiaisonAccount } from "../../../../../app/shared/models/liaison-account.model";
import { ILocation } from "../../../../../app/shared/models/location.model";
import { ChangeDetectorRef } from "@angular/core";

import { AssignLiaisonHistoryComponent } from "../../pages/liaison-details/components/assign-liaison-history/assign-liaison-history.component";
import { AssignLiaisonNewAccountComponent } from "../../pages/liaison-details/components/assign-liaison-new-account/assign-liaison-new-account.component";

// TO DO: This screen should be reformated to filter on server 
@Component({
    selector: 'account-container-component',
    templateUrl: 'account-container.component.html',
    styleUrls: ['./account-container.component.scss'],
    providers: [AdminApiService]
})
export class AccountContainerComponent implements OnInit {

    @Input() customerId: number = null;
    @Input() customer: ICustomer = null;

    @Input() liaisonId: number = null;
    @Input() liaison: User = null;

    @Input() locationId: number = null;
    @Input() location: ILocation = null;

    @Input() accounts: any[] = [];
    @Input() liaisons: User[] = [];

    @Output() dataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    trigger: number = 0;

    baseId: number = null;

    public totalHours: number = 0;

    loaded: boolean = false;

    showInactiveAccounts: boolean = false;

    constructor(
        private modalController: ModalController,
        private adminApiService: AdminApiService,
        public sharedService: SharedService,
        private cdr: ChangeDetectorRef
    ) {

    }

    async ngOnInit(): Promise<void> {
        const promises: any[] = [];

        promises.push(this.getAccounts());
        promises.push(this.getLiaisons());

        await Promise.all(promises);
  
        this.loaded = true;
    }

    updatedData(event: any) {
        this.accounts = event;

        this.trigger++;
    }

    async setFilteringValues(accounts: any[]): Promise<void> {
        if (this.customerId) {
            this.accounts = await Promise.all(accounts.filter(i => i.customers.id === this.customerId).sort((a, b) => {
                if (b.name < a.name) return 1;
                if (b.name > a.name) return -1;
                else {
                    if ((b?.qbo_name ?? b?.locations?.name) < (a?.qbo_name ?? a?.locations?.name)) return 1;
                    else if ((b?.qbo_name ?? b?.locations?.name) > (a?.qbo_name ?? a?.locations?.name)) return -1;
                    return 0;
                }
            }).map(async a => {
                const totalHours: number = await this.getTotalHoursForAccount(a);
                return { ...a, ...{ totalHours } };
            }));

            if (this.accounts) {
                this.accounts.forEach(a => {
                    if (a.liaison_accounts) {
                        a.liaison_accounts = a.liaison_accounts.sort((a, b) => {
                            if (b.users.first_name < a.users.first_name) return 1;
                            if (b.users.first_name > a.users.first_name) return -1;
                            return 0;
                        });
                    }
                });
            }

            this.baseId = this.customerId;
        }

        if (this.liaisonId) {
            this.accounts = await Promise.all(accounts.filter(i => {
                var covers = [];

                i.liaison_accounts.forEach(la => {
                    covers = [...covers, ...la.liaison_account_cover];
                });

                return (
                    i.liaison_accounts.map(l => l.user_id).includes(this.liaisonId) ||
                    covers.map(c => c.user_id).includes(this.liaisonId)
                );
            }).map(async (i) => {
                console.log(i);
                var isCrossCoverage = false;
                var crossCover = null;

                const liaisonAccount = i.liaison_accounts.find(l => {
                    if (l.liaison_account_cover.map(c => c.user_id).includes(this.liaisonId)) {
                        isCrossCoverage = true;
                        crossCover = l.liaison_account_cover.find(x => x.user_id === this.liaisonId);
                    }

                    return ((l.user_id === this.liaisonId) || l.liaison_account_cover.map(c => c.user_id).includes(this.liaisonId));
                });

                if (liaisonAccount && !liaisonAccount.pay_rate && !liaisonAccount.base_hours) {
                    // Check if there is any history in the future and show that instead
                    const allHistory = (await this.adminApiService.getAllHistoryByLiaisonAccount(liaisonAccount.id)).sort((a, b) => {
                        if (a.start_date > b.start_date) return 1;
                        if (a.start_date < b.start_date) return -1;
                        return 0;
                    });

                    if (allHistory[0]?.start_date && (this.sharedService.stringToDate(allHistory[0]?.start_date as any) >= new Date(new Date().setHours(0)))) {
                        liaisonAccount.pay_rate = allHistory[0]?.rate;
                        liaisonAccount.base_hours = allHistory[0]?.hours;
                    }
                }

                if (liaisonAccount?.liaison_account_cover?.length > 0) {
                    isCrossCoverage = true;
                }

                return { account: i, liaisonAccountId: liaisonAccount?.id, basePay: (crossCover ? crossCover?.pay_rate : liaisonAccount?.pay_rate), baseHours: liaisonAccount?.base_hours, active: this.sharedService.isActiveAccount(i), isCrossCoverage, crossCover };
            }));
            // LA id is null on model
            console.log(this.accounts);
            this.baseId = this.liaisonId;
        }

        if (this.locationId) {
            this.accounts = await Promise.all(accounts.filter(i => i.locations.id === this.locationId).sort((a, b) => {
                if (b.name < a.name) return 1;
                else if (b.name > a.name) return -1;
                else {
                    if ((b?.qbo_name ?? b?.locations?.name) < (a?.qbo_name ?? a?.locations?.name)) return 1;
                    else if ((b?.qbo_name ?? b?.locations?.name) > (a?.qbo_name ?? a?.locations?.name)) return -1;
                    return 0;
                }
            }).map(async a => {
                const totalHours: number = await this.getTotalHoursForAccount(a);
                return { ...a, ...{ totalHours } };
            }));

            if (this.accounts) {
                this.accounts.forEach(a => {
                    if (a.liaison_accounts) {
                        a.liaison_accounts = a.liaison_accounts.sort((a, b) => {
                            if (b.users.first_name < a.users.first_name) return 1;
                            if (b.users.first_name > a.users.first_name) return -1;
                            return 0;
                        });
                    }
                });
            }

            this.baseId = this.locationId;
        }
    }

    async getAccounts(): Promise<void> {
        // Put in order of newest - oldest
        const accounts = (await this.adminApiService.getAccountsData(true) ?? []).reverse();
        console.log(accounts);
        await this.setFilteringValues(accounts);
        this.getTotalHours();
    }

    async getLiaisons(): Promise<void> {
        this.liaisons = await this.adminApiService.getUsers('liaison');
        console.log(this.liaisons);
    }

    getUserById(id: number): User {
        return this.liaisons.find(l => l.id === id);
    }

    async getTotalHoursForAccount(account: IAccountData): Promise<number> {
        var contractedHours = account?.account_history[0]?.contracted_hours;

        if (contractedHours == null) {
            const allHistory = (await this.adminApiService.getAllAccountHistory(account.id)).sort((a, b) => {
                if (a.start_date > b.start_date) return 1;
                if (a.start_date < b.start_date) return -1;
                return 0;
            });

            contractedHours = allHistory[0]?.contracted_hours;
        }
        return contractedHours ?? 0;
    }

    getTotalHours(): number {
        var total: number = 0;
        this.accounts.filter(a => (this.liaisonId ? this.sharedService.isActiveAccount(a.account) : this.sharedService.isActiveAccount(a))).forEach((a: any) => {
            if (this.liaisonId) {
                total += a.baseHours;
            } else {
                total += (a.account_history[0]?.contracted_hours ?? 0);
            }
        });

        this.totalHours = total;
        return total;
    }

    async addNewAccount(): Promise<void> {
        const modal = await this.modalController.create({
            component: NewAccountWalkthroughPopupComponent,
            componentProps: {
                passedInCustomer: this.customerId
            },
            cssClass: "min-width-modal",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data) {
            const promises: any[] = [];
            promises.push(this.getAccounts());
            promises.push(this.getLiaisons());

            await Promise.all(promises);

            this.dataUpdated.emit(true);
        }
    }

    async editAccount(account: IAccountData): Promise<void> {

        const modal = await this.modalController.create({
            component: EditAccountWalkthroughPopupComponent,
            componentProps: {
                accountId: account.id
            },
            cssClass: "min-width-modal",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();

        await this.getAccounts();

        this.dataUpdated.emit(true);
    }

    async addNewLiaisonAccount(): Promise<void> {
        const modal = await this.modalController.create({
            component: AssignLiaisonNewAccountComponent,
            componentProps: {
                user: this.liaison,
                // accounts: this.accounts
            },
            cssClass: "min-width-modal",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data) {
            await this.getAccounts();
            // Update total in corner
            this.cdr.detectChanges();
        }
    }

    async editLiaisonAccount(param: any): Promise<void> {
        const modal = await this.modalController.create({
            component: EditAccountWalkthroughPopupComponent,
            componentProps: {
                liaisonAccountId: param.liaisonAccountId,
                accountId: param.account?.id,
                user: this.liaison,
                activeTab: 3,
                autoSelectUser: this.liaison.id
            },
            cssClass: "min-width-modal",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data) {
            await this.getAccounts();

            // Update total in corner
            this.cdr.detectChanges();

            this.dataUpdated.emit(true);
        }
    }

    getActiveAccounts(): any[] {
        return this.accounts.filter(a => {
            return this.isActive(this.liaisonId ? a.account : a);
        });
    }

    getLiaisonAccounts(account: IAccountData): ILiaisonAccount[] {
        return account.liaison_accounts.filter(la => this.sharedService.isActiveLiaisonAccount(la));
    }

    isActive(account: IAccountData, isCrossCoverage?: boolean): boolean {
        const isLiaisonAccountActive = this.sharedService.isActiveLiaisonAccount(account.liaison_accounts.find(l => l.user_id === this.liaisonId)) || isCrossCoverage;
        const mostRecentHistory = (account.account_history ?? []).sort((a, b) => {
            return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
        })[0];

        if (!mostRecentHistory) {
            return false;
        }

        const mostRecentStartDate = this.sharedService.stringToDate(mostRecentHistory.start_date as any);
        const mostRecentEndDate = this.sharedService.stringToEndDate(mostRecentHistory.end_date as any);
        return (mostRecentStartDate <= new Date()) && (!mostRecentHistory.end_date || (mostRecentEndDate >= new Date())) && (isLiaisonAccountActive || !this.liaisonId);
    }

    close(): void {
        this.modalController.dismiss();
    }

}