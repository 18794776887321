import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, firstValueFrom } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ILiaisonAccount, LiaisonAccount } from "../../../../app/shared/models/liaison-account.model";
import { ILocation } from "../../../../app/shared/models/location.model";
import { ICustomer } from "../../../../app/shared/models/customer.model";
import { User } from "../../../../app/shared/models/user.model";
import { Account } from "../../liaison/models/accounts.model";
import { IAccount } from "../../../../app/shared/models/account.model";
import { IAccountData } from "../../../../app/shared/models/account.model";
import { AccountContact, Contact, CustomerContact } from "../../../../app/shared/models/contact.model";
import { LiaisonMessage } from "../../../../app/shared/models/liaison-message.model";
import { ITimeEntry } from "../../../../app/shared/models/liaison-shifts.model";
import { IApproveRejectEntry, ITimeEntryData } from "../pages/time-approval/models/time-entry-data.model";
import { IAdditionalTime } from "../../../../app/shared/models/additional-time.model";
import { ILiaisonAccountHistory } from "../../../shared/models/liaison-account-history.model";
import { SharedService } from "../../../../app/shared/services/shared.service";
import { LiaisonReport } from "../../../../app/shared/models/liaison-report.model";
import { LiaisonIssue } from "../../../../app/shared/models/liaison-issue.model";
import { ICustomerTimeEntries } from "../pages/invoicing-and-payments/models/invoice.model";
import { IActivity } from "../../../../app/shared/models/activity.model";
import { AdminHomeFilter } from "../pages/home/models/filter.model";
import { IAccountHistory } from "../../../../app/shared/models/account-history.model";
import { IBusiness } from "../../../../app/shared/models/business.model";
import { ILiaisonAccountCover } from "../../../../app/shared/models/liaison-account-cover.model";
import { IProcessedReport, ReportType } from "../pages/reports/models/report-options.model";
import { IWeeklyNote } from "../../../../app/shared/models/weekly-note.model";
import { ICustomerContactAccountInvoice } from "../../../../app/shared/models/customer-contact-account-invoice.model";

@Injectable()
export class AdminApiService {
  API_BASE_URL: string;

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {
    this.API_BASE_URL = environment.API_URL;
  }

  async getCustomers(): Promise<ICustomer[]> {
    return firstValueFrom(this.httpClient.get<ICustomer[]>(`${this.API_BASE_URL}/admin/customers`));
  }

  async clearDemoAccountData(): Promise<boolean> {
    return firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/admin/customers/clear-demo-data`, {}));
  }

  async getCustomerById(id: number): Promise<ICustomer> {
    return firstValueFrom(this.httpClient.get<ICustomer>(`${this.API_BASE_URL}/admin/customers/${id}`));
  }

  async updateCustomer(customer: ICustomer): Promise<ICustomer>  {
    return firstValueFrom(this.httpClient.patch<ICustomer>(`${this.API_BASE_URL}/admin/customers`, customer));
  }

  async addCustomer(customer: ICustomer): Promise<ICustomer>  {
    return firstValueFrom(this.httpClient.post<ICustomer>(`${this.API_BASE_URL}/admin/customers`, customer));
  }

  async deleteCustomer(id: number) {
    return this.httpClient.delete<ICustomer>(`${this.API_BASE_URL}/admin/customers/${id}`)
    .pipe(
        catchError((error) => {
            console.log(error);
            return [];
        })
    );
  }

  async getAccounts(){
    const results = firstValueFrom(
      this.httpClient.get<LiaisonAccount[]>(`${this.API_BASE_URL}/admin/accounts`)
    );
  }

  async getAccountsData(includeUser: boolean = false, includeAllHistory: boolean = false): Promise<IAccountData[]> {
    const userId: number = JSON.parse(this.sharedService.localStorageGet('user'))?.id;

    var additionalQuery = '';

    if (includeUser && includeAllHistory) {
      additionalQuery = `?user=${userId}&includeAllHistory=true`;
    } else if (includeUser) {
      additionalQuery = `?user=${userId}`;
    } else if (includeAllHistory) {
      additionalQuery = `?includeAllHistory=true`;
    }

    return firstValueFrom(this.httpClient.get<IAccountData[]>(`${this.API_BASE_URL}/admin/accounts${additionalQuery}`));
  }

  async checkAccountNameQBOAvailability(customerId: number, locationId: number, accountDetails: string, accountId: number = null): Promise<boolean> {
    return firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/admin/accounts/check-account-name-availability`, {customer_id: customerId, location_id: locationId, account_details: accountDetails, account_id: accountId}));
  }

  async getAccountsDataWithDate(date: string): Promise<IAccountData[]> {
    return firstValueFrom(this.httpClient.get<IAccountData[]>(`${this.API_BASE_URL}/admin/accounts/${date}`));
  }

  async getAccountsDataById(id: number): Promise<IAccountData> {
    return firstValueFrom(this.httpClient.get<IAccountData>(`${this.API_BASE_URL}/admin/accounts/by-id/${id}`));
  }

  async getAccountsWithIndescrepancies(): Promise<IAccountData[]> {
    return firstValueFrom(this.httpClient.get<IAccountData[]>(`${this.API_BASE_URL}/admin/accounts/indescrepancies/all`));
  }

  async updateAccount(account: IAccount) {
    return firstValueFrom(this.httpClient.patch<IAccount>(`${this.API_BASE_URL}/admin/accounts`, account));
  }

  async deleteAccount(id: number) {
    return this.httpClient.delete<IAccount>(`${this.API_BASE_URL}/admin/accounts/${id}`)
    .pipe(
      catchError((error) => {
          console.log(error);
          return [];
      })
    );
  }

  async addAccount(account: IAccount) {
    return firstValueFrom(this.httpClient.post<IAccount>(`${this.API_BASE_URL}/admin/accounts`, account));
  }

  async updateLiaisonAccount(account: ILiaisonAccount) {
    return firstValueFrom(this.httpClient.patch<ILiaisonAccount>(`${this.API_BASE_URL}/admin/accounts/liaison-account`, account));
  }

  async deleteLiaisonAccount(id: number) {
    return firstValueFrom(this.httpClient.delete<ILiaisonAccount>(`${this.API_BASE_URL}/admin/accounts/liaison-account/${id}`));
  }

  async addLiaisonAccount(account: ILiaisonAccount) {
    return firstValueFrom(this.httpClient.post<ILiaisonAccount>(`${this.API_BASE_URL}/admin/accounts/liaison-account`, account));
  }

  async getUsers(role: string, includeUser: boolean = false) {
    const userId: number = JSON.parse(this.sharedService.localStorageGet('user'))?.id;
    return firstValueFrom(this.httpClient.get<User[]>(`${this.API_BASE_URL}/admin/users/role/${role}${includeUser ? `?user=${userId}` : ''}`));
  }

  async getLiaisonById(id: number): Promise<User> {
    return firstValueFrom(this.httpClient.get<User>(`${this.API_BASE_URL}/admin/users/${id}`));
  }

  async updateLiaison(user: User) {
    return firstValueFrom(this.httpClient.patch<User>(`${this.API_BASE_URL}/admin/users`, user));
  }

  async updateLiaisonActiveStatus(id: number, active: boolean, inactiveDate: string | null) {
    return firstValueFrom(this.httpClient.post<User>(`${this.API_BASE_URL}/admin/users/update-activation-status`, {id, active, inactiveDate}));
  }

  async fullDeactivateLiaison(id: number) {
    return firstValueFrom(this.httpClient.post<User>(`${this.API_BASE_URL}/admin/users/full-deactivation`, {id}));
  }

  async reactivateLiaison(id: number) {
    return firstValueFrom(this.httpClient.post<User>(`${this.API_BASE_URL}/admin/users/reactivate`, {id}));
  }

  async deleteLiaison(id: number) {
    return this.httpClient.delete<User>(`${this.API_BASE_URL}/admin/users/${id}`)
    .pipe(
        catchError((error) => {
            console.log(error);
            return [];
        })
    );
  }

  async addLiaison(user: User) {
    return firstValueFrom(this.httpClient.post<User>(`${this.API_BASE_URL}/admin/users`, user));
  }

  async getLocations(includeUser: boolean = false): Promise<ILocation[]> {
    const userId: number = JSON.parse(this.sharedService.localStorageGet('user'))?.id;
    return firstValueFrom(this.httpClient.get<ILocation[]>(`${this.API_BASE_URL}/admin/locations${includeUser ? `?user=${userId}` : ''}`));
  }

  async getLocationById(id: number): Promise<ILocation> {
    return firstValueFrom(this.httpClient.get<ILocation>(`${this.API_BASE_URL}/admin/locations/${id}`));
  }

  async updateLocations(location: ILocation): Promise<ILocation> {
    return firstValueFrom(this.httpClient.patch<ILocation>(`${this.API_BASE_URL}/admin/locations`, location));
  }

  async deleteLocation(id: number) {
    return this.httpClient.delete<ILocation>(`${this.API_BASE_URL}/admin/locations/${id}`)
        .pipe(
            catchError((error) => {
                console.log(error);
                return [];
            })
        );
  }

  async addLocations(location: ILocation): Promise<ILocation> {
    return firstValueFrom(this.httpClient.post<ILocation>(`${this.API_BASE_URL}/admin/locations`, location));
  }

  async getContacts(): Promise<Contact[]> {
    return firstValueFrom(this.httpClient.get<Contact[]>(`${this.API_BASE_URL}/admin/contacts`));
  }

  async updateContact(contact: Contact): Promise<Contact> {
    return firstValueFrom(this.httpClient.patch<Contact>(`${this.API_BASE_URL}/admin/contacts`, contact));
  }

  async deleteContact(id: number) {
    return this.httpClient.delete<Contact>(`${this.API_BASE_URL}/admin/contacts/${id}`)
        .pipe(
            catchError((error) => {
                console.log(error);
                return [];
            })
        );
  }

  async addContact(contact: Contact): Promise<Contact> {
    return firstValueFrom(this.httpClient.post<Contact>(`${this.API_BASE_URL}/admin/contacts`, contact));
  }

  async getUserByContact(id: number): Promise<User> {
    return firstValueFrom(this.httpClient.get<User>(`${this.API_BASE_URL}/admin/contacts/get-user/${id}`));
  }

  async getCustomerContacts(id: number): Promise<CustomerContact[]> {
    return firstValueFrom(this.httpClient.get<CustomerContact[]>(`${this.API_BASE_URL}/admin/customers/contacts/${id}`));
  }

  async getCustomerContactsByContact(id: number): Promise<CustomerContact[]> {
    return firstValueFrom(this.httpClient.get<CustomerContact[]>(`${this.API_BASE_URL}/admin/customers/contacts/contact/${id}`));
  }

  async addCustomerContact(customerContact: CustomerContact): Promise<CustomerContact>  {
    return firstValueFrom(this.httpClient.post<CustomerContact>(`${this.API_BASE_URL}/admin/customers/contacts`, customerContact));
  }

  async updateCustomerContact(customerContact: CustomerContact): Promise<CustomerContact>  {
    return firstValueFrom(this.httpClient.patch<CustomerContact>(`${this.API_BASE_URL}/admin/customers/contacts`, customerContact));
  }

  async deleteCustomerContact(id: number) {
    return firstValueFrom(this.httpClient.delete<CustomerContact>(`${this.API_BASE_URL}/admin/customers/contacts/${id}`));
  }

  async getAccountContactsByContact(id: number): Promise<AccountContact[]> {
    return firstValueFrom(this.httpClient.get<AccountContact[]>(`${this.API_BASE_URL}/admin/accounts/contacts/contact/${id}`));
  }

  async getAccountContactsByAccount(id: number): Promise<AccountContact[]> {
    return firstValueFrom(this.httpClient.get<AccountContact[]>(`${this.API_BASE_URL}/admin/accounts/contacts/${id}`));
  }

  async addAccountContact(accountContact: AccountContact): Promise<AccountContact>  {
    return firstValueFrom(this.httpClient.post<AccountContact>(`${this.API_BASE_URL}/admin/accounts/contacts`, accountContact));
  }

  async updateAccountContact(accountContact: AccountContact): Promise<AccountContact>  {
    return firstValueFrom(this.httpClient.patch<AccountContact>(`${this.API_BASE_URL}/admin/accounts/contacts`, accountContact));
  }

  async deleteAccountContact(id: number) {
    return firstValueFrom(this.httpClient.delete<AccountContact>(`${this.API_BASE_URL}/admin/accounts/contacts/${id}`));
  }

  async togglePrimaryFlagOnAccountContact(id: number, value: boolean) {
    return firstValueFrom(this.httpClient.patch<AccountContact>(`${this.API_BASE_URL}/admin/accounts/update-primary-flag`, {id: id, value: value}));
  }

  async getMessages(): Promise<LiaisonMessage[]> {
    return firstValueFrom(this.httpClient.get<LiaisonMessage[]>(`${this.API_BASE_URL}/admin/messages`));
  }

  async addMessage(message: LiaisonMessage): Promise<LiaisonMessage>  {
    return firstValueFrom(this.httpClient.post<LiaisonMessage>(`${this.API_BASE_URL}/admin/messages`, message));
  }

  async addMessageForMultipleUsers(message: LiaisonMessage[]): Promise<LiaisonMessage[]>  {
    return firstValueFrom(this.httpClient.post<LiaisonMessage[]>(`${this.API_BASE_URL}/admin/messages/muliple`, message));
  }

  async updateMessage(message: LiaisonMessage): Promise<LiaisonMessage>  {
    return firstValueFrom(this.httpClient.patch<LiaisonMessage>(`${this.API_BASE_URL}/admin/messages`, message));
  }

  async deleteMessage(id: number) {
    return firstValueFrom(this.httpClient.delete<LiaisonMessage>(`${this.API_BASE_URL}/admin/messages/${id}`));
  }

  async getAllTimeByWeek(date: string): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.get<ITimeEntry[]>(`${this.API_BASE_URL}/admin/time-approval/${date}`));
  }

  async getTimeEntryData(date: string, sort: number, liaisonManager: string): Promise<ITimeEntryData[]> {
    return firstValueFrom(this.httpClient.get<ITimeEntryData[]>(`${this.API_BASE_URL}/admin/time-approval/get-time-data/${date}/${sort}/${liaisonManager}`));
  }

  async generateQBOBills(data: any): Promise<any[]> {
    return firstValueFrom(this.httpClient.post<any[]>(`${this.API_BASE_URL}/admin/time-approval/generate-qbo-bills`, {data}));
  }

  async getTimeEntryDataInRange(startDate: string, endDate: string, sort: number, liaisonManager: string): Promise<ITimeEntryData[]> {
    return firstValueFrom(this.httpClient.get<ITimeEntryData[]>
      (`${this.API_BASE_URL}/admin/time-approval/get-time-data-in-range/${startDate}/${endDate}/${sort}/${liaisonManager}`));
  }

  async approveTimeEntries(values: IApproveRejectEntry[]): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.patch<ITimeEntry[]>(`${this.API_BASE_URL}/admin/time-approval/approve`, { approveList: values }));
  }

  async unapproveTimeEntries(values: IApproveRejectEntry[]): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.patch<ITimeEntry[]>(`${this.API_BASE_URL}/admin/time-approval/unapprove`, { approveList: values }));
  }

  async rejectTimeEntries(values: IApproveRejectEntry[]): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.patch<ITimeEntry[]>(`${this.API_BASE_URL}/admin/time-approval/reject`, { approveList: values }));
  }

  async adminApproveTimeEntries(date: string): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.patch<ITimeEntry[]>(`${this.API_BASE_URL}/admin/time-approval/admin-approval`, { date }));
  }

  async getAllUsers() {
    return firstValueFrom(this.httpClient.get<User[]>(`${this.API_BASE_URL}/admin/users/allUsers`));
  }

  async loginAsUser(id: number) {
    return firstValueFrom(this.httpClient.post<{token: string, user: User}>(`${this.API_BASE_URL}/admin/users/login-as-user`, {id}));
  }

  async resetUserPassword(data: {id: number, newPassword: string}) {
    return firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/admin/users/reset-user-password`, data));
  }

  async getAllAdditionalTime() {
    return firstValueFrom(this.httpClient.get<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time`));
  }

  async getAdditionalTimeByUser(id: number) {
    return firstValueFrom(this.httpClient.get<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time/user/${id}`));
  }

  async getAdditionalTimeByAccount(id: number) {
    return firstValueFrom(this.httpClient.get<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time/account/${id}`));
  }

  async getAdditionalTimeById(id: number) {
    return firstValueFrom(this.httpClient.get<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time/${id}`));
  }

  async addAdditionalHours(data: IAdditionalTime): Promise<IAdditionalTime>  {
    return firstValueFrom(this.httpClient.post<IAdditionalTime>(`${this.API_BASE_URL}/admin/additional-time`, data));
  }

  async getAdditionalHoursByAccountAndUserId(accountId: number, userId: number): Promise<IAdditionalTime[]>  {
    return firstValueFrom(this.httpClient.get<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time/user-account/${accountId}/${userId}`));
  }

  async getAdditionalHoursByAccountAndUserIdByCurrentWeek(accountId: number, userId: number): Promise<IAdditionalTime[]>  {
    return firstValueFrom(this.httpClient.get<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time/user-account-by-week/${accountId}/${userId}`));
  }

  async updateAdditionalHours(data: IAdditionalTime): Promise<IAdditionalTime>  {
    return firstValueFrom(this.httpClient.patch<IAdditionalTime>(`${this.API_BASE_URL}/admin/additional-time`, data));
  }

  async cancelAdditionalHours(id: number): Promise<IAdditionalTime>  {
    return firstValueFrom(this.httpClient.patch<IAdditionalTime>(`${this.API_BASE_URL}/admin/additional-time/cancel`, {id: id, canceled: true}));
  }

  async deleteAdditionalHours(id: number) {
    return firstValueFrom(this.httpClient.delete<IAdditionalTime>(`${this.API_BASE_URL}/admin/additional-time/${id}`));
  }

  async getAllAdditionalTimeByWeek(date: string): Promise<IAdditionalTime[]> {
    return firstValueFrom(this.httpClient.get<IAdditionalTime[]>(`${this.API_BASE_URL}/admin/additional-time/by-week/${date}`));
  }

  async getAllAdminApprovedTimeForWeek(date: string): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.get<ITimeEntry[]>(`${this.API_BASE_URL}/admin/invoices/week/${date}`));
  }

  async getAllAdminApprovedTimeForMonth(date: string): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.get<ITimeEntry[]>(`${this.API_BASE_URL}/admin/invoices/month/${date}`));
  }

  async getInvoicesByInvoiceNumber(invoiceNumber: number): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.get<ITimeEntry[]>(`${this.API_BASE_URL}/admin/invoices/by-invoice-number/${invoiceNumber}`));
  }

  async getInvoicedTimeEntriesByDateRange(lower: string, upper: string, period: string): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.get<ITimeEntry[]>(`${this.API_BASE_URL}/admin/invoices/history/${lower}/${upper}/${period}`));
  }

  async getAllAdminApprovedTimeNotInvoicedWithWeeklyInvoice(): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.get<ITimeEntry[]>(`${this.API_BASE_URL}/admin/invoices/not-invoiced-week`));
  }

  async getAllAdminApprovedTimeNotInvoicedWithMonthlyInvoice(): Promise<ITimeEntry[]> {
    return firstValueFrom(this.httpClient.get<ITimeEntry[]>(`${this.API_BASE_URL}/admin/invoices/not-invoiced-month`));
  }

  async invoiceTimeEntries(data: {id: number, invoice: number}[]): Promise<IAdditionalTime>  {
    return firstValueFrom(this.httpClient.patch<IAdditionalTime>(`${this.API_BASE_URL}/admin/invoices/invoice-time`, {shiftIds: data}));
  }

  async getInvoices(isHistory: boolean, invoicePeriod: string, startDate: string, endDate: string, invoiceNumber: string | null = null): Promise<ICustomerTimeEntries[]>  {
    return firstValueFrom(this.httpClient.get<ICustomerTimeEntries[]>
      (`${this.API_BASE_URL}/admin/invoices/get-invoices/${isHistory}/${invoicePeriod}/${startDate}/${endDate}/${invoiceNumber}`));
  }

  async exportInvoicesToQuickbooks(data: ICustomerTimeEntries[]): Promise<any[]>  {
    return firstValueFrom(this.httpClient.post<any[]>(`${this.API_BASE_URL}/admin/invoices/create-invoices-in-quickbooks`, data));
  }
  
  async createInvoiceActivity(): Promise<void> {
    return firstValueFrom(this.httpClient.get<void>(`${this.API_BASE_URL}/admin/invoices/create-activity`))
  }

  async getHighestInvoiceNumber(): Promise<number> {
    return firstValueFrom(this.httpClient.get<number>(`${this.API_BASE_URL}/admin/invoices/highest-invoice-number`));
  }

  async getAllAccountHistory(id: number): Promise<IAccountHistory[]> {
    return firstValueFrom(this.httpClient.get<IAccountHistory[]>(`${this.API_BASE_URL}/admin/accounts/account-history/all/${id}`));
  }
  
  async getAllAccountHistoryByLiaisonAccount(id: number): Promise<IAccountHistory[]> {
    return firstValueFrom(this.httpClient.get<IAccountHistory[]>(`${this.API_BASE_URL}/admin/accounts/account-history/all-by-liaison-account-id/${id}`));
  }

  async getCurrentAccountHistory(id: number): Promise<IAccountHistory> {
    return firstValueFrom(this.httpClient.get<IAccountHistory>(`${this.API_BASE_URL}/admin/accounts/account-history/current/${id}`));
  }

  async getAccountHistoryStartDateLiaisonAccountId(id: number): Promise<IAccountHistory> {
    return firstValueFrom(this.httpClient.get<IAccountHistory>(`${this.API_BASE_URL}/admin/accounts/account-history/by-liaison-account-id/${id}`));
  }

  async getAccountHistoryStartDateAccountId(id: number): Promise<IAccountHistory> {
    return firstValueFrom(this.httpClient.get<IAccountHistory>(`${this.API_BASE_URL}/admin/accounts/account-history/by-account-id/${id}`));
  }

  async postAccountHistory(data: IAccountHistory, updateLiaisonAccounts: boolean = true): Promise<IAccountHistory> {
    return firstValueFrom(this.httpClient.post<IAccountHistory>(`${this.API_BASE_URL}/admin/accounts/account-history?updateLiaisonAccounts=${updateLiaisonAccounts}`, data));
  }

  async postInactiveLiaisonAccount(id: number): Promise<IAccountHistory> {
    return firstValueFrom(this.httpClient.post<IAccountHistory>(`${this.API_BASE_URL}/admin/accounts/inactive-liaison-accounts`, {account_id: id}));
  }

  async updateAccountHistory(data: IAccountHistory, updateLiaisonAccounts: boolean = true): Promise<IAccountHistory> {
    return firstValueFrom(this.httpClient.patch<IAccountHistory>(`${this.API_BASE_URL}/admin/accounts/account-history?updateLiaisonAccounts=${updateLiaisonAccounts}`, data));
  }

  async deleteAccountHistory(id: number): Promise<IAccountHistory> {
    return firstValueFrom(this.httpClient.delete<IAccountHistory>(`${this.API_BASE_URL}/admin/accounts/account-history/${id}`));
  }

  async getAllHistoryByLiaisonAccount(id: number): Promise<ILiaisonAccountHistory[]> {
    return firstValueFrom(this.httpClient.get<ILiaisonAccountHistory[]>(`${this.API_BASE_URL}/admin/accounts/liaison-history/all/${id}`));
  }

  async getCurrentHistoryByLiaisonAccount(id: number): Promise<ILiaisonAccountHistory> {
    return firstValueFrom(this.httpClient.get<ILiaisonAccountHistory>(`${this.API_BASE_URL}/admin/accounts/liaison-history/${id}`));
  }

  async getHistoryByLiaisonAccountByDate(id: number, date: Date): Promise<ILiaisonAccountHistory> {
    return firstValueFrom(this.httpClient.get<ILiaisonAccountHistory>(`${this.API_BASE_URL}/admin/accounts/liaison-history/date/${id}/${date}`));
  }

  async getAllLiaisonAccountHistoryByAccountId(id: number): Promise<ILiaisonAccountHistory[]> {
    return firstValueFrom(this.httpClient.get<ILiaisonAccountHistory[]>(`${this.API_BASE_URL}/admin/accounts/liaison-history/by-account-id/${id}`));
  }

  async postHistoryByLiaisonAccount(data: ILiaisonAccountHistory): Promise<ILiaisonAccountHistory> {
    return firstValueFrom(this.httpClient.post<ILiaisonAccountHistory>(`${this.API_BASE_URL}/admin/accounts/liaison-history`, data));
  }

  async updateHistoryByLiaisonAccount(data: ILiaisonAccountHistory): Promise<ILiaisonAccountHistory> {
    return firstValueFrom(this.httpClient.patch<ILiaisonAccountHistory>(`${this.API_BASE_URL}/admin/accounts/liaison-history`, data));
  }

  async deleteHistoryByLiaisonAccount(id: number): Promise<ILiaisonAccountHistory> {
    return firstValueFrom(this.httpClient.delete<ILiaisonAccountHistory>(`${this.API_BASE_URL}/admin/accounts/liaison-history/${id}`));
  }

  async getWeeklySubmittedReports(
    userId: number,
    accountId: number
  ): Promise<LiaisonReport[]> {

    return firstValueFrom(this.httpClient.get<LiaisonReport[]>(`${this.API_BASE_URL}/reports/weekly-submitted/${userId}/${accountId}`));
  }

  async getWeeklyReportsForWeek(
    userId: number,
    accountId: number,
    date: string
  ): Promise<LiaisonReport[]> {
    return firstValueFrom(this.httpClient.get<LiaisonReport[]>(`${this.API_BASE_URL}/reports/weekly/${userId}/${accountId}/${date}`));
  }

  async getNewIssuesInWeek(userId: number, date: string) {
    return firstValueFrom(this.httpClient.get<LiaisonIssue[]>(`${this.API_BASE_URL}/issues/newIssues/${userId}/${date}`));
  }

  async initializeQuickbooks() {
    return firstValueFrom(this.httpClient.get<string>(`${this.API_BASE_URL}/quickbooks/initialize`));
  }

  async isValidQuickbooksToken() {
    return firstValueFrom(this.httpClient.get<boolean>(`${this.API_BASE_URL}/quickbooks/isValid`));
  }

  async refreshCurrentQuickbooksToken() {
    return firstValueFrom(this.httpClient.get<boolean>(`${this.API_BASE_URL}/quickbooks/refresh`));
  }

  async getActivities(page: number = 0, filter: AdminHomeFilter = null) {
    return firstValueFrom(this.httpClient.get<IActivity[]>(`${this.API_BASE_URL}/admin/activity/${page}/${JSON.stringify(filter)}`));
  }

  async processPayEntryCSV(data: string) {
    return firstValueFrom(this.httpClient.post<any>(`${this.API_BASE_URL}/admin/pay-entry`, {data}));
  }

  async getPushNotificationSettings(id: number) {
    return firstValueFrom(this.httpClient.get<User>(`${this.API_BASE_URL}/admin/push-notification-settings`));
  }

  async updatePushNotificationSettings(user: User) {
    return firstValueFrom(this.httpClient.patch<User>(`${this.API_BASE_URL}/admin/push-notification-settings`, user));
  }

  async getBusinessById(id: number) {
    return firstValueFrom(this.httpClient.get<IBusiness>(`${this.API_BASE_URL}/admin/businesses/${id}`));
  }

  async getAllBusinesses() {
    return firstValueFrom(this.httpClient.get<IBusiness[]>(`${this.API_BASE_URL}/admin/businesses`));
  }

  async createBusiness(data: IBusiness) {
    return firstValueFrom(this.httpClient.post<IBusiness>(`${this.API_BASE_URL}/admin/businesses`, data));
  }

  async updateBusiness(data: IBusiness) {
    return firstValueFrom(this.httpClient.patch<IBusiness>(`${this.API_BASE_URL}/admin/businesses`, data));
  }

  async deleteBusiness(id: number) {
    return firstValueFrom(this.httpClient.delete<IBusiness>(`${this.API_BASE_URL}/admin/businesses/${id}`));
  }

  async getLiaisonAccountCoverByLiaisonAccountId(id: number) {
    return firstValueFrom(this.httpClient.get<ILiaisonAccountCover[]>(`${this.API_BASE_URL}/admin/account-cover/${id}`));
  }

  async getLiaisonAccountCoverByUserIdAndAccountId(userId: number, accountId: number, startDate: string, endDate: string) {
    return firstValueFrom(this.httpClient.get<ILiaisonAccountCover[]>
      (`${this.API_BASE_URL}/admin/account-cover/${accountId}/${userId}/${startDate}/${endDate}`));
  }

  async createLiaisonAccountCover(data: ILiaisonAccountCover) {
    return firstValueFrom(this.httpClient.post<ILiaisonAccountCover>(`${this.API_BASE_URL}/admin/account-cover`, data));
  }

  async updateLiaisonAccountCover(data: ILiaisonAccountCover) {
    return firstValueFrom(this.httpClient.patch<ILiaisonAccountCover>(`${this.API_BASE_URL}/admin/account-cover`, data));
  }

  async deleteLiaisonAccountCover(id: number) {
    return firstValueFrom(this.httpClient.delete<ILiaisonAccountCover>(`${this.API_BASE_URL}/admin/account-cover/${id}`));
  }

  async sendWelcomeEmail(data: {email: string}) {
    return firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/email/welcome`, data));
  }

  async getWeeklyNoteByUserAccountDate(userId: number, accountId: number, week: number): Promise<any>  {
    return firstValueFrom(this.httpClient.get<IWeeklyNote>(`${this.API_BASE_URL}/admin/notes/get-admin-notes/${userId}/${accountId}/${week}`));
  }

  async updateWeeklyNote(data: IWeeklyNote): Promise<any>  {
    return firstValueFrom(this.httpClient.post<IWeeklyNote>(`${this.API_BASE_URL}/admin/notes/update-admin-note`, data));
  }

  async manualQuickbooksDatabaseSync(): Promise<any>  {
    return firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/quickbooks/database-sync`, {}));
  }

  async createOrUpdateCustomerContactAccountInvoiceSettings(data: ICustomerContactAccountInvoice[]): Promise<ICustomerContactAccountInvoice[]>  {
    return firstValueFrom(this.httpClient.post<ICustomerContactAccountInvoice[]>(`${this.API_BASE_URL}/admin/contacts/create-or-update-customer-contact-invoice-settings`, data));
  }

  async createOrUpdateCustomerContactAccountAccess(data: ICustomerContactAccountInvoice[]): Promise<ICustomerContactAccountInvoice[]>  {
    return firstValueFrom(this.httpClient.post<ICustomerContactAccountInvoice[]>(`${this.API_BASE_URL}/admin/contacts/create-or-update-customer-contact-access`, data));
  }
  
  async generateAdminReport(reportType: ReportType, optionsValue: any) {
    var options = JSON.parse(JSON.stringify(optionsValue));
    // Needed to reduce payload to server
   for (const [key, value] of Object.entries(options)) {
      const data = options[key];

      if (data.type === 'select') {
        data.select = [];
      }
    }

    return firstValueFrom(this.httpClient.post<IProcessedReport>(`${this.API_BASE_URL}/admin/report/generate-report`, {reportType, options}));
  }
}
