import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { RouterModule } from "@angular/router";
import { AppUIModule } from "../../../shared/ui/app-ui.module";
import { ReactiveFormsModule } from "@angular/forms";

import { FilterAssignedRepsPipe } from "../pipes/filter-assigned-reps.pipe";
import { ActiveFilterPipe } from "../pipes/active-filter.pipe";
import { FilterAssignedContactsPipe } from "../pipes/filter-assigned-contacts.pipe";

import { NewAccountWalkthroughPopupComponent } from "../components/new-account-walkthrough/new-account-walkthrough.component";
import { OvertimeRulesPopupComponent } from "../components/overtime-rules/overtime-rules.component";
import { EditAccountWalkthroughPopupComponent } from "../components/edit-account-walkthrough/edit-account-walkthrough.component";
import { ChooseAccountPopupComponent } from "../components/choose-account/choose-account.component";
import { LiaisonAccountHistoryComponent } from "../components/account-history/account-history.component";
import { BrowserModule } from '@angular/platform-browser';
import { AccountContainerComponent } from "../components/account-container/account-container.component";
import { FilterAccountByCustomerPipe } from "../pipes/filter-accounts-by-customer.pipe";
import { FilterAccountByLiaisonPipe } from "../pipes/filter-accounts-by-liaison.pipe";
import { DynamicPipe } from "../pipes/dynamic.pipe";
import { LiaisonAssignmentPopupComponent } from "../components/liaison-assignment/liaison-assignment.component";
import { NgSelectModule } from '@ng-select/ng-select';

import { MaskService, NgxMaskModule } from 'ngx-mask'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgxMaskModule,
    ReactiveFormsModule,
    AppUIModule,
    NgSelectModule
  ],
  declarations: [
    NewAccountWalkthroughPopupComponent,
    EditAccountWalkthroughPopupComponent,
    LiaisonAssignmentPopupComponent,
    FilterAssignedContactsPipe,
    FilterAssignedRepsPipe, 
    ActiveFilterPipe,
    OvertimeRulesPopupComponent,
    ChooseAccountPopupComponent,
    LiaisonAccountHistoryComponent,
    AccountContainerComponent,
    FilterAccountByCustomerPipe,
    FilterAccountByLiaisonPipe,
    DynamicPipe
  ],
  exports: [
    NewAccountWalkthroughPopupComponent,
    EditAccountWalkthroughPopupComponent,
    LiaisonAssignmentPopupComponent,
    FilterAssignedContactsPipe,
    FilterAssignedRepsPipe, 
    ActiveFilterPipe,
    OvertimeRulesPopupComponent,
    ChooseAccountPopupComponent,
    LiaisonAccountHistoryComponent,
    AccountContainerComponent,
    FilterAccountByCustomerPipe,
    FilterAccountByLiaisonPipe,
    DynamicPipe
  ],
  providers: [ MaskService ]
})
export class AdminComponentsModule {}
