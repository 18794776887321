import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "refresh",
})
export class RefreshPipe implements PipeTransform {
    transform(value: any[], trigger: number): any[] {
        // Refreshes list when trigger is hit
        return value;
    }
}