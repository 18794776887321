<div class="modal-container">
  
    <ion-row class="circle-option-row">
      <ng-container *ngFor="let option of options">
          <ion-col class="width-33" *ngIf="option.row === 1">
            <ion-row>
              <ion-col class="img-col ion-no-padding">
                <div (click)="routeTo(option.route, modal)" class="img-div">
                  <img [src]="'../../../../assets/icons/' + option.icon + '.svg'" />
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-no-padding">
                <span>{{option.title}}</span>
              </ion-col>
            </ion-row>
          </ion-col>
      </ng-container>
    </ion-row>

    <ion-row class="circle-option-row">
      <ng-container *ngFor="let option of options">
          <ion-col class="width-33" *ngIf="option.row === 2">
            <ion-row>
              <ion-col class="img-col ion-no-padding">
                <div (click)="routeTo(option.route, modal)" class="img-div">
                  <img [src]="'../../../../assets/icons/' + option.icon + '.svg'" />
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-no-padding">
                <span>{{option.title}}</span>
              </ion-col>
            </ion-row>
          </ion-col>
      </ng-container>
    </ion-row>

    <ion-row class="bottom-row">
      <ng-container *ngFor="let option of options">
          <ion-col class="width-50" *ngIf="option.row === 3">
            <div (click)="routeTo(option.route, modal)" class="bottom-buttom-div">
              <div class="img-div">
                <img [src]="'../../../../assets/icons/' + option.icon + '.svg'" />
              </div>
              <div class="span-div">
                <span>{{option.title}}</span>
              </div>
            </div>
          </ion-col>
      </ng-container>
    </ion-row>

</div>