import { LiaisonIssue } from "./liaison-issue.model";

export class LiaisonIssueUpdate {
  id?: number;
  daily_report_id: number;
  issue_id?: number;
  content: string = "";
  time_created?: string = new Date().toString();
  created_by?: number;
  is_local: boolean = false;
  is_closing_update?: boolean = false;
  is_action_taken?: boolean = false;
  issues?: LiaisonIssue = null;
  clean_point_required: boolean = null;
  parts_found: number = null;
  area_found: string = null;
  clean_point_date: string = null;
  issue_serial_numbers?: {id: number, issue_update_id: number, serial_number: string}[];
  serial_numbers?: string[];
}
