import { Component, Input, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { LiaisonStateService } from "../../../features/liaison/services/liaison-state-service";
import { LiaisonApiService } from "../../../../app/features/liaison/services/liaison-api-service";
import { SharedService } from "../../services/shared.service";
import { LiaisonReport } from "../../models/liaison-report.model";

@Component({
  selector: "app-report-sort-footer",
  templateUrl: "report-sort-footer.component.html",
  styleUrls: ["./report-sort-footer.component.scss"],
  providers: [LiaisonStateService, LiaisonApiService],
})
export class ReportSortFooterComponent implements OnInit {

  @Input() reportCount: number = null;

  subAmount: number = 0;

  constructor(
    private router: Router,
    private liaisonStateService: LiaisonStateService,
    private liaisonApiService: LiaisonApiService,
    private sharedService: SharedService
  ) {

  }

  ngOnInit(): void {
    if (this.reportCount === null) {
      this.getActiveUnsentReport();
    }
  }

  async getActiveUnsentReport(): Promise<void> {
    const accountId: number = this.liaisonStateService.getActiveAccountId();
    const allReports = await this.liaisonApiService.getReportsInProgressByAccountAndUser(accountId, this.liaisonStateService.getUserId()) ?? [];

    this.reportCount = allReports?.filter(item => this.isValidUnsentReport(item))?.length ?? 0;
  }

  isValidUnsentReport(item: LiaisonReport): boolean {
    return ((item.daily_activity && (item.daily_activity?.length > 0)) || (item.scrap_count > 0) || (item.production_count > 0) || (item.issues_issues_closed_report_idTodaily_reports?.length > 0) ||
    (item.issues_issues_initial_report_idTodaily_reports?.length > 0) || (item.issue_updates?.length > 0)) && !item.deleted
}

  navigateToAccounts() {
    this.sharedService.hapticsImpactLight();
    this.router.navigate(["/liaison/home"]);
  }

  navigateToReview() {
    this.sharedService.hapticsImpactLight();
    this.router.navigate(["/liaison/select-reports", this.liaisonStateService?.getActiveAccountId()]);
  }

  navigateToTime() {
    this.sharedService.hapticsImpactLight();
    this.router.navigate(["/liaison/time"]);
  }
}
