import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Platform } from "@ionic/angular";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { SharedService } from "../../../../services/shared.service";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-barcode-manual-entry",
  templateUrl: "./manual-entry.component.html",
  styleUrls: ["./manual-entry.component.scss"],
})
export class BarcodeManualEntryComponent implements OnInit {

    barcode: string = null;

    constructor(
        private sharedService: SharedService,
        private platform: Platform,
        private modalController: ModalController
    ) {

    }

    ngOnInit(): void {
        return;
    }

    close(fromCloseIcon: boolean = false): void {
        this.modalController.dismiss(fromCloseIcon ? null : this.barcode);
    }

}
