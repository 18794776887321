import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { IAdditionalTime } from "../../../../../../../app/shared/models/additional-time.model";

@Component({
  selector: "manual-approval-modal",
  templateUrl: "manual-approval.component.html",
  styleUrls: ["./manual-approval.component.scss"],
})
export class ManualApprovalComponent implements OnInit {
  
    additionalTime: IAdditionalTime = null;

    approverName: string = null;
    approvedMethod: string = null;
    approvalDate: string = null;

    methods: string[] = [
        'Text',
        'Email',
        'WhatsApp'
    ];

    constructor(
        private modalController: ModalController
    ) {

    }

    ngOnInit(): void {
        this.setDefaultDate();
    }

    approveRequest(): void {
        this.modalController.dismiss({name: this.approverName, method: this.approvedMethod.trim(), date: this.approvalDate});
    }


    close(): void {
        this.modalController.dismiss();
    }

    setDefaultDate(): void {
        const date = new Date();
        this.approvalDate = `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' : ''}${(date.getMonth() + 1)}-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    }


}
