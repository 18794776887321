<ion-grid>
  <ion-row class="header-row">
    <ion-col>
      <span class="modal-header-text">{{contact.email}}</span>
    </ion-col>
    <ion-col size="auto">
      <ion-icon
        class="cross"
        (click)="closeModal()"
        name="close-outline"
      ></ion-icon>
    </ion-col>
  </ion-row>
  <!-- <ion-row>
    <ion-col>
      <span>{{contact.email}}</span>
    </ion-col>
  </ion-row> -->
  <ion-row>
    <ion-col>
      <ion-button (click)="removeContact()">Remove Recipient</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
