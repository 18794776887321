import { Pipe, PipeTransform } from "@angular/core";
import { SharedService } from "../services/shared.service";

@Pipe({
  name: "orderByNameUsers",
})
export class OrderByNameUsersPipe implements PipeTransform {

    constructor(private sharedService: SharedService) {}

    transform(value: any[]): any[] {
        return this.sharedService.sortFirstNameLastName(value);

    }
}