import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { LiaisonAccount } from "../../models/liaison-account.model";
import { LiaisonShift } from "../../models/liaison-shifts.model";
import { Contact } from "../../models/contact.model";
import { ModalController } from "@ionic/angular";
import { AccountInfoComponent } from "../account-info/account-info.component";
import { ActionSheetController } from '@ionic/angular';
import { IAdditionalTime } from "../../models/additional-time.model";
import { AdminApiService } from "../../../../app/features/admin/services/admin-api-service";
import { LiaisonStateService } from "../../../../app/features/liaison/services/liaison-state-service";
import { ViewWillEnter } from "@ionic/angular";
import { PendingHoursComponent } from "../../../../app/features/liaison/pages/liaison-time/components/pending-hours/pending-hours.component";

@Component({
  selector: "account-info-header",
  templateUrl: "account-info-header.component.html",
  styleUrls: ["./account-info-header.component.scss"],
  providers: [AdminApiService, LiaisonStateService]
})
export class AccountInfoHeaderComponent {

    @Input() isLoaded: boolean = false;
    @Input() account: LiaisonAccount = null;
    @Input() totalHours: number = null;
    @Input() baseHours: number = null;
    @Input() primaryContact: Contact = null;
    @Input() totalAdditionalHours: number = 0;
    @Input() totalPendingAdditionalHours: number = 0;
    @Input() additionalTimeArray?: any[] = [];
    @Input() navRoute: string = '/liaison/home';
    @Input() timePage: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modalController: ModalController,
        private actionSheetController: ActionSheetController,
        private adminApiService: AdminApiService,
        private liaisonStateService: LiaisonStateService
    ) {}

    async openAccountInfo(): Promise<void> {
        const modal = await this.modalController.create({
            component: AccountInfoComponent,
            componentProps: {
                accountId: this.account.id,
            },
            cssClass: "min-width-modal",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });
        await modal.present();
        await modal.onDidDismiss();
    }

    async contactPopup(): Promise<void> {
        if (this.primaryContact) {
            const actionSheet = this.actionSheetController.create({
                header: `Contact ${this.primaryContact?.first_name} ${this.primaryContact?.last_name ?? ''}`,
                buttons: [
                    {
                        text: `Call: ${this.primaryContact?.phone ?? 'Not Available'}`,
                        handler: () => {}
                    },
                    {
                        text: `Text: ${this.primaryContact?.phone ?? 'Not Available'}`,
                        handler: () => {}
                    },
                    {
                        text: `Email: ${this.primaryContact?.email ?? 'Not Available'}`,
                        handler: () => {}
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        data: {
                            action: 'cancel'
                        }
                    }
                ]
            });
    
            (await actionSheet).present();
        }
    }

    async viewPendingHours(): Promise<void> {
        if (!((this.totalHours > (this.baseHours + this.totalAdditionalHours)) && !this.timePage)) {
            return;
        }

        const createModal = await this.modalController.create({
          component: PendingHoursComponent,
          cssClass: "min-width-modal",
          showBackdrop: true,
          componentProps: {
            additionalTimeArray: this.additionalTimeArray.filter(a => !a.approved && !a.rejected)
          },
          backdropDismiss: true,
          keyboardClose: true,
          swipeToClose: true,
        });
    
        await createModal.present();
        const { data } = await createModal.onDidDismiss();

    }

}