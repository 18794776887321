import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-calendar-popover',
  templateUrl: './calendar-popover.component.html',
  styleUrls: [
    './calendar-popover.component.scss'
  ]
})
export class CalendarPopoverComponent {

  date: Date = null;
  dateFilter: any;
  highlightedDates: any;

  constructor(
    private popoverController: PopoverController
  ) {

  }

  change(newValue: Date): void {
    this.date = newValue;
    this.popoverController.dismiss(this.date);
  }

  close() {
    this.popoverController.dismiss();
  }


}
