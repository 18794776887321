import { Pipe, PipeTransform } from "@angular/core";
import { IAccount } from "../models/account.model";

@Pipe({
  name: "filterAccountSearch",
})
export class FilterAccountSearchPipe implements PipeTransform {
    transform(value: IAccount[], searchValue: string): IAccount[] {
        return value.filter(v => v.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
}