import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LiaisonApiService } from "../../../../../../../app/features/liaison/services/liaison-api-service";
import { LiaisonStateService } from "../../../../../../../app/features/liaison/services/liaison-state-service";
import { Router } from "@angular/router";
import { ILiaisonContact } from "../../../../../../../app/shared/models/liaison-contact.model";

@Component({
  selector: "edit-contact-component",
  templateUrl: "edit-contact.component.html",
  styleUrls: ["./edit-contact.component.scss"],
  providers: [LiaisonApiService],
})
export class EditContactComponent implements OnInit {

    contact: ILiaisonContact = null;

    constructor(
        private modalCtrl: ModalController,
    ) {}

    ngOnInit(): void {

    }

    removeContact(): void {
        this.modalCtrl.dismiss(true);
    }

    async closeModal() {
        this.modalCtrl.dismiss(false);
    }
}
