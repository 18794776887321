import { Pipe, PipeTransform } from "@angular/core";
import { User } from "../../../shared/models/user.model";
import { ILiaisonAccount } from "../../../shared/models/liaison-account.model";
import { SharedService } from "../../../shared/services/shared.service";

@Pipe({
    name: 'filterAssignedReps'
})

export class FilterAssignedRepsPipe implements PipeTransform {

    constructor(private sharedService: SharedService) {}

    transform(items: User[], reps: ILiaisonAccount[], userId: number, manualUpdate?: number) {

        return this.sharedService.sortFirstNameLastName<User>(items.filter(i => (!reps.map(r => r.user_id).includes(i.id)) || (i.id === userId)));

    }
}