<ion-content>
    <ion-datetime
          presentation="date"
          [isDateEnabled]="dateFilter"
          (ngModelChange)="change($event)"
          [ngModel]="date"
    ></ion-datetime>
</ion-content>


<!-- (ionChange)="monitorDateEntry($event)" -->