import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { Camera, CameraResultType, CameraSource, GalleryPhoto, Photo}  from '@capacitor/camera';
import { Platform } from '@ionic/angular';
import { SharedService } from '../../services/shared.service';
import { decode } from 'base64-arraybuffer';
import { IAttachment, IIssueAttachment, IRejectAttachment, IReportAttachment } from '../../models/attachment.model';
import { ModalController } from '@ionic/angular';
import { PreviewAttachmentComponent } from '../preview-attachment/preview-attachment.component';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage'; 
import { finalize, timeout, catchError } from "rxjs/operators";
import { firstValueFrom, of } from 'rxjs';
import { AttachmentUploadService } from '../../services/attachment-upload.service';
import { Output, EventEmitter } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { CacheInterceptor } from '../../services/cache.service';
import { IFirebaseUploadObject, IOfflineIdentifierData } from '../../models/cache.model';
import { Share } from '@capacitor/share';


@Component({
    selector: 'app-attachment-upload',
    templateUrl: 'attachment-upload.component.html',
    styleUrls: ['./attachment-upload.component.scss']
    
})
export class AttachmentUploadComponent implements OnInit {

    @ViewChild('accordion') accordion: any;

    @Input() attachments: IAttachment[] = [];
    @Input() context: string = null;
    @Input() id: number = null;
    @Input() idList: number[] = null;
    @Input() showButtons: boolean = true;
    @Input() showDelete: boolean = true;
    @Input() hasPadding: boolean = true;
    @Input() paddingAmount: string = '20px';
    @Input() startHidden: boolean = false;
    @Input() showAttachmentCount: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() condensed: boolean = false;
    @Input() openAccordionOnUpload: boolean = false;
    @Input() imageUIOnly: boolean = false;
    @Input() excludeExistingAttachments: boolean = false;
    @Input() slimUploadButton: boolean = false;
    @Input() onlyShowButtonOnZeroAttachments: boolean = false;

    @Output() generateId = new EventEmitter<{}>();
    @Output() attachmentDeleted = new EventEmitter<IAttachment>();

    unsyncedAttachments: any[] = [];
    loadingAttachment: any[] = [];
 
    filePath: string = 'Uploads';

    constructor(
        private platform: Platform,
        private sharedService: SharedService,
        private modalController: ModalController,
        private storage: AngularFireStorage,
        private attachmentUploadService: AttachmentUploadService,
        private actionSheetController: ActionSheetController,
        private cacheInterceptor: CacheInterceptor
    ) { }

    async ngOnInit(): Promise<void> {
        await this.getInitialAttachments();
    }

    async getInitialAttachments(): Promise<void> {
        if (this.context === 'report' && this.id && !this.excludeExistingAttachments) {
            this.attachments = await this.attachmentUploadService.getReportAttachment(this.id);
        } else if (this.context === 'issue' && this.id && !this.excludeExistingAttachments) {
            this.attachments = await this.attachmentUploadService.getIssueAttachment(this.id);
        } else if (this.context === 'reject' && this.id && !this.excludeExistingAttachments) {
            this.attachments = await this.attachmentUploadService.getRejectAttachment(this.id);
        } else if (this.context === 'reject' && this.idList && !this.excludeExistingAttachments) {
            this.idList.forEach(async (innerId) => {
                const rejectAttachments: IAttachment[] = await this.attachmentUploadService.getRejectAttachment(innerId);
                if (rejectAttachments) {
                    rejectAttachments.forEach((attachment: IAttachment) => {
                        this.attachments.push(attachment);
                    });
                }
            })
        }
    }

    async pickImagesForUpdate(): Promise<void> {
        const options = {
            quality: 90,
            limit: 5 // Set limit for number of images
        };
    
        const { photos } = await Camera.pickImages(options);

        // Convert each GalleryPhoto into a Blob and upload
        for (const photo of photos) {
            const blob = await this.convertGalleryPhotoToBlob(photo);

            if (blob) {
                // Should cap the size of an image for offline mode
                this.loadingAttachment.push({loading: true});
                if (this.openAccordionOnUpload && this.accordion) {
                    this.accordion.open();
                }
                this.firebaseUpload(blob); // Upload each blob
            }
        }
    }

    async convertGalleryPhotoToBlob(photo: GalleryPhoto): Promise<Blob> {
        try {
            // You can use the base64 representation or fetch the image from the webPath URL.
            const response = await fetch(photo.webPath); // Fetch the image using webPath
            const blob = await response.blob(); // Convert to blob
            return blob;
        } catch (error) {
            console.error('Error converting photo to Blob', error);
            return null;
        }
    }

    async processUpload(source: CameraSource): Promise<void> {

        const permissions = await Camera.checkPermissions();

        if (permissions.photos === 'denied' && source === CameraSource.Photos) {
            await Camera.requestPermissions({permissions: ['photos']});
        }

        if (permissions.camera === 'denied' && source === CameraSource.Camera) {
            await Camera.requestPermissions({permissions: ['camera']});
        }

        const base64Image: Photo = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            quality: 50,
            source: source,
            allowEditing: true,
        });

        // Should cap the size of an image for offline mode
        if (this.cacheInterceptor.isOffline) {
            const identifier: string = await this.cacheInterceptor.createFirebaseCacheObject(base64Image.base64String,
                `${this.filePath}/${this.context}-${new Date().getTime()}`,
                `image/${base64Image.format}`);
            this.unsyncedAttachments.push({
                images: {
                    image_url: `data:image/${base64Image.format};base64,${base64Image.base64String}`
                },
                offlineIdentifier: identifier
            });
        } else {
            this.loadingAttachment.push({loading: true});
            if (this.openAccordionOnUpload && this.accordion) {
                this.accordion.open();
            }
            const blob = new Blob([new Uint8Array(decode(base64Image.base64String))], {
                type: `image/${base64Image.format}`
            });
    
            this.firebaseUpload(blob, source);
        }
    } 

    firebaseUpload(blob: Blob, source?: CameraSource): void {
        const fileName = `${this.context}-${new Date().getTime()}`
        const fullPath = `${this.filePath}/${fileName}`;
        const fileRef = this.storage.ref(fullPath);
        const task = this.storage.upload(fullPath, blob);

        task.snapshotChanges()
            .pipe(
                timeout(10000),
                catchError((error) => {
                    this.sharedService.presentToast('danger', 'Attachment failed to upload. This can be due to low connectivity issues.', 'Upload Failed!', 'long');
                    task.cancel();
                    
                    if (this.loadingAttachment.length > 0) {
                        this.loadingAttachment.splice(0, 1);
                    }

                    return of(null);
                }),
                finalize(() => {
                    const getURL = fileRef.getDownloadURL().subscribe(async (downloadURL) => {
                        if (downloadURL) {
                            if (this.context === 'report') {
                                await this.handleReportUpload(downloadURL);
                            } else if (this.context === 'issue') {
                                await this.handleIssueUpload(downloadURL);
                            } else if (this.context === 'reject') {
                                await this.handleRejectUpload(downloadURL);
                            }
                        }

                        if (this.loadingAttachment.length > 0) {
                            this.loadingAttachment.splice(0, 1);
                        }

                        this.sharedService.presentToast('primary', 'Attachment has been uploaded successfully.', 'Success!', 'med');
                    });
                })
            ).subscribe();
    }

    async handleReportUpload(downloadURL: string, offlineIdentifier?: string, offlineFirebaseIdentifier?: string): Promise<void> {
        if (this.id || offlineIdentifier) {
            const newAttachment: IReportAttachment = {
                userId: Number(this.sharedService.localStorageGet('userId')),
                imageUrl: downloadURL,
                reportId: this.id
            };

            const offlineIdentifierObject: IOfflineIdentifierData = (offlineIdentifier || offlineFirebaseIdentifier) ?
                {parentIdentifier: offlineIdentifier, replacementField: 'reportId', firebaseIdentifier: offlineFirebaseIdentifier} :
                null;
    
            const attachment = await this.attachmentUploadService.postReportAttachment(newAttachment, offlineIdentifierObject);
            this.attachments.push(attachment);
        } else {
            const unsyncedAttachment = {
                images: {
                    image_url: downloadURL
                }
            }
            this.unsyncedAttachments.push(unsyncedAttachment);
        }

        if (this.openAccordionOnUpload && this.accordion) {
            this.accordion.open();
        }
    }

    async handleIssueUpload(downloadURL: string, offlineIdentifier?: string, offlineFirebaseIdentifier?: string): Promise<void> {
        if (this.id || offlineIdentifier) {
            const newAttachment: IIssueAttachment = {
                userId: Number(this.sharedService.localStorageGet('userId')),
                imageUrl: downloadURL,
                issueId: this.id
            };

            const offlineIdentifierObject: IOfflineIdentifierData = (offlineIdentifier || offlineFirebaseIdentifier) ?
                {parentIdentifier: offlineIdentifier, replacementField: 'issueId', firebaseIdentifier: offlineFirebaseIdentifier} : 
                null;
    
            const attachment = await this.attachmentUploadService.postIssueAttachment(newAttachment, offlineIdentifierObject);
            this.attachments.push(attachment);
        } else {
            const unsyncedAttachment = {
                images: {
                    image_url: downloadURL
                }
            }
            this.unsyncedAttachments.push(unsyncedAttachment);
        }

        if (this.openAccordionOnUpload && this.accordion) {
            this.accordion.open();
        }
    }

    async handleRejectUpload(downloadURL: string, offlineIdentifier?: string, offlineFirebaseIdentifier?: string): Promise<void> {
        if (this.id || offlineIdentifier) {
            const newAttachment: IRejectAttachment = {
                userId: Number(this.sharedService.localStorageGet('userId')),
                imageUrl: downloadURL,
                rejectId: this.id
            };

            const offlineIdentifierObject: IOfflineIdentifierData = (offlineIdentifier || offlineFirebaseIdentifier) ?
                {parentIdentifier: offlineIdentifier, replacementField: 'rejectId', firebaseIdentifier: offlineFirebaseIdentifier} : 
                null;
    
            const attachment = await this.attachmentUploadService.postRejectAttachment(newAttachment, offlineIdentifierObject);
            this.attachments.push(attachment);
        } else {
            const unsyncedAttachment = {
                images: {
                    image_url: downloadURL
                }
            };
            this.unsyncedAttachments.push(unsyncedAttachment);
        }

        if (this.openAccordionOnUpload && this.accordion) {
            this.accordion.open();
        }
    }

    async refreshAttachmentData(): Promise<void> {
        await this.getInitialAttachments();
    }

    uploadFromGallery(): void {
        if (!this.id) {
            this.generateId.emit({});
        }
        
        // this.processUpload(CameraSource.Photos);
        this.pickImagesForUpdate();
    }

    uploadFromCamera(): void {
        if (this.platform.is('capacitor')) {
            this.processUpload(CameraSource.Camera);
        } else {
            this.sharedService.presentToast('danger', '', 'Feature not available on current platform.', 'med');
        }
    }

    async presentActionSheet() {
        const actionSheet = this.actionSheetController.create({
            header: 'Add Attachment',
            buttons: [
                {
                    text: 'Take Photo',
                    handler: () => this.uploadFromCamera(),
                    cssClass: 'photo-button'
                },
                {
                    text: 'Existing Photo',
                    handler: () => this.uploadFromGallery(),
                    cssClass: 'photo-button'
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'cancel-button',
                    data: {
                        action: 'cancel'
                    }
                }
            ]
        });

        (await actionSheet).present();
    }

    async previewAttachment(attachment: IAttachment): Promise<void> {
        const createModal = await this.modalController.create({
            component: PreviewAttachmentComponent,
            cssClass: 'min-width-modal grey-background',
            showBackdrop: true,
            componentProps: {
                attachment: attachment
            },
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true
        });

        await createModal.present();
        const { data } = await createModal.onDidDismiss();
    }

    async removeAttachment(attachment: IAttachment, partOfSync: boolean = false, showToaster: boolean = true): Promise<void> {
        if (!partOfSync) {
            await firstValueFrom(this.storage.refFromURL(attachment.images.image_url).delete());
            const index = this.attachments.indexOf(attachment);

            if (index !== -1) {
                this.attachments.splice(index, 1);
                this.attachmentDeleted.emit(attachment);
    
                if (attachment.image_id) {
                    await this.attachmentUploadService.deleteAttachment(attachment.image_id);
                    if (showToaster) {
                        this.sharedService.presentToast('primary', 'Attachment has been removed successfully.', 'Success!', 'med');
                    } 
                }
            } else {
                if (showToaster) {
                    this.sharedService.presentToast('danger', 'Attachment Failed to be removed successfully.', 'Failed!', 'med');
                } 
            }
        } else {
            const unsyncedIndex = this.unsyncedAttachments.indexOf(attachment);

            if (unsyncedIndex !== -1) {
                this.unsyncedAttachments.splice(unsyncedIndex, 1);
                this.attachmentDeleted.emit(attachment);
                if (showToaster) {
                    this.sharedService.presentToast('primary', 'Attachment has been removed successfully.', 'Success!', 'med');
                } 
            } else {
                if (showToaster) {
                    this.sharedService.presentToast('danger', 'Attachment Failed to be removed successfully.', 'Failed!', 'med');
                } 
            }
        }

    }

    async deleteAllAttachments(): Promise<void> {
        (this.getAllAttachments() ?? []).forEach(async a => {
            await this.removeAttachment(a, false, false);
        });
    }

    async downloadAttachment(attachment: IAttachment): Promise<void> {
        // TO DO: will need to form base64 from actual returned url
        const url = await firstValueFrom(this.storage.refFromURL(attachment.images.image_url).getDownloadURL());
    }

    getAllAttachments(): any[] {
        return [...this.loadingAttachment, ...this.attachments.concat(this.unsyncedAttachments)];
    }

    getAttachmentLength(): number {
        const attachmentLength: number = this.attachments?.length ?? 0;
        const unsyncedAttachmentLength: number = this.unsyncedAttachments?.length ?? 0;
        const loadingAttachmentLength: number = this.loadingAttachment?.length ?? 0;

        return attachmentLength + unsyncedAttachmentLength + loadingAttachmentLength;
    }

    async manualSync(id: number, uniqueOfflineIdentifier?: string): Promise<void> {
        if (this.unsyncedAttachments.length > 0) {
            if (id || uniqueOfflineIdentifier) {
                this.id = id;
                this.unsyncedAttachments.forEach(downloadURL => {
                    if (this.context === 'report') {
                        this.handleReportUpload(downloadURL.images.image_url, uniqueOfflineIdentifier ?? null, downloadURL.offlineIdentifier ?? null);
                        //this.removeAttachment(downloadURL);
                    } else if (this.context === 'issue') {
                        this.handleIssueUpload(downloadURL.images.image_url, uniqueOfflineIdentifier ?? null, downloadURL.offlineIdentifier ?? null);
                        //this.removeAttachment(downloadURL);
                    } else if (this.context === 'reject') {
                        this.handleRejectUpload(downloadURL.images.image_url, uniqueOfflineIdentifier ?? null, downloadURL.offlineIdentifier ?? null);
                        //this.removeAttachment(downloadURL, true);
                    }
                });
            }
        }
    }

    async addAttachmentsById(context: string, id: number, idList: number[] = []): Promise<void> {
        if (context === 'issue') {
            const issueAttachments: IAttachment[] = await this.attachmentUploadService.getIssueAttachment(id);
            if (issueAttachments) {
                issueAttachments.forEach((attachment: IAttachment) => {
                    this.attachments.push(attachment);
                });
            }
        }

        if (context === 'reject') {
            const rejectAttachments: IAttachment[] = await this.attachmentUploadService.getRejectAttachment(id);
            if (rejectAttachments) {
                rejectAttachments.forEach((attachment: IAttachment) => {
                    this.attachments.push(attachment);
                });
            }
        }
    }

    // ngOnDestroy(): void {
    //     if (this.unsyncedAttachments.length > 0) {
    //         if (this.id) {
    //             this.unsyncedAttachments.forEach(downloadURL => {
    //                 if (this.context === 'report') {
    //                     this.handleReportUpload(downloadURL);
    //                 } else if (this.context === 'issue') {
    //                     this.handleIssueUpload(downloadURL);
    //                 } else if (this.context === 'reject') {
    //                     this.handleRejectUpload(downloadURL);
    //                 }
    //             });
    //         }
    //     }
    // }

}