import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ModalController } from '@ionic/angular';
import { IAttachment } from '../../models/attachment.model';
import { PreviewAttachmentComponent } from '../preview-attachment/preview-attachment.component';
import { IDefect } from '../../../../app/features/liaison/models/daily-report.model';
import { Reject } from '../../models/liaison-sort.model';

@Component({
    selector: 'reject-defect-report-component',
    templateUrl: 'reject-defect-report.component.html',
    styleUrls:['./reject-defect-report.component.scss']
})

export class RejectDefectReportComponent implements OnInit {
    
    @Input() defect: IDefect = null;
    @Input() rejects: Reject[] = [];

    constructor(
        private sharedService: SharedService,
        private modalController: ModalController
    ) {

    }

    ngOnInit(): void {
        console.log(this.defect)
    }

    closeModal(): void {
        this.modalController.dismiss();
    }


}