import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'activeFilter'
})

export class ActiveFilterPipe implements PipeTransform {

    transform(items: any[]) {
        
        if (!items || items.length <= 0) {
            return [];
        }

        if (items[0].hasOwnProperty('active')) {
            return items.filter(i => i.active);
        } else if (items[0].hasOwnProperty('is_active')) {
            return items.filter(i => i.is_active);
        } else {
            return [];
        }

    }
    
}