<ion-grid>
    <ion-row>
      <ion-col>
        <span class="modal-header-text">External Approval</span>
      </ion-col>
      <ion-col size="auto">
        <ion-icon class="cross" (click)="close()" name="close-outline"></ion-icon>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="entry-column">
        <div class="label">
          <span>Name of Approver</span>
        </div>
        <ion-input fill="solid" type="text" placeholder="" [(ngModel)]="approverName"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
        <ion-col class="entry-column">
          <div class="label">
            <span>Date of Approval</span>
          </div>
          <ion-input fill="solid" type="date" placeholder="" [(ngModel)]="approvalDate"></ion-input>
        </ion-col>
      </ion-row>
    <ion-row>
      <ion-col class="entry-column">
        <span class="label">Method of Approval</span>
        <select [(ngModel)]="approvedMethod" [ngClass]="{'text-color-grey': !approvedMethod}">
          <option [value]="null" disabled selected>Select approval method</option>
          <option *ngFor="let method of methods">
            {{method}}
          </option>
        </select>
      </ion-col>
    </ion-row>
    <ion-row class="disclaimer">
      <ion-col>
        <span>
          By submitting this approval, you are certifying that you have obtained written prior approval from the appropriate customer 
          representative for the additional hours. You are responsible for keeping a record of this approval for 90 days and may be asked to present 
          it at any time within this period. Failure to obtain the appropriate approval or maintain the record of this approval will result in loss of payment for those hours.
          </span>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-footer>
    <ion-row>
      <ion-col>
        <ion-button
          class="add-approval-button"
          (click)="approveRequest()"
          [disabled]="!approverName || !approvedMethod"
        >
          Approve Request
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-footer>