import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LiaisonIssue } from '../../models/liaison-issue.model';
import { CreateEditIssueComponent } from '../create-edit-issue/create-issue.component';
import { LiaisonStateService } from '../../../../app/features/liaison/services/liaison-state-service';
import { CacheInterceptor } from '../../services/cache.service';

@Component({
    selector: 'issue-card-component',
    templateUrl: 'issue-card.component.html',
    styleUrls:['./issue-card.component.scss']
})

export class IssueCardComponent {
    
    @Input() issue: LiaisonIssue = null;
    @Input() issues: LiaisonIssue[] = [];
    @Output() refreshIssues = new EventEmitter<{}>();

    constructor(
        private modalController: ModalController,
        private liaisonStateService: LiaisonStateService,
        private cacheInterceptor: CacheInterceptor
    ) {

    }

    getDate(date: string): string {
        const split = date.split('T');

        return split[0];
    }

    async createOrUpdateIssue(issue?: LiaisonIssue) {
        const modal = await this.modalController.create({
            component: CreateEditIssueComponent,
            componentProps: {
                activeIssue: issue ? issue : new LiaisonIssue(null,"",false,null,"",[],0,null,this.liaisonStateService.getUserId())
            },
            cssClass: 'min-width-modal',
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (this.cacheInterceptor.isOffline) {
            if (data) {
                var existingIssue = this.issues.find(i => i.id === data.id);

                if (existingIssue) {
                    existingIssue = data;
                }

                // Update cache with new state
                this.cacheInterceptor.manuallyCreateCacheObject(`${this.cacheInterceptor.API_BASE_URL}/issues/${this.liaisonStateService.getActiveAccountId()}`, this.issues);
            }
        }

        this.refreshIssues.emit();
    }

}