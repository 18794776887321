<ion-grid>
    <ion-row class="header-row">
        <ion-col>
            <span class="modal-header-text">Create Defect</span>
        </ion-col>
        <ion-col size="auto">
            <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col size="auto" class="label">
            Label: 
        </ion-col>
        <ion-col>
            <ion-input [(ngModel)]="label" type="text"></ion-input>
        </ion-col>
    </ion-row>
    <ion-row class="button-row">
        <ion-col>
            <ion-button class="bottom-button" (click)="createDefect()">Create Defect</ion-button>
        </ion-col>
    </ion-row>
</ion-grid>