import { Pipe, PipeTransform } from "@angular/core";
import { User } from "../../../shared/models/user.model";
import { AccountContact } from "../../../shared/models/contact.model";
import { Contact } from "../../../shared/models/contact.model";

@Pipe({
    name: 'filterAssignedContacts'
})

export class FilterAssignedContactsPipe implements PipeTransform {


    transform(items: Contact[], contacts: AccountContact[], contactId: number) {

        return items.filter(i => ((!contacts.map(r => r.contact_id).includes(i.id)) && i.active) || (i.id === contactId));

    }
}