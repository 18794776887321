import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { LiaisonApiService } from "../../../../app/features/liaison/services/liaison-api-service";
import { LiaisonStateService } from "../../../../app/features/liaison/services/liaison-state-service";
import { ModalController } from "@ionic/angular";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-close-open-issue-popup",
  templateUrl: "./close-open-issue-popup.component.html",
  styleUrls: ["./close-open-issue-popup.component.scss"],
  providers: [LiaisonApiService]
})

export class CloseOpenIssueComponent implements OnInit {

    reason: string = null;
    isClosingIssue: boolean = true;

    cleanPointRequired: boolean = false;
    cleanDate: string = null;

    constructor(
        public liaisonStateService: LiaisonStateService,
        private liaisonApiService: LiaisonApiService,
        private modalController: ModalController,
        private sharedService: SharedService
    ) {

    }

    ngOnInit() {
        this.setDefaultDate();
    }

    setDefaultDate(): void {
        this.cleanDate = this.sharedService.getDefaultDate(new Date().toISOString());
    }

    confirm(): void {
        this.modalController.dismiss({reason: this.reason, cleanPointRequired: this.cleanPointRequired, cleanDate: this.cleanDate});
    }

    close(): void {
        this.modalController.dismiss();
    }


}
