import { Component, Input, OnInit, Output } from '@angular/core';
import { IDailyReportDisplayModel, IReportDisplayModel } from '../../../../models/daily-report-display.model';
import { SharedService } from '../../../../services/shared.service';
import { LiaisonApiService } from '../../../../../../app/features/liaison/services/liaison-api-service';
import { IIssue } from '../../../../../../app/features/liaison/models/daily-report.model';

@Component({
    selector: 'issue-list-component',
    templateUrl: 'issue-list.component.html',
    styleUrls:['./issue-list.component.scss'],
    providers: [LiaisonApiService]
})

export class IssueListComponent implements OnInit {
    
    @Input() issues: IIssue[] = [];

    statusMap = new Map<string, string>([
        ['new', 'New Issue'],
        ['update', 'In Progress'],
        ['closed', 'Closed']
    ])

    statusColorMap = new Map<string, string>([
        ['new', '#0568af'],
        ['update', 'lightgrey'],
        ['closed', '#c1272d']
    ])

    ngOnInit(): void {
        console.log(this.issues)
        if (this.issues) {
            this.issues.forEach(issue => {
                // format date
                if (issue.date_created?.includes('T')) {
                    const [year, month, day] = issue.date_created.split('T')[0].split('-');

                    issue.date_created = `${month}/${day}/${year}`
                }

                if (!(issue as any).status) {
                    var status = 'new';

                    if (!issue.active) {
                        status = 'closed';
                    } else if (issue.issue_updates.filter(u => !u.is_action_taken).length > 0) {
                        status = 'update';
                    }

                    (issue as any).status = status;
                }

                issue.issue_updates.forEach(update => {
                    if (update.time_created) {
                        // update.time_created = update.time_created.replace('-', '/').replace('-', '/');
                    }

                    if (update.id === issue.actionUpdate?.id) {
                        update.dontShow = true;
                    }

                    if (update.is_closing_update) {
                        update.dontShow = true;

                        // issue.closing_update = update;
                    }

                    // if (update.content) {
                    //     var optionalSentenceOne = null;
                    //     var optionalSentenceTwo = null;
                    //     var optionalSentenceThree = null;

                    //     if (update.parts_found) {
                    //         optionalSentenceOne = `${update.parts_found} parts found `;
                    //     }

                    //     if (update.area_found && optionalSentenceOne) {
                    //         optionalSentenceOne += `in ${update.area_found}. `;
                    //     }

                    //     if (update.area_found && !optionalSentenceOne) {
                    //         optionalSentenceOne = `Issue found in ${update.area_found}`;
                    //     }

                    //     if (update.issue_serial_numbers.length > 0 && (update.issue_serial_numbers as any) !== 'none') {
                    //         optionalSentenceTwo = `Traceability Barcodes: ${update.issue_serial_numbers}. `
                    //     }

                    //     if (update.clean_point_required) {
                    //         optionalSentenceThree = `Clean point: ${update.clean_point_date}. `
                    //     }

                    //     update.content = `${optionalSentenceOne ?? ''}${update.content} ${optionalSentenceTwo ?? ''}${optionalSentenceThree ?? ''}`
                    // }
                })
            })
        }
    }

}