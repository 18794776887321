<ion-grid>
    <ion-row class="header-row">
        <ion-col>
          
        </ion-col>
        <ion-icon class="cross" (click)="close()" name="close-outline"></ion-icon>
    </ion-row>

    <div class="scroll-area" *ngIf="!loading">

        <ion-row>
            <ion-col>
                <div class="label-text">
                    <span>Liaison</span>
                    <select *ngIf="sharedService.isNative()" [(ngModel)]="selectedUser" [ngClass]="{'text-color-grey': !selectedUser}">
                        <option [value]="null" disabled selected>Select Liaison</option>
                        <ng-container *ngIf="liaisons?.length > 0">
                            <option [ngValue]="user" *ngFor="let user of liaisons">
                                {{user?.first_name}} {{user?.last_name ?? ''}}
                            </option>
                        </ng-container>
                    </select>
                    <ng-select placeholder="Select Liaison" *ngIf="!sharedService.isNative()" appendTo="body" class="ng-select-no-border"
                    [(ngModel)]="selectedUser" [ngClass]="{'text-color-grey': !selectedUser}">
                        <ng-container *ngIf="liaisons?.length > 0">
                            <ng-option [value]="user" *ngFor="let user of liaisons">
                                {{user?.first_name}} {{user?.last_name ?? ''}}
                            </ng-option>
                        </ng-container>
                    </ng-select>
                </div>
            </ion-col>
            <ion-row>
                <div class="label-text">
                    <span>Pay Rate</span>
                    <div>
                        <ng-container>
                            <currency-input [width]="100" [(value)]="payRate" [placeholder]="''"></currency-input>
                        </ng-container>
                    </div>
                </div>
            </ion-row>
        </ion-row>

        <ion-row>
            <ion-col>
                <div class="label-text">
                    <span>Start Date</span>
                    <ion-input type="date" [(ngModel)]="startDate"></ion-input>
                </div>
            </ion-col>
            <ion-col>
                <div class="label-text">
                    <span>End Date</span>
                    <div>
                        <ion-input type="date" [(ngModel)]="endDate"></ion-input>
                    </div>
                </div>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col class="checkbox-row">
                <ion-checkbox [(ngModel)]="allowDirectReporting"></ion-checkbox>
                <span>Allow direct reporting from liaison</span>
            </ion-col>
        </ion-row>

    </div>

    <div class="loading-area" *ngIf="loading">
        <ion-spinner name="dots"></ion-spinner>
    </div>

    <ion-row class="button-container">
        <ion-col *ngIf="liaisonCover">
            <div [ngClass]="{'width-100': liaisonCover}" class="save-button red-button" (click)="remove()">
                <span>Delete</span>
            </div>
        </ion-col>
        <ion-col>
            <div class="save-button blue-button" [ngClass]="{'disabled': !startDate || !selectedUser, 'width-100': liaisonCover}" (click)="save()">
                <span>Save</span>
            </div>
        </ion-col>
    </ion-row>
</ion-grid>