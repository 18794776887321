import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginPageModule } from "./features/login/pages/app-login/app-login.module";
import { AuthGuardService as AuthGuard } from "./features/login/auth-guard/auth-guard.service";

const routes: Routes = [
  {
    path: "liaison",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./features/liaison/pages/liaison-router.module").then(
        (m) => m.LiaisonRouterModule
      ),
  },
  {
    path: "admin",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./features/admin/pages/admin-router.module").then(
        (m) => m.AdminRouterModule
      ),
  },
  {
    path: "customer",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./features/customer/pages/customer-router.module").then(
        (m) => m.CustomerRouterModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./features/login/pages/app-login/app-login.module").then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: "sign-up",
    loadChildren: () =>
      import("./features/login/pages/sign-up/sign-up.module").then(
        (m) => m.SignUpPageModule
      ),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./features/login/pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: "reset-password/:token",
    loadChildren: () =>
      import("./features/login/pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: "settings",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./features/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: "onboard",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./features/login/pages/onboard/onboard.module").then(
        (m) => m.OnBoardPageModule
      ),
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "/login",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
