<ion-grid>
    <ion-row class="top-bar">
        <!-- Pushes close icon over to left -->
        <!-- Flex box will break with justify end on Android (idk) -->
        <ion-col></ion-col>
        <ion-col size="auto">
            <ion-icon
                  class="cross"
                  (click)="close()"
                  name="close-outline"
            ></ion-icon>
        </ion-col>
    </ion-row>
    <ion-row class="text-row">
        <ion-col>
            <span>Enter your reason for {{isClosingIssue ? 'closing' : 'opening'}} this issue</span>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <ion-textarea autocomplete=“true” spellcheck=“true” autocapitalize="true" [(ngModel)]="reason" placeholder="Type here..."></ion-textarea>
        </ion-col>
    </ion-row>
    <ion-row class="clean-point-checkbox-row" *ngIf="isClosingIssue">
        <ion-col size="auto">
            <ion-checkbox [(ngModel)]="cleanPointRequired"></ion-checkbox>
        </ion-col>
        <ion-col>
            Clean Point Required?
        </ion-col>
    </ion-row>
    <ion-row [ngStyle]="{'opacity': (isClosingIssue && cleanPointRequired) ? '1' : '0'}">
        <ion-col>
            <ion-input [(ngModel)]="cleanDate" type="date"></ion-input>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <ion-button [disabled]="!reason || reason.length <= 0" (click)="confirm()"
            [ngClass]="{'close': isClosingIssue, 'open': !isClosingIssue}">
                {{isClosingIssue ? 'Close' : 'Open'}} Issue</ion-button>
        </ion-col>
    </ion-row>
</ion-grid>