<div class="footer-container">
  <div class="button-container">
    <div (click)="navigateToAccounts()" class="image-container">
      <img src="../../../../assets/icons/accounts.svg" />
    </div>
    <span class="margin-top-span">Accounts</span>
  </div>
  <div class="button-container">
    <div [ngStyle]="{'opacity': (reportCount > 0) ? '1' : '0'}" class="badge-container">
      <div>
        <span>{{reportCount}}</span>
      </div>
    </div>
    <div (click)="navigateToReview()" class="send-container">
      <img src="../../../../assets/icons/document-text-outline.svg" />
    </div>
    <span class="margin-top-span">Generate</span>
  </div>
  <div class="button-container">
    <div (click)="navigateToTime()" class="image-container">
      <img src="../../../../assets/icons/clock-white.svg" />
    </div>
    <span class="margin-top-span">Time</span>
  </div>
</div>
