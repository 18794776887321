// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  name: 'production',
  biometricServerName: 'QASTrackMobileApp',
  version: '2.4',
  firebase: {
    apiKey: "AIzaSyC5l-W8IsYPkLEUg01aDNvJCKfoGeZ9xHI",
    authDomain: "qas-production.firebaseapp.com",
    projectId: "qas-production",
    storageBucket: "qas-production.appspot.com",
    messagingSenderId: "912325748263",
    appId: "1:912325748263:web:b923310fc9641a570cd002",
    measurementId: "G-Q89N83VECT"
  },
  appShellConfig: {
    debug: false,
    networkDelay: 500,
  },
  API_URL: "https://qasmobileexpressapi-production-99df.up.railway.app/api",
};