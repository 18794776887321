<ion-grid>
  <ion-row class="header-row">
    <ion-col *ngIf="loaded">
      <span>{{account.name}}</span>
    </ion-col>
    <ion-col *ngIf="!loaded">
      <ion-skeleton-text class="header" animated="true"></ion-skeleton-text>
    </ion-col>
    <ion-col size="auto">
      <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
    </ion-col>
  </ion-row>

  <div>

  </div>
  <ion-row>
    <ion-col size="auto">
      Location: 
    </ion-col>
    <ion-col [ngStyle]="{'color': account?.locations?.name ? '' : 'red'}" *ngIf="loaded">
      {{account?.locations?.name ?? 'No Location'}}
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="auto">
      Primary Contact: 
    </ion-col>
    <ion-col *ngIf="loaded" [ngStyle]="{'color': primaryContact?.contacts?.first_name ? '' : 'red'}" (click)="contactPopup()">
      {{primaryContact?.contacts?.first_name ? (primaryContact?.contacts?.first_name + ' ' + (primaryContact?.contacts?.last_name ?? '')) : 'No Contact'}}
    </ion-col>
  </ion-row>
  
  <ion-row>
    <ion-col size="auto">
      Contact Phone: 
    </ion-col>
    <ion-col *ngIf="loaded" [ngClass]="{'existing-data': primaryContact?.contacts?.phone, 'missing-data': !primaryContact?.contacts?.phone}" (click)="contactPopup(CONTACT_EVENT_TYPE.PHONE)">
      {{primaryContact?.contacts?.phone ?? 'No Phone'}}
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="auto">
      Contact Email: 
    </ion-col>
    <!-- (click)="contactPopup(CONTACT_EVENT_TYPE.EMAIL)" -->
    <!-- [ngClass]="{'existing-data': primaryContact?.contacts?.email, 'missing-data': !primaryContact?.contacts?.email}" -->
    <ion-col *ngIf="loaded" class="basic-data" >
      {{primaryContact?.contacts?.email ?? 'No Email'}}
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="auto">
      Contracted Hours: 
    </ion-col>
    <ion-col *ngIf="loaded" [ngClass]="{'missing-data': !baseHours}">
      {{baseHours ? baseHours + ((baseHours === 1) ? 'hr' : ' hrs') : 'No Base Hours'}}
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="auto">
      Additional Hours: 
    </ion-col>
    <ion-col *ngIf="loaded" [ngClass]="{'missing-data': !additionalHours}">
      {{additionalHours ? additionalHours + ((additionalHours === 1) ? 'hr' : ' hrs') : 'No Additional Hours'}}
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="auto">
      Base Rate: 
    </ion-col>
    <ion-col *ngIf="loaded" [ngClass]="{'missing-data': !payRate}">
      {{payRate ? (payRate | currency: 'USD') : 'No Base Rate Found'}}
    </ion-col>
  </ion-row>

  <ion-row *ngIf="liaisonCovers.length > 0">
    <ion-col>
      <ion-label>Available Liaison Coverages:</ion-label>
      <ion-grid class="coverage-table">
        <ion-row class="header-row">
          <ion-col size="4">Name</ion-col>
          <ion-col size="4">Start Date</ion-col>
          <ion-col size="4">End Date</ion-col>
        </ion-row>
        <ion-row *ngFor="let cover of liaisonCovers">
          <ion-col size="4">{{cover.users?.first_name}} {{cover.users?.last_name}}</ion-col>
          <ion-col size="4">{{cover.start_date ? (convertDate(cover.start_date) | date:'shortDate') : 'N/A'}}</ion-col>
          <ion-col size="4">{{cover.end_date ? (convertDate(cover.end_date) | date:'shortDate') : 'N/A'}}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>

</ion-grid>
