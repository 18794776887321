import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDailyReportDisplayModel, IReportDisplayModel } from '../../models/daily-report-display.model';
import { LiaisonIssueUpdate } from '../../models/liaison-issue-updates.model';
import { SharedService } from '../../services/shared.service';
import { IAttachment, IRejectAttachment } from '../../models/attachment.model';
import { IDefect, Sort } from '../../../../app/features/liaison/models/daily-report.model';
import { ModalController } from '@ionic/angular';
import { RejectImagePopupComponent } from '../reject-image-popup/reject-image-popup.component';
import { LiaisonApiService } from '../../../../app/features/liaison/services/liaison-api-service';
import { RejectDefectReportComponent } from '../reject-defect-report/reject-defect-report.component';

@Component({
    selector: 'liaison-report-content-component',
    templateUrl: 'liaison-report-content.component.html',
    styleUrls:['./liaison-report-content.component.scss'],
    providers: [LiaisonApiService]
})

export class LiaisonReportContentComponent implements OnInit {
    
    @Input() report: IDailyReportDisplayModel = null;

    reportData: IReportDisplayModel = null;

    statusMap = new Map<string, string>([
        ['new', 'New Issue'],
        ['update', 'In Progress'],
        ['closed', 'Closed']
    ])

    statusColorMap = new Map<string, string>([
        ['new', '#0568af'],
        ['update', 'lightgrey'],
        ['closed', '#c1272d']
    ])

    sortImages: IAttachment[] = [];

    issueData: any = {
        new: [],
        update: [],
        closed: []
    };

    constructor(
        private sharedService: SharedService,
        private modalController: ModalController,
        private liaisonApiService: LiaisonApiService
    ) {

    }

    async ngOnInit(): Promise<void> {
        console.log(this.report);
        await this.processReport();
    }

    async processReport(): Promise<void> {
        this.reportData = await this.liaisonApiService.convertReportToDisplayReport(this.report);
        console.log(this.reportData);

        this.issueData = {
            new: this.reportData.allIssues.filter(r => (r as any).status === 'new'),
            update: this.reportData.allIssues.filter(r => (r as any).status === 'update'),
            closed: this.reportData.allIssues.filter(r => (r as any).status === 'closed')
        }
    }

    async openDefectImages(defect: IDefect): Promise<void> {
        if (defect.images.length <= 0) {
            return;
        }

        const allRejects = this.report.sorts.flatMap(s => s.rejects);

        const modal = await this.modalController.create({
            component: RejectImagePopupComponent,
            componentProps: {
                images: defect.images.map(i => {
                    return {
                        ...i,
                        reject: allRejects.find(r => r.id === i.reject_id)
                    }
                })
            },
            cssClass: "min-width-modal",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data) {

        }
    }

    async openDefectReport(defect: IDefect, sort: Sort): Promise<void> {
        if (!defect) {
            return;
        }

        const rejects = sort.rejects.filter(r => r.reject_defects.map(d => d.defects?.id).includes(defect.id));
        console.log(rejects)

        const modal = await this.modalController.create({
            component: RejectDefectReportComponent,
            componentProps: {
                defect,
                rejects
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data) {

        }
    }

    processNewIssues(): void {
        // Add the initial update information to the issue for display reasons
        this.report.newIssues.forEach(i => {
            if (i.grouped_updates.length > 0) {
                // Get oldest updates
                const oldestUpdateGroup = i.grouped_updates.sort((a, b) => {
                    if (new Date(a.date) < new Date(b.date)) return 1;
                    if (new Date(a.date) > new Date(b.date)) return -1;
                    return 0;
                })[0];

                const oldestUpdate = oldestUpdateGroup?.updates.sort((a, b) => {
                    if (new Date(a.time_created) < new Date(b.time_created)) return 1;
                    if (new Date(a.time_created) > new Date(b.time_created)) return -1;
                    return 0;
                })[0];

                // TO DO: Check sorting is correct and display values
                if (oldestUpdate) {
                    oldestUpdateGroup.updates = oldestUpdateGroup.updates.filter(o => o.id !== oldestUpdate.id);

                    i.grouped_updates = i.grouped_updates.filter(g => g.updates.length > 0);

                    i.actionTaken = oldestUpdate.content;
                    i.nonConformedParts = oldestUpdate.parts_found;
                    i.areaFound = oldestUpdate.area_found;
                }
            }
        });
    }

    getTraceabilityBarcodes(update: LiaisonIssueUpdate): string {
        var barcodeString: string = '';
    
        update.issue_serial_numbers.forEach((i, index) => {
            if (index === (update.issue_serial_numbers.length - 1)) {
                barcodeString += `${i.serial_number}`;
            } else {
                barcodeString += `${i.serial_number}, `;
            }
        });
    
        return barcodeString;
    }

    getStandardizedDateFormat(data: string): string {
        const date = this.sharedService.stringToDate(data);
    
        return `${((date.getMonth() + 1) < 10) ? '0' : ''}${date.getMonth() + 1}-${(date.getDate() < 10) ? '0' : ''}${date.getDate()}-${date.getFullYear()}`;
    }

    convertBarcodesToCSV(barcodes: any[]): string {
        var barcodeString: string = '';
    
        barcodes.forEach((i, index) => {
            if (index === (barcodes.length - 1)) {
                barcodeString += `${i.serial_number}`;
            } else {
                barcodeString += `${i.serial_number}, `;
            }
        });
    
        return barcodeString;
    }


}