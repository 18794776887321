import { Component, Input, OnInit, Output } from '@angular/core';
import { IDailyReportDisplayModel, IReportDisplayModel } from '../../../../models/daily-report-display.model';
import { SharedService } from '../../../../services/shared.service';
import { LiaisonApiService } from '../../../../../../app/features/liaison/services/liaison-api-service';
import { IDefect, IIssue, ISort, Sort } from '../../../../../../app/features/liaison/models/daily-report.model';
import { ModalController } from '@ionic/angular';
import { RejectImagePopupComponent } from '../../../reject-image-popup/reject-image-popup.component';
import { RejectDefectReportComponent } from '../../../reject-defect-report/reject-defect-report.component';

@Component({
    selector: 'sort-list-component',
    templateUrl: 'sort-list.component.html',
    styleUrls:['./sort-list.component.scss'],
    providers: [LiaisonApiService]
})

export class SortListComponent implements OnInit {
    
    @Input() sorts: Sort[] = [];

    constructor(
        private modalController: ModalController
    ) { }

    ngOnInit(): void {
        console.log(this.sorts);
    }

    async openDefectImages(defect: IDefect): Promise<void> {
        if (defect.images.length <= 0) {
            return;
        }

        const allRejects = this.sorts.flatMap(s => s.rejects);

        const modal = await this.modalController.create({
            component: RejectImagePopupComponent,
            componentProps: {
                images: defect.images.map(i => {
                    return {
                        ...i,
                        reject: allRejects.find(r => r.id === i.reject_id)
                    }
                })
            },
            cssClass: "min-width-modal",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data) {

        }
    }

    async openDefectReport(defect: IDefect, sort: Sort): Promise<void> {
        if (!defect) {
            return;
        }

        const rejects = sort.rejects.filter(r => r.reject_defects.map(d => d.defects?.id).includes(defect.id));
        console.log(rejects)

        const modal = await this.modalController.create({
            component: RejectDefectReportComponent,
            componentProps: {
                defect,
                rejects
            },
            cssClass: "min-width-modal grey-background",
            showBackdrop: true,
            backdropDismiss: true,
            keyboardClose: true,
            swipeToClose: true,
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data) {

        }
    }

}