import { Component, OnInit } from "@angular/core";
import { AdminApiService } from "../../services/admin-api-service";
import { ViewWillEnter } from "@ionic/angular";
import { OVERTIMEOPTIONS } from "../../../../../app/shared/models/liaison-account.model";
import { ModalController } from "@ionic/angular";

export interface IOvertimeRules {
    noOvertime: boolean;
    overtimeAfter: number;
    overtimeOption: OVERTIMEOPTIONS;
    overtimeOnWeekends: boolean;
    overtimeOnHolidays: boolean;
    doubleTimeSundays: boolean;
    doubleTimeOnHolidays: boolean;
};

@Component({
    selector: 'overtime-rules-popup-component',
    templateUrl: 'overtime-rules.component.html',
    styleUrls:['./overtime-rules.component.scss'],
    providers:[AdminApiService]
})
export class OvertimeRulesPopupComponent implements ViewWillEnter, OnInit {

    OVERTIMEOPTIONS = OVERTIMEOPTIONS;

    overtimeRules: IOvertimeRules = null;
    originalOvertimeRules: IOvertimeRules = {
        noOvertime: null,
        overtimeAfter: null,
        overtimeOnHolidays: null,
        overtimeOnWeekends: null,
        overtimeOption: null,
        doubleTimeOnHolidays: null,
        doubleTimeSundays: null
    };

    constructor(
        private modalController: ModalController
    ) {

    }

    ngOnInit(): void {
        this.originalOvertimeRules.overtimeAfter = this.overtimeRules.overtimeAfter;
        this.originalOvertimeRules.overtimeOption = this.overtimeRules.overtimeOption;
        this.originalOvertimeRules.overtimeOnWeekends = this.overtimeRules.overtimeOnWeekends;
        this.originalOvertimeRules.overtimeOnHolidays = this.overtimeRules.overtimeOnHolidays;
        this.originalOvertimeRules.doubleTimeSundays = this.overtimeRules.doubleTimeSundays;
        this.originalOvertimeRules.doubleTimeOnHolidays = this.overtimeRules.doubleTimeOnHolidays;
        this.originalOvertimeRules.noOvertime = this.overtimeRules.noOvertime;
    }

    ionViewWillEnter(): void {
        
    }

    hasChange(): boolean {
        return (this.originalOvertimeRules.overtimeAfter !== this.overtimeRules.overtimeAfter) ||
        (this.originalOvertimeRules.overtimeOption !== this.overtimeRules.overtimeOption) ||
        (this.originalOvertimeRules.overtimeOnWeekends !== this.overtimeRules.overtimeOnWeekends) ||
        (this.originalOvertimeRules.overtimeOnHolidays !== this.overtimeRules.overtimeOnHolidays) ||
        (this.originalOvertimeRules.doubleTimeSundays !== this.overtimeRules.doubleTimeSundays) ||
        (this.originalOvertimeRules.noOvertime !== this.overtimeRules.noOvertime) ||
        (this.originalOvertimeRules.doubleTimeOnHolidays !== this.overtimeRules.doubleTimeOnHolidays)
    }

    save(): void {
        this.modalController.dismiss(this.overtimeRules)
    }

    close(): void {
        this.modalController.dismiss();
    }


}