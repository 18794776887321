import { Injectable } from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from "@angular/common/http";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const currentDate = new Date();
    // Get the auth token from the service.
    const authToken = this.auth.getToken();
    const userId = this.auth.getUser()?.id ?? 0;
    const userDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1) < 10 ? '0' : ''}${(currentDate.getMonth() + 1)}-${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}`;

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set("Authorization", `Bearer ${authToken}`).set('loggedInUserId', userId.toString()).set('loggedInUserDate', userDate),
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
