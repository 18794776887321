<ion-grid>
    <ion-row class="header-row">
        <ion-col>
            <span>{{user.first_name}} {{user.last_name}}</span>
        </ion-col>
        <ion-icon class="cross" (click)="close()" name="close-outline"></ion-icon>
    </ion-row>

    <div class="scroll-area">
        <ion-row class="table-header-row">
            <ion-col>
                <span>Base Hours</span>
            </ion-col>
            <ion-col>
                <span>Pay Rate</span>
            </ion-col>
            <ion-col>
                <span>Start Date</span>
            </ion-col>
            <ion-col>
                <span>End Date</span>
            </ion-col>
            <ion-col size="auto">
                <ion-button (click)="addNew()">+ New</ion-button>
            </ion-col>
        </ion-row>

        <ion-row class="table-row" *ngFor="let data of history | orderByStartDate: trigger">
            <ion-col>
                <ion-input (ionChange)="refreshPipe()" type="number" [(ngModel)]="data.hours"></ion-input>
            </ion-col>  
            <ion-col>
                <currency-input [(value)]="data.rate" [placholder]="''"></currency-input>
            </ion-col> 
            <ion-col>
                <ion-input (ionBlur)="dateUnfocused()" type="date" [(ngModel)]="data.start_date"></ion-input>
            </ion-col>
            <ion-col>
                <ion-input [disabled]="true" *ngIf="!data.end_date" placeholder=""></ion-input>
                <ion-input *ngIf="data.end_date" (ionBlur)="dateUnfocused()" type="date" [(ngModel)]="data.end_date"></ion-input>
            </ion-col>
            <ion-col size="auto">
                <ion-button (click)="remove(data)">Remove</ion-button>
            </ion-col>
        </ion-row>
    </div>

    <ion-row class="save-button">
        <ion-col>
            <ion-button (click)="save()">
                Save
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>