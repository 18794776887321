import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { firstValueFrom } from "rxjs";
import { Platform } from "@ionic/angular";
import { SharedService } from "../../../../app/shared/services/shared.service";
import { FirebaseAuthentication, SignInWithOAuthOptions, SignInCustomParameter, SignInResult, SignInWithEmailAndPasswordOptions } from '@capacitor-firebase/authentication';


@Injectable()
export class LoginService {
    API_BASE_URL: string

    constructor(
        private httpClient: HttpClient,
        private platform: Platform,
        private sharedService: SharedService
    ) {
        this.API_BASE_URL = environment.API_URL;
    }

    public async signInWithEmailAndPassword(email: string, password: string): Promise<string> {
        const creds: SignInWithEmailAndPasswordOptions = {email, password};
        await FirebaseAuthentication.signInWithEmailAndPassword(creds);
        const token = await FirebaseAuthentication.getIdToken();

        return token?.token ?? null;
    };

    public async signUpWithEmailAndPassword(email: string, password: string): Promise<string> {
        const creds: SignInWithEmailAndPasswordOptions = {email, password};
        await FirebaseAuthentication.createUserWithEmailAndPassword(creds);
        const token = await FirebaseAuthentication.getIdToken();

        return token?.token ?? null;
    };

    public async signInWithGoogle(): Promise<string> {
        await FirebaseAuthentication.signInWithGoogle();
        const token = await FirebaseAuthentication.getIdToken();

        return token?.token ?? null;
    };

    public async signOut(): Promise<void> {
        this.sharedService.localStorageRemove('user');
        this.sharedService.localStorageRemove('authToken');
        this.sharedService.localStorageRemove('userId');
        this.sharedService.localStorageRemove('qas-pending-request-queue');
        await FirebaseAuthentication.signOut();
    }

    public async signInWithMicrosoft() {
        // const tenantCustomerProperties: SignInCustomParameter = { key: 'tenant', value: '' }
        const consentProperties: SignInCustomParameter = { key: 'prompt', value: 'consent' }

        const options: SignInWithOAuthOptions = {
            mode: 'redirect',
            customParameters: [consentProperties],
        };

        try {
            return FirebaseAuthentication.signInWithMicrosoft(options).then((result: SignInResult) => {
                const credential = result.credential;
                const accessToken = credential.accessToken;
                this.sharedService.localStorageSet('ssoToken', accessToken);
            
                return accessToken;
            });
        } catch (error) {
            console.log(error);
        }
    }

    public async getUserUID(): Promise<string> {
        const data = await FirebaseAuthentication.getCurrentUser();

        return data?.user?.uid ?? '';
    }

}