<div class="container" [ngStyle]="{'overflow-y': this.sharedService.isLiaison() ? 'auto' : 'hidden'}">
    <ion-row class="close-header">
        <ion-col class="logo">
            <img class="qas-logo" src="../.././../../../assets/logos/QAS Logo-main.svg"/>
        </ion-col>
        <ion-col class="close">
            <ion-icon (click)="close()" name="close-outline"></ion-icon>
        </ion-col>
    </ion-row>

    <ion-row class="header">
        <ion-col>
            <span>Contact Us</span>
        </ion-col>
    </ion-row>
    <ion-row class="data">
        <ion-col>
            <span>Phone: <a href="tel:8777277589">877-727-7589</a></span>
        </ion-col>
    </ion-row>
    <ion-row class="data">
        <ion-col>
            <span>Website: <a href="https://qas-solutions.com/">https://qas-solutions.com/</a></span>
        </ion-col>
    </ion-row>

    <ng-container *ngIf="this.sharedService.isLiaison()">
        <ion-row class="header">
            <ion-col>
                <span>QAS Contacts</span>
            </ion-col>
        </ion-row>
        <ion-row class="row" *ngFor="let personnel of qasPersonnel">
            <ion-col>
                <ion-row class="top-row">
                    <ion-col class="ion-no-padding selectable">
                        {{personnel.name}}
                    </ion-col>
                </ion-row>
                <ion-row class="data-row">
                    <ion-col class="ion-no-padding selectable">
                        {{personnel.title ?? 'No Title'}}
                    </ion-col>
                </ion-row>
                <ion-row class="data-row">
                    <ion-col class="ion-no-padding selectable">
                        <span>Email: </span>
                        <a [href]="'mailto: ' + personnel.email">{{personnel.email ?? 'No Email'}}</a>
                    </ion-col>
                </ion-row>
                <ion-row class="data-row">
                    <ion-col class="ion-no-padding selectable">
                        <span>Phone: </span>
                        <a [href]="'tel: ' + personnel?.phone">{{personnel?.phone ?? 'No Phone'}}</a>
                    </ion-col>
                </ion-row>
                <ion-row class="notes-row" *ngIf="personnel?.notes">
                    <ion-col class="ion-no-padding selectable">
                        <span>* </span>
                        {{personnel?.notes ?? 'No Data'}}
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
    </ng-container>

    <ng-container *ngIf="!this.sharedService.isLiaison()">
        <ion-row class="location-header">
            <ion-col>
                <span>Need a location? See where we can serve you.</span>
            </ion-col>
        </ion-row>
        <ion-row class="search-row">
            <ion-col>
                <ion-input (ionChange)="updateLocations()" [(ngModel)]="search" placeholder="Search for location">
                    <ion-icon name="search-outline"></ion-icon>
                </ion-input>
                <!-- <div class="search">
                    <span>Search</span>
                </div> -->
            </ion-col>
        </ion-row>
    
        <ng-container *ngIf="locations.length > 0">
            <div class="scroll">
                <ion-row class="card" *ngFor="let location of locationDisplay">
                    <ion-col>
                       <ion-row class="sub-header">
                           <ion-col class="ion-no-padding">
                                <span>{{location.name}}</span>
                           </ion-col>
                       </ion-row>
                       <ion-row class="sub-data">
                            <ion-col *ngIf="location?.city && location?.state" class="ion-no-padding">
                                <span>{{location.city}}, {{location.state}}</span>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </div>
        </ng-container>
        <ng-container *ngIf="locations.length <= 0">
            <div class="scroll spinner-container">
                <ion-spinner name="dots"></ion-spinner>
            </div>
        </ng-container>
    </ng-container>

</div>