<ion-grid>
    <ion-row class="header-row" id="hide-on-camera">
        <ion-col class="header-text">
          <span class="modal-header-text">Add Update</span>
        </ion-col>
        <ion-col size="auto">
          <ion-icon
            class="cross"
            (click)="closeModal(false)"
            name="close-outline"
          ></ion-icon>
        </ion-col>
      </ion-row>

    <ion-row id="hide-on-camera">
        <ion-col>
          <span class="qas-blue label">Number of Nonconforming Parts</span>
          <ion-input
            placeholder="Enter # of parts"
            [(ngModel)]="newUpdate.parts_found"
            [disabled]="numberOfPartsNotApplicable"
            type="number"
          ></ion-input>
        </ion-col>
        <ion-col class="no-applicable-col" size="auto">
          <input [(ngModel)]="numberOfPartsNotApplicable" type="checkbox" />
          <span>Not Applicable</span>
        </ion-col>
      </ion-row>
    
      <ion-row class="action-taken-row" id="hide-on-camera">
        <ion-col>
          <span class="qas-blue label">Area in Plant Where Found</span>
          <ion-textarea
            class="description-box"
            placeholder="Enter area where found"
            [(ngModel)]="newUpdate.area_found"
            [disabled]="areaFoundNotApplicable"
            autocomplete=“true” 
            spellcheck=“true” 
            type="text"
            autocapitalize="true"
            rows="1"
          ></ion-textarea>
        </ion-col>
        <ion-col class="no-applicable-col" size="auto">
          <input [(ngModel)]="areaFoundNotApplicable" type="checkbox" />
          <span>Not Applicable</span>
        </ion-col>
      </ion-row>
    
      <ion-row class="description-row" id="hide-on-camera">
        <ion-col>
          <span class="qas-blue label">Action Taken</span>
          <ion-textarea
            class="description-box"
            [(ngModel)]="newUpdate.content"
            type="text"
            placeholder="Enter action taken"
            autocomplete=“true” 
            spellcheck=“true” 
            autocapitalize="true"
            [ngModelOptions]="{standalone: true}"
            [autoGrow]="true"
            autosize 
            rows="1"
          ></ion-textarea>
        </ion-col>
      </ion-row>

      <ion-row id="hide-on-camera">
        <ion-col>
          <button class="attachment-upload-button" (click)="manualAttachmentComponentPopup()">Upload Photo</button>
        </ion-col>
        <ion-col>
          <app-barcode-scanner (barcodeScanEvent)="onBarcodeScan($event)" [useDefaultButton]="true"></app-barcode-scanner>
        </ion-col>
      </ion-row>
    
      <div class="upload-container" id="hide-on-camera">
        <!-- Start Photo Upload Area -->
        <app-attachment-upload
          #attachmentUpload
          [condensed]="true"
          [id]="activeIssue.id"
          [isDisabled]="activeIssue?.id && !activeIssue?.active"
          [context]="'issue'"
          [startHidden]="true"
          [showButtons]="false"
          [showAttachmentCount]="true"
          [paddingAmount]="'10px'"
          [openAccordionOnUpload]="true"
          [excludeExistingAttachments]="true"
        ></app-attachment-upload>
        <!-- End Photo Upload Area -->
      </div>
      
      <div id="hide-on-camera">
        <accordion #barcodeAccordion [fontSize]="14" [title]="'Traceability Barcodes (' + (newUpdate.serial_numbers?.length ?? 0) + ')'" [hidden]="true">
          <ion-row class="scan-row" id="hide-on-camera">
            <ion-col>
              <div class="traceability-info">
                <div *ngFor="let serialNumber of newUpdate.serial_numbers">
                  <span>{{serialNumber}}</span>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </accordion>
      </div>

      <ion-row class="button-row" id="hide-on-camera">
        <ion-col>
            <button class="save-button" (click)="closeModal()"
            [disabled]="(!areaFoundNotApplicable && !newUpdate.area_found) || (!numberOfPartsNotApplicable && !newUpdate.parts_found) || !newUpdate.content">
                Add Update
            </button>
        </ion-col>
      </ion-row>
</ion-grid>