import { Pipe, PipeTransform } from "@angular/core";
import { ILiaisonAccountHistory } from "../models/liaison-account-history.model";

@Pipe({
  name: "orderByStartDate",
})
export class OrderByStartDatePipe implements PipeTransform {
    transform(value: ILiaisonAccountHistory[], trigger: number): ILiaisonAccountHistory[] {
        return value.sort((a, b) => {
            if (a.start_date > b.start_date) {
                return -1;
            }
            if (a.start_date < b.start_date) {
                return 1;
            }
            
            return 0;
        });

    }
}