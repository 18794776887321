import { Component, Input } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { LiaisonStateService } from "../../../features/liaison/services/liaison-state-service";
import { SharedService } from "../../services/shared.service";
import { Platform } from "@ionic/angular";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";

@Component({
  selector: "app-barcode-scanner-overlay",
  templateUrl: "barcode-scanner-overlay.component.html",
  styleUrls: ["./barcode-scanner-overlay.component.scss"],
  providers: [LiaisonStateService],
})
export class BarcodeScannerOverlayComponent {

    constructor(
        private sharedService: SharedService,
        private platform: Platform
    ) {
        this.platform.backButton.subscribeWithPriority(10, () => {
            this.closeCamera();
        });
    }

    closeCamera(): void {
        BarcodeScanner.showBackground();
        document.body.classList.remove("scanner-active");
        BarcodeScanner.stopScan();
    }

    manualEntry(): void {
        window.dispatchEvent(new Event('manualBarcodeEntry'));
    }

}
