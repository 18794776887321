import { Pipe, PipeTransform } from "@angular/core";
import { User } from "../models/user.model";

@Pipe({
  name: "filterUserSearch",
})
export class FilterUserSearchPipe implements PipeTransform {
    transform(value: any[], searchValue: string): User[] {
      return value.filter(v => {
        var isValid = false;
        const name = `${v.name ?? `${v.first_name} ${v.last_name ?? ''}`}`;
        const subUserNames = (v?.users ?? []).map(s => `${s.name ?? `${s.first_name} ${s.last_name ?? ''}`}`);

        // searchs the sub contractors for each business
        subUserNames.forEach(s => {
          if (s.toLowerCase().includes(searchValue.toLowerCase())) {
            isValid = true;
          }
        }); 

        return isValid || name.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
}