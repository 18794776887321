import { Injectable } from '@angular/core';
import { Observable, Subject, from, of, catchError } from 'rxjs';
import { Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IIssueAttachment, IRejectAttachment, IReportAttachment, IAttachment } from '../models/attachment.model';
import { CacheInterceptor } from './cache.service';
import { IOfflineIdentifierData } from '../models/cache.model';

@Injectable()
export class AttachmentUploadService {

    API_BASE_URL: string = environment.API_URL;

    constructor(
        private platform: Platform,
        private httpClient: HttpClient,
        private cacheInterceptor: CacheInterceptor
    ) {
        
    }

    postReportAttachment(data: IReportAttachment, parentIdentifier: IOfflineIdentifierData = null){
        return this.cacheInterceptor.processRequest<IAttachment>(`${this.API_BASE_URL}/images/reports`, 'POST', false, data, parentIdentifier);
    }

    postRejectAttachment(data: IRejectAttachment, parentIdentifier: IOfflineIdentifierData = null){
        return this.cacheInterceptor.processRequest<IAttachment>(`${this.API_BASE_URL}/images/rejects`, 'POST', false, data, parentIdentifier);
    }

    postIssueAttachment(data: IIssueAttachment, parentIdentifier: IOfflineIdentifierData = null){
        return this.cacheInterceptor.processRequest<IAttachment>(`${this.API_BASE_URL}/images/issues`, 'POST', false, data, parentIdentifier);
    }

    getReportAttachment(id: number){
        return this.cacheInterceptor.processRequest<IAttachment[]>(`${this.API_BASE_URL}/images/reports/${id}`, 'GET', true);
    }

    getRejectAttachment(id: number){
        return this.cacheInterceptor.processRequest<IAttachment[]>(`${this.API_BASE_URL}/images/rejects/${id}`, 'GET', true);
    }

    getIssueAttachment(id: number){
        return this.cacheInterceptor.processRequest<IAttachment[]>(`${this.API_BASE_URL}/images/issues/${id}`, 'GET', true);
    }

    deleteAttachment(id: number){
        return this.cacheInterceptor.processRequest<IAttachment>(`${this.API_BASE_URL}/images/${id}`, 'DELETE', false);
    }


}