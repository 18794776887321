<ion-grid>
    <ion-row class="header-row">
        <ion-col>
            <span class="modal-header-text">Recipients</span>
        </ion-col>
        <ion-col size="auto">
            <ion-icon
            class="cross"
            (click)="closeModal()"
            name="close-outline"
            ></ion-icon>
        </ion-col>
    </ion-row>
    <div class="container">
    <div class="contacts-container" *ngIf="loaded && allEmails.length > 0">
        <div class="contact-wrapper" *ngFor="let email of allEmails">
            <ion-row *ngIf="email.email === primaryEmail">
                <span> Primary Contact</span>
            </ion-row>
            <ion-row>
                <ion-col class="checkbox-col" size="auto">
                    <ion-checkbox [(ngModel)]="email.active" (ionChange)="checkboxChange(email, $event)"></ion-checkbox>
                </ion-col>
                <ion-col class="email" (click)="editEmail(email.email)">
                    {{email.email}}
                </ion-col>
            </ion-row>
        </div>
    </div>
    <div class="empty-container" *ngIf="loaded && allEmails.length <= 0">
        <ion-row>
            <ion-col>
                <span>No Recipients</span>
            </ion-col>
        </ion-row>
    </div>
    <div class="empty-container" *ngIf="!loaded">
        <ion-row>
            <ion-col>
                <ion-spinner></ion-spinner>
            </ion-col>
        </ion-row>

    </div>
    <ion-row (click)="addContact()" class="add-contact">
        <ion-col>
        Add Recipient
        </ion-col>
    </ion-row>
    </div>
    <ion-row>
        <ion-col>
            <button (click)="send()" class="send-button">Send</button>
        </ion-col>
    </ion-row>
</ion-grid>