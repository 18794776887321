import { Pipe, PipeTransform } from "@angular/core";
import { ICustomer } from "../models/customer.model";


@Pipe({
  name: "orderByName",
})
export class OrderByNamePipe implements PipeTransform {
    transform(value: ICustomer[]): ICustomer[] {
        return value.sort((a, b) => {
            if (a.company_name < b.company_name) {
                return -1;
            }
            if (a.company_name > b.company_name) {
                return 1;
            }
            
            return 0;
        });

    }
}