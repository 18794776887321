import { Component, Input, OnInit } from "@angular/core";
import { ViewWillEnter } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { LiaisonApiService } from "../../../features/liaison/services/liaison-api-service";
import { LiaisonStateService } from "../../../features/liaison/services/liaison-state-service";

@Component({
  selector: "send-to-customer-component",
  templateUrl: "send-to-customer-popup.component.html",
  styleUrls: ["./send-to-customer-popup.component.scss"],
  providers: [LiaisonApiService],
})
export class SendToCustomerComponent implements OnInit, ViewWillEnter {

  @Input() isUpdate: boolean = false;
  @Input() overrideText: string = null;
  @Input() overrideMessage: string = null;

  constructor(
    private modalCtrl: ModalController,
    public liaisonStateService: LiaisonStateService
  ) {

  }


  ngOnInit(): void {

  }

  ionViewWillEnter(): void {
   
  }


  async closeModal(value: boolean = false) {
    this.modalCtrl.dismiss(value);
  }
}

